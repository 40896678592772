import React from "react";
export const GuideEligibility = ({ data }) => {
    return (
        <div style={{ padding: "18px 24px" }}>
            <div className="question">
                <div className="number">1.</div>
                <div>
                    Does the project operate as a hotel, motel, or resort as
                    defined by the{" "}
                    <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b4/2.1/03.html"
                    >
                        Selling Guide B4-2.1-03
                    </a>
                    ?
                </div>
            </div>
            <div className="summary-answer">
                <div className="label">Answer:&nbsp;</div>
                {typeof data.guideEligibilityAndProjectInfoSt !==
                    "undefined" ? (
                    <>
                        {data.guideEligibilityAndProjectInfoSt
                            .geHotelMotelResortIndSt === "Fail" ? (
                            <>
                                <div
                                    className="attribute"
                                    style={{ color: "#D0021B" }}
                                >
                                    {
                                        data.guideEligibilityAndProjectInfoSt
                                            .geHotelMotelResortInd
                                    }
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="attribute">
                                    {
                                        data.guideEligibilityAndProjectInfoSt
                                            .geHotelMotelResortInd
                                    }
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
            <div className="question">
                <div className="number">2.</div>
                <div>
                    How many timeshare or segmented units within the project?
                    (If none, enter zero)
                </div>
            </div>
            <div className="summary-answer">
                <div className="label">Answer:&nbsp;</div>
                {typeof data.guideEligibilityAndProjectInfoSt !==
                    "undefined" ? (
                    <>
                        {data.guideEligibilityAndProjectInfoSt
                            .geTimeshareCountSt === "Fail" ? (
                            <>
                                <div
                                    className="attribute"
                                    style={{ color: "#D0021B" }}
                                >
                                    {
                                        data.guideEligibilityAndProjectInfoSt
                                            .geTimeshareCount
                                    }
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="attribute">
                                    {
                                        data.guideEligibilityAndProjectInfoSt
                                            .geTimeshareCount
                                    }
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
            <div className="question">
                <div className="number">3.</div>{" "}
                <div>Is the project a houseboat project?</div>
            </div>
            <div className="summary-answer">
                <div className="label">Answer:&nbsp;</div>
                {typeof data.guideEligibilityAndProjectInfoSt !==
                    "undefined" ? (
                    <>
                        {data.guideEligibilityAndProjectInfoSt
                            .geHouseBoatIndSt === "Fail" ? (
                            <>
                                <div
                                    className="attribute"
                                    style={{ color: "#D0021B" }}
                                >
                                    {
                                        data.guideEligibilityAndProjectInfoSt
                                            .geHouseBoatInd
                                    }
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="attribute">
                                    {
                                        data.guideEligibilityAndProjectInfoSt
                                            .geHouseBoatInd
                                    }
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
            <div className="question">
                <div className="number">4.</div>{" "}
                <div>
                    Do any single units have multiple living spaces? (Does NOT
                    pertain to legally combined units as outlined in
                    <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b4/2.1/03.html"
                    >
                        {" "}
                        B4-2.1-03
                    </a>
                    .)
                </div>
            </div>
            <div className="summary-answer">
                <div className="label">Answer:&nbsp;</div>
                {typeof data.guideEligibilityAndProjectInfoSt !==
                    "undefined" ? (
                    <>
                        {data.guideEligibilityAndProjectInfoSt
                            .geMulLivingSpacesIndSt === "Fail" ? (
                            <>
                                <div
                                    className="attribute"
                                    style={{ color: "#D0021B" }}
                                >
                                    {
                                        data.guideEligibilityAndProjectInfoSt
                                            .geMulLivingSpacesInd
                                    }
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="attribute">
                                    {
                                        data.guideEligibilityAndProjectInfoSt
                                            .geMulLivingSpacesInd
                                    }
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
            <div className="question">
                <div className="number">5.</div>
                <div>
                    Are units in the project subject to{" "}
                    <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b4/2.1/03.html"
                    >
                        split ownership{" "}
                    </a>
                    arrangements or other arrangements that restrict the unit
                    owner’s ability to occupy the unit such as mandatory
                    rental pooling agreements, common interest apartments or
                    community apartment projects?
                </div>
            </div>
            <div className="summary-answer">
                <div className="label">Answer:&nbsp;</div>
                {typeof data.guideEligibilityAndProjectInfoSt !==
                    "undefined" ? (
                    <>
                        {data.guideEligibilityAndProjectInfoSt
                            .geSplitOwnershipIndSt === "Fail" ? (
                            <>
                                <div
                                    className="attribute"
                                    style={{ color: "#D0021B" }}
                                >
                                    {
                                        data.guideEligibilityAndProjectInfoSt
                                            .geSplitOwnershipInd
                                    }
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="attribute">
                                    {
                                        data.guideEligibilityAndProjectInfoSt
                                            .geSplitOwnershipInd
                                    }
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
            <div className="question">
                <div className="number">6.</div>{" "}
                <div>
                    Does the project/HOA's budget reflect non-incidental
                    income from the ownership and/or operation of amenities or
                    services for use by unit owners and the general public?
                </div>
            </div>
            <div className="summary-answer">
                <div className="label">Answer:&nbsp;</div>
                {typeof data.guideEligibilityAndProjectInfoSt !==
                    "undefined" ? (
                    <>
                        {data.guideEligibilityAndProjectInfoSt
                            .geNIncIncomeAbovePmtdIndSt === "Fail" ? (
                            <>
                                <div
                                    className="attribute"
                                    style={{ color: "#D0021B" }}
                                >
                                    {
                                        data.guideEligibilityAndProjectInfoSt.geNIncIncomeAbovePmtdInd
                                    }
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="attribute">
                                    {
                                        data.guideEligibilityAndProjectInfoSt.geNIncIncomeAbovePmtdInd

                                    }
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
            {data.guideEligibilityAndProjectInfoSt
                .geNIncIncomeAbovePmtdInd === "Yes" ? (
                <>
                    <div className="question">
                        <div
                            className="number"
                            style={{
                                borderLeft: "1px solid rgba(0,0,0,0.4)",
                                borderBottom: "1px solid rgba(0,0,0,0.4)",
                                marginLeft: "37px",
                                marginBottom: "10px",
                            }}
                        ></div>{" "}
                        <div style={{ paddingTop: "10px" }}>
                            What percentage (%) of the Total Income is reflected
                            as non-incidental income on the budget?
                        </div>
                    </div>
                    <div
                        className="summary-answer"
                        style={{ paddingLeft: "86px" }}
                    >
                        <div className="label">Answer:&nbsp;</div>
                        {typeof data.guideEligibilityAndProjectInfoSt !==
                            "undefined" ? (
                            <>
                                {data.guideEligibilityAndProjectInfoSt
                                    .gePerTotIncNonIncIncomeSt === "Fail" ? (
                                    <>
                                        <div
                                            className="attribute"
                                            style={{ color: "#D0021B" }}
                                        >
                                            {
                                                data.guideEligibilityAndProjectInfoSt
                                                    .gePerTotIncNonIncIncome
                                            }
                                            %
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="attribute">
                                            {
                                                data.guideEligibilityAndProjectInfoSt
                                                    .gePerTotIncNonIncIncome
                                            }
                                            %
                                        </div>
                                    </>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="question">
                        <div
                            className="number"
                            style={{
                                borderLeft: "1px solid rgba(0,0,0,0.4)",
                                borderBottom: "1px solid rgba(0,0,0,0.4)",
                                marginLeft: "37px",
                                marginBottom: "10px",
                            }}
                        ></div>{" "}
                        <div style={{ paddingTop: "10px" }}>
                            Is the income from the permitted sources as outlined
                            in{" "}
                            <a
                                target="_blank"
                                href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B4-Underwriting-Property/Chapter-B4-2-Project-Standards/#Non-Incidental.20Business.20Arrangements"
                            >
                                B4-2.1-03
                            </a>
                            ?
                        </div>
                    </div>
                    <div
                        className="summary-answer"
                        style={{ paddingLeft: "86px" }}
                    >
                        <div className="label">Answer:&nbsp;</div>
                        <div className="attribute">
                            {
                                data.guideEligibilityAndProjectInfoSt
                                    .geIncomeFromPermittedSource
                            }
                        </div>
                    </div>
                </>
            ) : (
                <></>
            )}
            <div className="question">
                <div className="number">7.</div>{" "}
                <div>
                    Is the project out of compliance with the{" "}
                    <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b4/2.1/03.html"
                    >
                        Selling Guide requirements
                    </a>{" "}
                    related to the lien priority of unpaid common expense
                    assessments?
                </div>
            </div>
            <div className="summary-answer">
                <div className="label">Answer:&nbsp;</div>
                {typeof data.guideEligibilityAndProjectInfoSt !==
                    "undefined" ? (
                    <>
                        {data.guideEligibilityAndProjectInfoSt
                            .geUnpaidCommonExpAsmtsIndSt === "Fail" ? (
                            <>
                                <div
                                    className="attribute"
                                    style={{ color: "#D0021B" }}
                                >
                                    {
                                        data.guideEligibilityAndProjectInfoSt
                                            .geUnpaidCommonExpAsmtsInd
                                    }
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="attribute">
                                    {
                                        data.guideEligibilityAndProjectInfoSt
                                            .geUnpaidCommonExpAsmtsInd
                                    }
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
            <div className="question">
                <div className="number">8.</div>{" "}
                <div>
                    Is the project, in whole or in part, operated or owned as
                    a continuing care facility which provides medical and/or
                    supportive services to unit owners? See{" "}
                    <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b4/2.1/03.html"
                    >
                        Selling Guide reference
                    </a>
                    .
                </div>
            </div>
            <div className="summary-answer">
                <div className="label">Answer:&nbsp;</div>
                {typeof data.guideEligibilityAndProjectInfoSt !==
                    "undefined" ? (
                    <>
                        {data.guideEligibilityAndProjectInfoSt
                            .geCareFacilityIndSt === "Fail" ? (
                            <>
                                <div
                                    className="attribute"
                                    style={{ color: "#D0021B" }}
                                >
                                    {
                                        data.guideEligibilityAndProjectInfoSt
                                            .geCareFacilityInd
                                    }
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="attribute">
                                    {
                                        data.guideEligibilityAndProjectInfoSt
                                            .geCareFacilityInd
                                    }
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
            <div className="question">
                <div className="number">9.</div>{" "}
                <div>
                    Are residential unit owners required to pay mandatory
                    upfront and/or periodic membership, or recreational lease
                    for the use of amenities (such as country club facilities,
                    golf courses, etc.), that are NOT owned by the HOA or
                    master association and instead are owned by an outside
                    party (including the developer or builder)?
                </div>
            </div>
            <div className="summary-answer">
                <div className="label">Answer:&nbsp;</div>
                {typeof data.guideEligibilityAndProjectInfoSt !==
                    "undefined" ? (
                    <>
                        {data.guideEligibilityAndProjectInfoSt
                            .gePayMemshpOrLeaseIndSt === "Fail" ? (
                            <>
                                <div
                                    className="attribute"
                                    style={{ color: "#D0021B" }}
                                >
                                    {
                                        data.guideEligibilityAndProjectInfoSt
                                            .gePayMemshpOrLeaseInd
                                    }
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="attribute">
                                    {
                                        data.guideEligibilityAndProjectInfoSt
                                            .gePayMemshpOrLeaseInd
                                    }
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
            <div className="question">
                <div className="number">10.</div>{" "}
                <div>
                    Is the project out of compliance with the{" "}
                    <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b4/2.2/02.html"
                    >
                        Selling Guide requirements
                    </a>{" "}
                    related to the HOA's ownership of the project's
                    facilities, amenities, common elements, or limited common
                    elements (i.e., are any of these elements owned by an
                    entity other than the HOA)?
                </div>
            </div>
            <div className="summary-answer">
                <div className="label">Answer:&nbsp;</div>
                {typeof data.guideEligibilityAndProjectInfoSt !==
                    "undefined" ? (
                    <>
                        {data.guideEligibilityAndProjectInfoSt
                            .getCmplnHOAOwnshpIndSt === "Fail" ? (
                            <>
                                <div
                                    className="attribute"
                                    style={{ color: "#D0021B" }}
                                >
                                    {
                                        data.guideEligibilityAndProjectInfoSt
                                            .getCmplnHOAOwnshpInd
                                    }
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="attribute">
                                    {
                                        data.guideEligibilityAndProjectInfoSt
                                            .getCmplnHOAOwnshpInd
                                    }
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
            <div className="question">
                <div className="number">11.</div>{" "}
                <div>
                    Is the lender aware of any unacceptable
                    environmental hazard that has not been appropriately
                    assessed and remediated per sections
                    <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b4/2.1/04.html"
                    >
                        {" "}
                        B4-2.1-04
                    </a>
                    ,
                    <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b4/2.1/05.html"
                    >
                        {" "}
                        B4-2.1-05
                    </a>
                    , and{" "}
                    <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b4/2.1/06.html"
                    >
                        B4-2.1-06
                    </a>{" "}
                    of the Selling Guide requirements?
                </div>
            </div>
            <div className="summary-answer">
                <div className="label">Answer:&nbsp;</div>
                {typeof data.guideEligibilityAndProjectInfoSt !==
                    "undefined" ? (
                    <>
                        {data.guideEligibilityAndProjectInfoSt
                            .geEnvHazardIndSt === "Fail" ? (
                            <>
                                <div
                                    className="attribute"
                                    style={{ color: "#D0021B" }}
                                >
                                    {
                                        data.guideEligibilityAndProjectInfoSt
                                            .geEnvHazardInd
                                    }
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="attribute">
                                    {
                                        data.guideEligibilityAndProjectInfoSt
                                            .geEnvHazardInd
                                    }
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
            <div className="question">
                <div className="number">12.</div>{" "}
                <div>
                    Are units in the project subject to Right of First Refusal
                    or resale restrictions that do not meet the applicable
                    requirements in section{" "}
                    <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b4/2.1/01.html"
                    >
                        B4-2.1-01
                    </a>
                    ,
                    <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b4/2.2/03.html"
                    >
                        {" "}
                        B4-2.2-03
                    </a>
                    , and{" "}
                    <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b5/5.2/02.html"
                    >
                        B5-5.2-02
                    </a>{" "}
                    of the Selling Guide related to loans with resale
                    restrictions?
                </div>
            </div>
            <div className="summary-answer">
                <div className="label">Answer:&nbsp;</div>
                {typeof data.guideEligibilityAndProjectInfoSt !==
                    "undefined" ? (
                    <>
                        {data.guideEligibilityAndProjectInfoSt
                            .geRtFtRefslOrReSlRstnsIndSt === "Fail" ? (
                            <>
                                <div
                                    className="attribute"
                                    style={{ color: "#D0021B" }}
                                >
                                    {
                                        data.guideEligibilityAndProjectInfoSt
                                            .geRtFtRefslOrReSlRstnsInd
                                    }
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="attribute">
                                    {
                                        data.guideEligibilityAndProjectInfoSt
                                            .geRtFtRefslOrReSlRstnsInd
                                    }
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
            <div className="question">
                <div className="number">13.</div>{" "}
                <div>
                    Are there any sale/financing concessions in excess of
                    Fannie Mae’s eligibility policies for mortgage loans even
                    if these concessions are not available to the subject
                    unit, as described in{" "}
                    <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b4/2.1/03.html"
                    >
                        {" "}
                        B4-2.1-03
                    </a>
                    ?
                </div>
            </div>
            <div className="summary-answer">
                <div className="label">Answer:&nbsp;</div>
                {typeof data.newProjectEligibilitySt !==
                    "undefined" ? (
                    <>
                        {data.newProjectEligibilitySt
                            .npeFinConExcessIndSt === "Fail" ? (
                            <>
                                <div
                                    className="attribute"
                                    style={{ color: "#D0021B" }}
                                >
                                    {
                                        data.newProjectEligibilitySt
                                            .npeFinConExcessInd
                                    }
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="attribute">
                                    {
                                        data.newProjectEligibilitySt
                                            .npeFinConExcessInd
                                    }
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
            {/* <div className="question">
                <div className="number">14.</div>{" "}
                <div>
                    Is the project subject to any action that would cause the project to cease to exist, including termination, deconversion or dissolution of their legal structure or subject to voluntary or involuntary bankruptcy, liquidation or receivership proceedings, or any substantially similar action. This includes projects that have voted or are in the process of voting on any of the actions described above ?
                </div>
            </div>
            <div className="summary-answer">
                <div className="label">Answer:&nbsp;</div>
                {typeof data.guideEligibilityAndProjectInfoSt !==
                    "undefined" ? (
                    <>
                        {data.guideEligibilityAndProjectInfoSt
                            .geIsProjActionCeaseToExistSt === "Fail" ? (
                            <>
                                <div
                                    className="attribute"
                                    style={{ color: "#D0021B" }}
                                >
                                    {
                                        data.guideEligibilityAndProjectInfoSt.geIsProjActionCeaseToExist
                                    }
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="attribute">
                                    {
                                        data.guideEligibilityAndProjectInfoSt.geIsProjActionCeaseToExist

                                    }
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
            {data.guideEligibilityAndProjectInfoSt
                .geIsProjActionCeaseToExist === "Yes" ? (
                <>
                    <div className="question">
                        <div
                            className="number"
                            style={{
                                borderLeft: "1px solid rgba(0,0,0,0.4)",
                                borderBottom: "1px solid rgba(0,0,0,0.4)",
                                marginLeft: "37px",
                                marginBottom: "10px",
                            }}
                        ></div>{" "}
                        <div style={{ paddingTop: "10px" }}>
                            Choose one of the following 3 options that most closely describes the project?
                        </div>
                    </div>
                        <div
                            className="summary-answer"
                            style={{ paddingLeft: "86px" }}
                        >
                            <div className="label">Answer:&nbsp;</div>
                            { typeof data.guideEligibilityAndProjectInfoSt !==
                                "undefined" ? (
                                <>
                                    <div className="attribute">
                                        {data.guideEligibilityAndProjectInfoSt.geProjActionToCease === "terminating" ? <>Terminating, deconverting or dissolving or any substantially similar action</> : <></>}
                                        {data.guideEligibilityAndProjectInfoSt.geProjActionToCease === "voluntary" ? <>Voluntary or involuntary bankruptcy, liquidation or receivership proceedings, or any substantially similar action</> : <></>}
                                        {data.guideEligibilityAndProjectInfoSt.geProjActionToCease === "NA" ? <>Unable to determine</> : <></>}
                                    </div>

                                </>) : (<></>)
                            }
                        </div>
                </>
            ) : (
                <></>
            )} */}
        </div>
    );
}