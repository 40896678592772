import moment from "moment";

export function certificationFormatter(formData) {
  const passData = JSON.parse(sessionStorage.getItem("passData"));
  const lenderId =
  sessionStorage.getItem("lenderId") ||
  passData.sellerServicerCoNo ||
  "12346";
    const certificationData = {
        guideEligibilityAndProjectInfoAndIds: {
          lenderId: lenderId,
          projectId: formData.projectId,
          phaseId: formData.phaseId === 'proj' ? "" : formData.phaseId,
          createdBy: formData.createdBy,
          guideEligibilityAndProjectInfo: {
            geHotelMotelResortInd: formData.geHotelMotelResortInd,
            geTimeshareCount: formData.geTimeshareCount,
            geHouseBoatInd: formData.geHouseBoatInd,
            geMulLivingSpacesInd: formData.geMulLivingSpacesInd,
            geSplitOwnershipInd: formData.geSplitOwnershipInd,
            geNIncIncomeAbovePmtdInd: formData.geNIncIncomeAbovePmtdInd,
            gePerTotIncNonIncIncome: formData.gePerTotIncNonIncIncome,
            geIncomeFromPermittedSource: formData.geIncomeFromPermittedSource,
            geUnpaidCommonExpAsmtsInd: formData.geUnpaidCommonExpAsmtsInd,
            geCareFacilityInd: formData.geCareFacilityInd,
            gePayMemshpOrLeaseInd: formData.gePayMemshpOrLeaseInd,
            getCmplnHOAOwnshpInd: formData.getCmplnHOAOwnshpInd,
            geEnvHazardInd: formData.geEnvHazardInd,
            geRtFtRefslOrReSlRstnsInd: formData.geRtFtRefslOrReSlRstnsInd,
            piYearBuilt: formData.piYearBuilt,
            piConvtdProjInd: formData.piConvtdProjInd,
            piGutRehabInd: formData.piGutRehabInd,
            piYearConvtd: formData.piYearConvtd,
            piAddnPhsOrAnnxInd: formData.piAddnPhsOrAnnxInd,
            piCntlHOATurnOverInd: formData.piCntlHOATurnOverInd,
            piDtTurndOver: formData.piDtTurndOver,
            piCommnAreasCompltInd: formData.piCommnAreasCompltInd,
            geIsProjActionCeaseToExist: formData.geIsProjActionCeaseToExist,
            geProjActionToCease: formData.geProjActionToCease,
          },
        },
        projectPresaleAndInvestor: {
          updatedBy: formData.createdBy,
          projectPresaleInvstr: {
            ppActivePendingSpAssmntsInd: formData.ppActivePendingSpAssmntsInd,
            ppDevOwndUntsRentalHoldBackInd: formData.ppDevOwndUntsRentalHoldBackInd,
            ppDevOwnedUnits: formData.ppDevOwnedUnits,
            ppMaintFeesDevOwndCurrentInd: formData.ppMaintFeesDevOwndCurrentInd,
            ppOwnerOccupiedUnits: formData.ppOwnerOccupiedUnits,
            ppPreSaleConveyPercntge: formData.ppPreSaleConveyPercntge,
            ppSecondHomes: formData.ppSecondHomes,
            ppTotalUnits: formData.ppTotalUnits,
            ppUnitsComplete: formData.ppUnitsComplete,
            ppUnitsConveyed: formData.ppUnitsConveyed,
            ppUnitsRented: formData.ppUnitsRented,
          },
        },
        phasePresaleAndInvestor: {
          updatedBy: formData.createdBy,
          phasePresaleInvstr: {
            phpOwnerOccupiedUnits: formData.phpOwnerOccupiedUnits,
            phpPhases: formData.phpPhases,
            phpPhasesAnnexedToProject: formData.phpPhasesAnnexedToProject,
            phpSecondHomes: formData.phpSecondHomes,
            phpUnits: formData.phpUnits,
            phpUnitsComplete: formData.phpUnitsComplete,
            phpUnitsCurrPhaseAndPrPhase: formData.phpUnitsCurrPhaseAndPrPhase,
            phpUnitsForSale: formData.phpUnitsForSale,
            phpUnitsRented: formData.phpUnitsRented,
            phpUnitsSold: formData.phpUnitsSold,
            phpUnitsSoldOwnerOccpSecondHomeRsdn:
              formData.phpUnitsSoldOwnerOccpSecondHomeRsdn,
          },
        },
        newProjectEligibility: {
          updatedBy: formData.createdBy,
          newProjectEligibility: {
            npeAssrArrgReviewedInd: formData.npeAssrArrgReviewedInd,
            npeFinConExcessInd: formData.npeFinConExcessInd,
            npeLglDocsComplyInd: formData.npeLglDocsComplyInd,
            npeMasterAsscInd: formData.npeMasterAsscInd,
            npePayMnthyAssesLndrRevwPlanInd:
              formData.npePayMnthyAssesLndrRevwPlanInd,
          },
        },
        projectOwnership: {
          updatedBy: formData.createdBy,
          projectOwnership: {
            poLeaseComplyInd: formData.poLeaseComplyInd,
            poLeaseholdInd: formData.poLeaseholdInd,
            poOwnersHaveSoleOwnshpInd: formData.poOwnersHaveSoleOwnshpInd,
            poSnglOwnMoreThanOneInd: formData.poSnglOwnMoreThanOneInd,
            poUnitsSnglOwn: formData.poUnitsSnglOwn,
            poLeaseholdExpDate: formData.poLeaseholdExpDate,
          },
        },
        lcFinancials: {
          updatedBy: formData.createdBy,
          financials: {
            fnoOfSpAssmts: formData.fnoOfSpAssmts,
            fresStudyReqInd: formData.fresStudyReqInd,
            fmetDefInd: formData.fmetDefInd,
            fmetDefElem: formData.fmetDefElem,
            fotherReasonMetDef: formData.fotherReasonMetDef,
            fleakRepairInd: formData.fleakRepairInd,
            funitsHOAExpnPastDue: formData.funitsHOAExpnPastDue,
            fexpDateAnlBudget:formData.fexpDateAnlBudget,
            fbugtAnlIncome: formData.fbugtAnlIncome,
            famtExclItems: formData.famtExclItems,
            fbugtAnlReserves: formData.fbugtAnlReserves,
            fdefdMntInd: formData.fdefdMntInd,
            fsplAssmtsInd: formData.fsplAssmtsInd,
            fttlSpAssmts:formData.fttlSpAssmts,
            fspAssmts: formData.fspAssmts,
            fphyDetInd: formData.fphyDetInd,
            fphyDetEle: formData.fphyDetEle,
            fotherReasonPhyDet: formData.fotherReasonPhyDet,
            finspFailInd: formData.finspFailInd,
            funfundRepInd: formData.funfundRepInd
          },
        },
        lcLitigation: {
          updatedBy: formData.createdBy,
          litigation: {
            lcommerclSpaceInd: formData.lcommerclSpaceInd,
            linsurancCvgInd: formData.linsurancCvgInd,
            llitigationInd: formData.llitigationInd,
            lminrMttrLitgtnInd: formData.lminrMttrLitgtnInd,
            lnonResSqFtg: formData.lnonResSqFtg,
            lsafetyLitgtnInd: formData.lsafetyLitgtnInd,
            lttlSqFtg: formData.lttlSqFtg,
          },
        },
      };
      return certificationData;
  }

  export function getMultiSelectOptions(data) {
    let options = [];
    if (data.length > 0) {
      if (isJsonString(data[0])) {
        data.map(option => {
          options.push(option.label);
        })
      } else {
        options = data;
      }
    }
    return options;
  }

  export function setMultiSelectOptions(data) {
    let options = [];
    if (data.length > 0) {
      data.map(option => {
        options.push({ "label": option, "value": option });
      })
    }
    return options;
  }

  export function page7SetMultiSelectOptions(formData) {
    if (formData.fmetDefElem.length > 0) {
      const fmetDefElem = setMultiSelectOptions(formData.fmetDefElem);
      formData.fmetDefElem = fmetDefElem;
    }
    if (formData.fspAssmts.length > 0) {
      const fspAssmts = [];
      formData.fspAssmts.map(option => {
        option.freasonsSpAssmts = setMultiSelectOptions(option.freasonsSpAssmts);
        fspAssmts.push(option);
      }) 
    }
    if (formData.fphyDetEle.length > 0) {
      const fphyDetEle = setMultiSelectOptions(formData.fphyDetEle);
      formData.fphyDetEle = fphyDetEle;
    }
    return formData;
  }

  export function page7tMultiSelectOptions(formData) {
    if (formData.fmetDefElem.length > 0) {
      const fmetDefElem = getMultiSelectOptions(formData.fmetDefElem);
      formData.fmetDefElem = fmetDefElem;
    }
    if (formData.fspAssmts.length > 0) {
      const fspAssmts = [];
      formData.fspAssmts.map(option => {
        option.freasonsSpAssmts = getMultiSelectOptions(option.freasonsSpAssmts);
        fspAssmts.push(option);
      }) 
    }
    if (formData.fphyDetEle.length > 0) {
      const fphyDetEle = getMultiSelectOptions(formData.fphyDetEle);
      formData.fphyDetEle = fphyDetEle;
    }
    return formData;
  }

  export function isJsonString(str) {
      try {
        var json = JSON.parse(JSON.stringify(str));
        return (typeof json === 'object');
      } catch (e) {
        return false;
      }
  }

  export function inArray(list, key) {
    if (list.length > 0) {
      var length = list.length;
      for(var i = 0; i < length; i++) {
          if(list[i].toLowerCase() == key.toLowerCase())
              return true;
      }
    }
    return false;
  };


  export function inOptionsArray(list, key) {
    let l = getMultiSelectOptions(list);
    return inArray(l,key)
  };

  export function fspAssmtsRuleCheck(list) {
    let rule = "Pass"
    if (list.length > 0) {
      list.map(ass => {
        if (ass.fremediateRepairIndSt === 'Fail') {
          return rule = "Fail"
        }
        if (ass.fnoOfUnitsDelinquentSt === 'Fail') {
          return rule = "Fail"
        }
      });
    }
    return rule;
  };

  export function getIndex(value){
    let index = '';
    switch (value) {
      case 0:
        index = "first";
        break;
      case 1:
        index = 'second';
        break;
      case 2:
        index = 'third';
        break;
      case 3:
        index = 'fourth';
        break;
      case 4:
        index = 'fifth';
        break;
      default:
        index = "";
        break;
    }
    return index;
  };

  export function getDays(dateSent) {
    let now = moment();
    dateSent = moment(dateSent)
    if (dateSent < now) {
      return 121;
    }
    return Math.abs(
      moment(dateSent, 'YYYY-MM-DD')
        .diff(moment(now, 'YYYY-MM-DD'), 'days')
    ) + 1;
  }

  export function getGoogleAddressFields(place) {
    let address1 = "";
    let postcode = "";
    let city = "";
    let state = "";
    for (const component of place.address_components) {
      const componentType = component?.types[0];
      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name} ${address1}`;
          break;
        }
        case "route": {
          address1 += component.short_name;
          break;
        }
        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          break;
        }
        case "locality":
          city = component.long_name;
          break;

        case "administrative_area_level_1": {
          state = component.short_name;
          break;
        }
      }
    }
    return {"street": address1, "zip": postcode, "city": city, "state": state}
  }

  export function filterProjectRestrictionForProject(data, isLender) {
    let projectRestrictions = {
      "projectDR": [],
      "showAddManualRestrictions":true
    }
    if (data) {
      const persDecisions = data?.filter(c => c.type === 'Pers Decision' && c.phaseId === 0);
      const manualDecisions = data?.filter(c => c.type === 'Manual Restriction' && c.phaseId === 0);

      if ( persDecisions.length === 0) {
        const maxDecisionTime =  Math.max(...manualDecisions.map(d=> new Date(new Date(d.dateCreated).setSeconds(0)).getTime()));
        projectRestrictions.showAddManualRestrictions = true;
        projectRestrictions.projectDR = data?.filter(c => c.type === 'Manual Restriction' && new Date(new Date(c.dateCreated).setSeconds(0)).getTime() === maxDecisionTime && c.phaseId === 0);
      } else {
        const maxDecisionTime =  Math.max(...persDecisions.map(d=> new Date(new Date(d.dateCreated).setSeconds(0)).getTime()));
        projectRestrictions.showAddManualRestrictions = false;
        projectRestrictions.projectDR = data?.filter(c => c.type === 'Restriction' && new Date(new Date(c.dateCreated).setSeconds(0)).getTime() === maxDecisionTime && c.phaseId === 0);
      }

      if ( projectRestrictions.projectDR.length === 0 && isLender ) {
        projectRestrictions.showAddManualRestrictions = false;
        projectRestrictions.projectDR = data?.filter(c => c.type === 'Certification Restriction' && c.phaseId === 0);
      }
    }
    return projectRestrictions;
  }

  export function filterProjectConditionsForProject(data) {
    let projectCondns = {
      projectCondnList: []
    };
    if (data) {
      const decisions = data?.filter(c => c.type === 'Pers Decision' && c.phaseId === 0);
      const maxDecisionTime =  Math.max(...decisions.map(d=> new Date(new Date(d.dateCreated).setSeconds(0)).getTime()));
      projectCondns.projectCondnList =  data?.filter(c => c.type === 'Condition' && new Date(new Date(c.dateCreated).setSeconds(0)).getTime() == maxDecisionTime && c.phaseId === 0);
    } 
    return projectCondns;
  }

export function filterRestrictionForProjectPhase(data, phaseId, isLender) {
  let phaseRestrictions = {
    "projectDR": [],
    "phaseDR": [],
    "showAddManualRestrictions": true
  };
  if (data) {
    // Project
    const projectRestrictions = filterProjectRestrictionForProject(data);
    phaseRestrictions.projectDR = projectRestrictions.projectDR
    // Phase
    const persDecisions = data?.filter(c => c.type === 'Pers Decision' && c.phaseId === phaseId);
    const manualDecisions = data?.filter(c => c.type === 'Manual Restriction' && c.phaseId === phaseId);

    if (persDecisions.length === 0) {
      const maxDecisionTime = Math.max(...manualDecisions.map(d => new Date(new Date(d.dateCreated).setSeconds(0)).getTime()));
      phaseRestrictions.showAddManualRestrictions = true;
      phaseRestrictions.phaseDR = data?.filter(c => c.type === 'Manual Restriction' && new Date(new Date(c.dateCreated).setSeconds(0)).getTime() === maxDecisionTime && c.phaseId === phaseId);
    } else {
      const maxDecisionTime = Math.max(...persDecisions.map(d => new Date(new Date(d.dateCreated).setSeconds(0)).getTime()));
      phaseRestrictions.showAddManualRestrictions = false;
      phaseRestrictions.phaseDR = data?.filter(c => c.type === 'Restriction' && new Date(new Date(c.dateCreated).setSeconds(0)).getTime() === maxDecisionTime && c.phaseId === phaseId);
    }

    if (phaseRestrictions.phaseDR.length === 0 && isLender) {
      phaseRestrictions.showAddManualRestrictions = false;
      phaseRestrictions.phaseDR = data?.filter(c => c.type === 'Certification Restriction' && c.phaseId === phaseId);
    }
  }
  return phaseRestrictions;
}

  export function filterConditionsForProjectPhase(data, phaseId) {
    let phaseCondns = {"projectCondnList":[],"phaseCondnList":[]};
    if (data) {
      //Project
      const projectCondition = filterProjectConditionsForProject(data);
      phaseCondns.projectCondnList = projectCondition.projectCondnList;
      //Phase
      const decisions = data?.filter(c => c.type === 'Pers Decision' && c.phaseId === phaseId);
      const maxDecisionTime =  Math.max(...decisions.map(d=> new Date(new Date(d.dateCreated).setSeconds(0)).getTime()));
      phaseCondns.phaseCondnList =  data?.filter(c => c.type === 'Condition' && new Date(new Date(c.dateCreated).setSeconds(0)).getTime() == maxDecisionTime && c.phaseId === phaseId);
    } 
    return phaseCondns;
  }

  export function filterProjectConditionsForChildPhase(data, phaseId) {
    let phaseCondns = {"childPhaseCondnList":[]};
    if (data) {
      //Phase
      const decisions = data?.filter(c => c.type === 'Pers Decision' && c.phaseId === phaseId);
      const maxDecisionTime =  Math.max(...decisions.map(d=> new Date(new Date(d.dateCreated).setSeconds(0)).getTime()));
      phaseCondns.childPhaseCondnList =  data?.filter(c => c.type === 'Condition' && new Date(new Date(c.dateCreated).setSeconds(0)).getTime() == maxDecisionTime && c.phaseId === phaseId);
    } 
    return phaseCondns;
  }

  

  export function filterRestrictionForChildPhase(data, phaseId, childPhaseId, isLender) {
    let childPhaseRestrictions = {
      "projectDR": [],
      "phaseDR": [],
      "showAddManualRestrictions": true,
      "childPhaseDR": [],
    };

    if (data) {
      // Project
      const projectPhaseRestrictions = filterRestrictionForProjectPhase(data, phaseId, isLender);
      childPhaseRestrictions.projectDR = projectPhaseRestrictions.projectDR;
      childPhaseRestrictions.phaseDR = projectPhaseRestrictions.phaseDR;
      // Phase
      const persDecisions = data?.filter(c => c.type === 'Pers Decision' && c.phaseId === childPhaseId);
      const manualDecisions = data?.filter(c => c.type === 'Manual Restriction' && c.phaseId === childPhaseId);
  
      if (persDecisions.length === 0) {
        const maxDecisionTime = Math.max(...manualDecisions.map(d => new Date(new Date(d.dateCreated).setSeconds(0)).getTime()));
        childPhaseRestrictions.showAddManualRestrictions = true;
        childPhaseRestrictions.childPhaseDR = data?.filter(c => c.type === 'Manual Restriction' && new Date(new Date(c.dateCreated).setSeconds(0)).getTime() === maxDecisionTime && c.phaseId === childPhaseId);
      } else {
        const maxDecisionTime = Math.max(...persDecisions.map(d => new Date(new Date(d.dateCreated).setSeconds(0)).getTime()));
        childPhaseRestrictions.showAddManualRestrictions = false;
        childPhaseRestrictions.childPhaseDR = data?.filter(c => c.type === 'Restriction' && new Date(new Date(c.dateCreated).setSeconds(0)).getTime() === maxDecisionTime && c.phaseId === childPhaseId);
      }
  
      if (childPhaseRestrictions.childPhaseDR.length === 0 && isLender) {
        childPhaseRestrictions.showAddManualRestrictions = false;
        childPhaseRestrictions.childPhaseDR = data?.filter(c => c.type === 'Certification Restriction' && c.phaseId === phaseId);
      }
    }
    return childPhaseRestrictions;
  }