import React from "react";
import "./Condition.css";
import ApartmentIcon from "../../images/apartment_black_24dp@2x.svg";
import LayersBlackIcon from "../../images/layers_black_24dp@1x.svg";
import ConstructionIcon from "../../images/construction_black_24dp@1x.svg";
import CampaignIcon from "../../images/campaign_black_24dp@1x.svg";
const Condition = ({ item, type }) => {
  return (
    <div>
      {typeof item !== "undefined" ? (
        <div className="condition-text">
          <div className="condition-image">
            <img
              alt="Condition Type Logo"
              className="condition-approval-image"
              src={
                type === "project"
                  ? ApartmentIcon
                  : type === "phase"
                  ? LayersBlackIcon
                  : type === "Construction"
                  ? ConstructionIcon
                  : CampaignIcon
              }
            />
          </div>
          &nbsp;
          <div className="text">
            {item.decisionName}
            :&nbsp;
            {item.decisionDesc}
          </div>{" "}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Condition;
