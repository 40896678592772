import React, { useState, useEffect } from "react";
import axios from "axios";
import "../Certification.css";
import { Formik, Form, Field } from "formik";
import WarningIcon from "../../../images/icons/Warning@1x.svg";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SaveIcon from "@material-ui/icons/Save";
import { Accordion, Card } from "react-bootstrap";
import ContextAwareToggle from "../../Profile/ContextAwareToggle";
import FormStepper from "../stepper";
import {
  Button,
  Radio,
  InputAdornment,
  TextField,
  makeStyles,
  FilledInput,
} from "@material-ui/core";
import { CancelButton } from "../CancelButton";
import { isTPORole } from "../../../redux/actions/userAction";
import ApartmentIcon from "../../../images/apartment_black_24dp@2x.svg";
import LayersBlackIcon from "../../../images/layers_black_24dp@1x.svg";
import { certificationFormatter, page7tMultiSelectOptions, page7SetMultiSelectOptions } from '../../shared/services/SharedService'
import { SpecialAssessments } from "../../shared/components/PrintCertificationForms/SpecialAssessments";

import { inOptionsArray } from '../../shared/services/SharedService'
export const Page9 = ({
  formData,
  setFormData,
  streetInfo,
  nextStep,
  prevStep,
  setDocId,
  convResult,
  year1,
  year2,
  year3,
  step,
  status,
  cancelCertification,
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const useStyles = makeStyles((theme) => ({
    input: {
      backgroundColor: "#FCFCFC!important",
      boxShadow:
        "0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px rgba(0,0,0,0.21)",
      borderRadius: "4px",
      height: "3px",
    },
    inputPercent: {
      backgroundColor: "#FCFCFC!important",
      boxShadow: "0 0.4px 0.6px 0 rgba(0,0,0,0.37)",
      borderRadius: "4px",
      height: "3px",
    },
  }));

  const [email, setEmail] = React.useState("");
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const [phone, setPhone] = React.useState("");
  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const [ruleType, setRuleType] = useState("");
  const [phaseType, setPhaseType] = useState(null);
  let splAssmtList = <></>;
  if (formData.fspAssmts.length > 0) {
    let splData = page7tMultiSelectOptions(formData);
    splAssmtList = splData.fspAssmts.map((data, index) => <SpecialAssessments data={data} index={index} />);
  }
  function getRuleType() {
    if (formData.phaseId === "proj") {
      if (convResult < 90) {
        if (
          formData.piAddnPhsOrAnnxInd === "No" &&
          formData.piCntlHOATurnOverInd === "Yes" &&
          formData.piCommnAreasCompltInd === "Yes" &&
          formData.ppUnitsComplete === formData.ppTotalUnits &&
          formData.ppDevOwndUntsRentalHoldBackInd === "Yes" &&
          formData.ppDevOwnedUnits / formData.ppTotalUnits <= 0.2
        ) {
          setRuleType("projectEst");
        } else {
          setRuleType("projectNew");
        }
        setPhaseType(null);
      } else {
        if (
          formData.piAddnPhsOrAnnxInd === "No" &&
          formData.piCntlHOATurnOverInd === "Yes" &&
          formData.piCommnAreasCompltInd === "Yes" &&
          formData.ppUnitsComplete === formData.ppTotalUnits
        ) {
          setRuleType("projectEst");
        } else {
          setRuleType("projectNew");
        }
        setPhaseType(null);
      }
    } else {
      setRuleType("phaseNew");
      setPhaseType(formData.phaseId);
    }
  }

  const goToPrevious = () => {
    let values = page7SetMultiSelectOptions(formData);
    setFormData(values);
    prevStep();
  };

  useEffect(() => {
    getRuleType();
  }, []);
  const passData = JSON.parse(sessionStorage.getItem("passData"));
  const lenderId =
    sessionStorage.getItem("lenderId") ||
    passData.sellerServicerCoNo ||
    "12346";

  const testData = certificationFormatter(formData);

  const [apiErr, setApiErr] = useState("");

  function saveApi() {
    formData = page7tMultiSelectOptions(formData)
    let testData = {
      ruleType: ruleType,
      guideEligibilityAndProjectInfoAndIds: {
        lenderId: lenderId,
        projectId: formData.projectId,
        phaseId: phaseType,
        createdBy: formData.createdBy,
        lenderUserName: formData.userFirstName + " " + formData.userLastName,
        lenderEmail: email,
        lenderPhone: phone,
        guideEligibilityAndProjectInfo: {
          geHotelMotelResortInd: formData.geHotelMotelResortInd,
          geTimeshareCount: formData.geTimeshareCount,
          geHouseBoatInd: formData.geHouseBoatInd,
          geMulLivingSpacesInd: formData.geMulLivingSpacesInd,
          geSplitOwnershipInd: formData.geSplitOwnershipInd,
          geNIncIncomeAbovePmtdInd: formData.geNIncIncomeAbovePmtdInd,
          gePerTotIncNonIncIncome: formData.gePerTotIncNonIncIncome,
          geIncomeFromPermittedSource: formData.geIncomeFromPermittedSource,
          geUnpaidCommonExpAsmtsInd: formData.geUnpaidCommonExpAsmtsInd,
          geCareFacilityInd: formData.geCareFacilityInd,
          gePayMemshpOrLeaseInd: formData.gePayMemshpOrLeaseInd,
          getCmplnHOAOwnshpInd: formData.getCmplnHOAOwnshpInd,
          geEnvHazardInd: formData.geEnvHazardInd,
          geRtFtRefslOrReSlRstnsInd: formData.geRtFtRefslOrReSlRstnsInd,
          piYearBuilt: formData.piYearBuilt,
          piConvtdProjInd: formData.piConvtdProjInd,
          piGutRehabInd: formData.piGutRehabInd,
          piYearConvtd: formData.piYearConvtd,
          piAddnPhsOrAnnxInd: formData.piAddnPhsOrAnnxInd,
          piCntlHOATurnOverInd: formData.piCntlHOATurnOverInd,
          piDtTurndOver: formData.piDtTurndOver,
          piCommnAreasCompltInd: formData.piCommnAreasCompltInd,
          geIsProjActionCeaseToExist: formData.geIsProjActionCeaseToExist,
			    geProjActionToCease: formData.geProjActionToCease,
        },
      },
      projectPresaleAndInvestor: {
        updatedBy: formData.createdBy,
        projectPresaleInvstr: {
          ppActivePendingSpAssmntsInd: formData.ppActivePendingSpAssmntsInd,
          ppDevOwndUntsRentalHoldBackInd: formData.ppDevOwndUntsRentalHoldBackInd,
          ppDevOwnedUnits: formData.ppDevOwnedUnits,
          ppMaintFeesDevOwndCurrentInd: formData.ppMaintFeesDevOwndCurrentInd,
          ppOwnerOccupiedUnits: formData.ppOwnerOccupiedUnits,
          ppPreSaleConveyPercntge: convResult,
          ppSecondHomes: formData.ppSecondHomes,
          ppTotalUnits: formData.ppTotalUnits,
          ppUnitsComplete: formData.ppUnitsComplete,
          ppUnitsConveyed: formData.ppUnitsConveyed,
          ppUnitsRented: formData.ppUnitsRented,
        },
      },
      phasePresaleAndInvestor: {
        updatedBy: formData.createdBy,
        phasePresaleInvstr: {
          phpOwnerOccupiedUnits: formData.phpOwnerOccupiedUnits,
          phpPhases: formData.phpPhases,
          phpPhasesAnnexedToProject: formData.phpPhasesAnnexedToProject,
          phpSecondHomes: formData.phpSecondHomes,
          phpUnits: formData.phpUnits,
          phpUnitsComplete: formData.phpUnitsComplete,
          phpUnitsCurrPhaseAndPrPhase: formData.phpUnitsCurrPhaseAndPrPhase,
          phpUnitsForSale: formData.phpUnitsForSale,
          phpUnitsRented: formData.phpUnitsRented,
          phpUnitsSold: formData.phpUnitsSold,
          phpUnitsSoldOwnerOccpSecondHomeRsdn:
            formData.phpUnitsSoldOwnerOccpSecondHomeRsdn,
        },
      },
      newProjectEligibility: {
        updatedBy: formData.createdBy,
        newProjectEligibility: {
          npeAssrArrgReviewedInd: formData.npeAssrArrgReviewedInd,
          npeFinConExcessInd: formData.npeFinConExcessInd,
          npeLglDocsComplyInd: formData.npeLglDocsComplyInd,
          npeMasterAsscInd: formData.npeMasterAsscInd,
          npePayMnthyAssesLndrRevwPlanInd:
            formData.npePayMnthyAssesLndrRevwPlanInd,
        },
      },
      projectOwnership: {
        updatedBy: formData.createdBy,
        projectOwnership: {
          poLeaseComplyInd: formData.poLeaseComplyInd,
          poLeaseholdInd: formData.poLeaseholdInd,
          poOwnersHaveSoleOwnshpInd: formData.poOwnersHaveSoleOwnshpInd,
          poSnglOwnMoreThanOneInd: formData.poSnglOwnMoreThanOneInd,
          poUnitsSnglOwn: formData.poUnitsSnglOwn,
          poLeaseholdExpDate: formData.poLeaseholdExpDate
        },
      },
      lcFinancials: {
        updatedBy: formData.createdBy,
        financials: {
          fnoOfSpAssmts: formData.fnoOfSpAssmts,
          fresStudyReqInd: formData.fresStudyReqInd,
          fmetDefInd: formData.fmetDefInd,
          fmetDefElem: formData.fmetDefElem,
          fotherReasonMetDef: formData.fotherReasonMetDef,
          fleakRepairInd: formData.fleakRepairInd,
          funitsHOAExpnPastDue: formData.funitsHOAExpnPastDue,
          fexpDateAnlBudget: formData.fexpDateAnlBudget,
          fbugtAnlIncome: formData.fbugtAnlIncome,
          famtExclItems: formData.famtExclItems,
          fbugtAnlReserves: formData.fbugtAnlReserves,
          fdefdMntInd: formData.fdefdMntInd,
          fsplAssmtsInd: formData.fsplAssmtsInd,
          fttlSpAssmts: formData.fttlSpAssmts,
          fspAssmts: formData.fspAssmts,
          fphyDetInd: formData.fphyDetInd,
          fphyDetEle: formData.fphyDetEle,
          fotherReasonPhyDet: formData.fotherReasonPhyDet,
          finspFailInd: formData.finspFailInd,
          funfundRepInd: formData.funfundRepInd
        },
      },
      lcLitigation: {
        updatedBy: formData.createdBy,
        litigation: {
          lcommerclSpaceInd: formData.lcommerclSpaceInd,
          linsurancCvgInd: formData.linsurancCvgInd,
          llitigationInd: formData.llitigationInd,
          lminrMttrLitgtnInd: formData.lminrMttrLitgtnInd,
          lnonResSqFtg: formData.lnonResSqFtg,
          lsafetyLitgtnInd: formData.lsafetyLitgtnInd,
        },
      },
    };
    testData.ruleType = ruleType;
    axios
      .post(
        global.REACT_APP_API_ENDPOINT +
        `/lndrCertification/runLenderCertificationRules`,
        testData
      )
      .then((response) => {
        if (response.status == 200) {
          setApiErr("");
          setTimeout(() => {
            setDocId(response.data);
            nextStep();
          }, 1000);
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            setIsSave(false);
            setApiErr("Internal server error");
          } else {
            setIsSave(false);

            setApiErr(response.data.message);
          }
        }
      })
      .catch((error) => {
        setIsSave(false);
        console.log("certification phase error", error);
      });
  }

  const classes = useStyles();
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [isSave, setIsSave] = useState(false);

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      onSubmit={(values) => {
        setFormData(values);
        if (email.length === 0) {
          setEmailError("Required");
        }

        if (phone.length === 0) {
          setPhoneError("Required");
        }

        if (
          phone &&
          !/^(?:(?:\\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/i.test(
            phone
          )
        ) {
          setPhoneError(
            "Invalid Phone Number. eg((555)-555-5555, 555-555-5555)"
          );
        }

        if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
          setEmailError("Invalid Email Address");
        }

        if (
          phone &&
          !/^(?:(?:\\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/i.test(
            phone
          ) === false &&
          phone.length != 0
        ) {
          setPhoneError("");
        }

        if (
          email &&
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) === false &&
          email.length != 0
        ) {
          setEmailError("");
        }
        if (
          phone &&
          !/^(?:(?:\\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/i.test(
            phone
          ) === false &&
          phone.length != 0 &&
          email &&
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) === false &&
          email.length != 0
        ) {
          setPhoneError("");
          setEmailError("");
          setIsSave(true);
          saveApi();
        }
      }}
    >
      {({ values, errors, touched }) => (
        <Form>
          <div className="certification-container">
            {(convResult < 90 &&
              values.phaseId === "proj" &&
              values.piAddnPhsOrAnnxInd === "No" &&
              values.piCntlHOATurnOverInd === "Yes" &&
              values.piCommnAreasCompltInd === "Yes" &&
              values.ppUnitsComplete === values.ppTotalUnits &&
              values.ppDevOwndUntsRentalHoldBackInd === "Yes" &&
              values.ppDevOwnedUnits / values.ppTotalUnits <= 0.2) ||
              (convResult >= 90 &&
                values.phaseId === "proj" &&
                values.piAddnPhsOrAnnxInd === "No" &&
                values.piCntlHOATurnOverInd === "Yes" &&
                values.piCommnAreasCompltInd === "Yes" &&
                values.ppUnitsComplete === values.ppTotalUnits) ? (
              <FormStepper step={6} type={values.phaseId} class="est" />
            ) : values.phaseId === "proj" ? (
              <FormStepper step={7} type={values.phaseId} class="new" />
            ) : (convResult < 90 &&
              values.piAddnPhsOrAnnxInd === "No" &&
              values.piCntlHOATurnOverInd === "Yes" &&
              values.piCommnAreasCompltInd === "Yes" &&
              values.ppUnitsComplete === values.ppTotalUnits &&
              values.ppDevOwndUntsRentalHoldBackInd === "Yes" &&
              values.ppDevOwnedUnits / values.ppTotalUnits <= 0.2) ||
              (convResult >= 90 &&
                values.piAddnPhsOrAnnxInd === "No" &&
                values.piCntlHOATurnOverInd === "Yes" &&
                values.piCommnAreasCompltInd === "Yes" &&
                values.ppUnitsComplete === values.ppTotalUnits) ? (
              <FormStepper step={7} type={values.phaseId} class="est" />
            ) : (
              <FormStepper step={8} type={values.phaseId} class="new" />
            )}
            <div className="main">
              <CancelButton
                status={status}
                cancelCertification={cancelCertification}
              />
              <div className="title">Certification Eligibility Questions</div>
              <div className="subtitle">
                {values.phaseId === "proj" ? (
                  <>
                    <img
                      alt="Apartment Logo"
                      style={{ paddingRight: "8px", paddingBottom: "4px" }}
                      src={ApartmentIcon}
                    />
                    {streetInfo.projectName}
                  </>
                ) : (
                  <>
                    <img
                      alt="Phase Logo"
                      style={{ paddingRight: "8px", paddingBottom: "4px" }}
                      src={LayersBlackIcon}
                    />
                    {streetInfo.phaseName}
                  </>
                )}
              </div>
              <div className="info">
                <div style={{ display: "flex" }}>
                  <div className="info-container">
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Project ID</div>
                        <div className="attributes">{values.projectId}</div>
                      </>
                    ) : (
                      <>
                        <div className="type">Phase ID</div>
                        <div className="attributes">{values.phaseId}</div>
                      </>
                    )}
                  </div>
                  <div
                    className="info-container"
                    style={{ paddingLeft: "220px" }}
                  >
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Address</div>
                        <div className="attributes">
                          {streetInfo.projectStreet}, {streetInfo.projectCity},{" "}
                          {streetInfo.projectState} {streetInfo.projectZip}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="type">Address</div>
                        <div className="attributes">
                          {streetInfo.phaseStreet}, {streetInfo.phaseCity},{" "}
                          {streetInfo.phaseState} {streetInfo.phaseZip}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div className="info-container" style={{ paddingTop: "0px" }}>
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Project Name</div>
                        <div className="attributes">
                          {streetInfo.projectName}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="type">Project/Phase Name</div>
                        <div className="attributes">
                          {streetInfo.projectName} / {streetInfo.phaseName}
                        </div>
                      </>
                    )}
                  </div>
                  {values.phaseId === "proj" ? (
                    <div
                      className="info-container"
                      style={{ padding: "0px 0px 0px 134px" }}
                    >
                      <>
                        <div className="type">Project Type</div>
                        <div className="attributes">
                          {status === "est" || status === "Incomplete" ? (
                            <>
                              {ruleType === "projectNew" ? (
                                <div>
                                  New
                                  <img
                                    src={WarningIcon}
                                    style={{
                                      paddingLeft: "3px",
                                      paddingBottom: "5px",
                                      width: "20px",
                                      height: "20px",
                                    }}
                                    alt="Warning Logo"
                                  />
                                </div>
                              ) : (
                                <div>Established</div>
                              )}
                            </>
                          ) : status === "new" || status === "Incomplete" ? (
                            <>
                              {ruleType === "projectEst" ? (
                                <div>
                                  Established
                                  <img
                                    src={WarningIcon}
                                    style={{
                                      paddingLeft: "3px",
                                      paddingBottom: "5px",
                                      width: "20px",
                                      height: "20px",
                                    }}
                                    alt="Warning Logo"
                                  />
                                </div>
                              ) : (
                                <div>New</div>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className="form">
                <div className="form-header">
                  {values.phaseId === "proj" ? (
                    <>
                      {ruleType === "projectNew" ? (
                        <div className="subheader">Section 8 OF 8</div>
                      ) : (
                        <div className="subheader">Section 7 OF 7</div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="subheader">Section 9 OF 9</div>
                    </>
                  )}
                  <div className="title">Summary</div>
                </div>
                <div className="form-body">
                  <Accordion defaultActiveKey="1" style={{ marginTop: "16px" }}>
                    <Card
                      className="accordion-header-card"
                      style={{ margin: "0px" }}
                    >
                      <Card.Header className="accordion-header-space">
                        <ContextAwareToggle eventKey="1">
                          <p className="accordion-header-title">
                            1. Guide Eligibility
                          </p>
                        </ContextAwareToggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body className="accordion-body-card">
                          <div style={{ padding: "18px 24px" }}>
                            <div className="question">
                              <div className="number">1.</div>
                              <div>
                                Does the project operate as a hotel, motel, or
                                resort as defined by the{" "}
                                <a
                                  target="_blank"
                                  href="https://www.fanniemae.com/content/guide/selling/b4/2.1/03.html"
                                >
                                  Selling Guide B4-2.1-03
                                </a>
                                ?
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.geHotelMotelResortInd}
                              </div>
                            </div>
                            <div className="question">
                              <div className="number">2.</div>
                              <div>
                                How many timeshare or segmented units within the
                                project? (If none, enter zero)
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.geTimeshareCount}
                              </div>
                            </div>
                            <div className="question">
                              <div className="number">3.</div>{" "}
                              <div>Is the project a houseboat project?</div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.geHouseBoatInd}
                              </div>
                            </div>
                            <div className="question">
                              <div className="number">4.</div>{" "}
                              <div>
                                Do any single units have multiple living spaces?
                                (Does NOT pertain to legally combined units as
                                outlined in
                                <a
                                  target="_blank"
                                  href="https://www.fanniemae.com/content/guide/selling/b4/2.1/03.html"
                                >
                                  {" "}
                                  B4-2.1-03
                                </a>
                                .)
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.geMulLivingSpacesInd}
                              </div>
                            </div>
                            <div className="question">
                              <div className="number">5.</div>
                              <div>
                                Are units in the project subject to{" "}
                                <a
                                  target="_blank"
                                  href="https://www.fanniemae.com/content/guide/selling/b4/2.1/03.html"
                                >
                                  split ownership{" "}
                                </a>
                                arrangements or other arrangements that restrict
                                the unit owner’s ability to occupy the unit such
                                as mandatory rental pooling agreements, common
                                interest apartments or community apartment
                                projects?
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.geSplitOwnershipInd}
                              </div>
                            </div>
                            <div className="question">
                              <div className="number">6.</div>{" "}
                              <div>
                                Does the project/HOA's budget reflect
                                non-incidental income above the amount permitted
                                in the{" "}
                                <a
                                  target="_blank"
                                  href="https://www.fanniemae.com/content/guide/selling/b4/2.1/03.html"
                                >
                                  Selling Guide{" "}
                                </a>
                                from the ownership and/or operation of amenities
                                or services for use by unit owners and the
                                general public?
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.geNIncIncomeAbovePmtdInd}
                              </div>
                            </div>
                            {values.geNIncIncomeAbovePmtdInd === "Yes" ? (
                              <>
                                <div className="question">
                                  <div
                                    className="number"
                                    style={{
                                      borderLeft: "1px solid rgba(0,0,0,0.4)",
                                      borderBottom: "1px solid rgba(0,0,0,0.4)",
                                      marginLeft: "37px",
                                      marginBottom: "10px",
                                    }}
                                  ></div>{" "}
                                  <div style={{ paddingTop: "10px" }}>
                                    What percentage (%) of the Total Income is
                                    reflected as non-incidental income on the
                                    budget?
                                  </div>
                                </div>
                                <div
                                  className="summary-answer"
                                  style={{ paddingLeft: "86px" }}
                                >
                                  <div className="label">Answer:&nbsp;</div>
                                  <div className="attribute">
                                    {values.gePerTotIncNonIncIncome}%
                                  </div>
                                </div>
                                <div className="question">
                                  <div
                                    className="number"
                                    style={{
                                      borderLeft: "1px solid rgba(0,0,0,0.4)",
                                      borderBottom: "1px solid rgba(0,0,0,0.4)",
                                      marginLeft: "37px",
                                      marginBottom: "10px",
                                    }}
                                  ></div>{" "}
                                  <div style={{ paddingTop: "10px" }}>
                                    Is the income from the permitted sources as
                                    outlined in{" "}
                                    <a
                                      target="_blank"
                                      href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B4-Underwriting-Property/Chapter-B4-2-Project-Standards/#Non-Incidental.20Business.20Arrangements"
                                    >
                                      B4-2.1-03
                                    </a>
                                    ?
                                  </div>
                                </div>
                                <div
                                  className="summary-answer"
                                  style={{ paddingLeft: "86px" }}
                                >
                                  <div className="label">Answer:&nbsp;</div>
                                  <div className="attribute">
                                    {values.geIncomeFromPermittedSource}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            <div className="question">
                              <div className="number">7.</div>{" "}
                              <div>
                                Is the project out of compliance with the{" "}
                                <a
                                  target="_blank"
                                  href="https://www.fanniemae.com/content/guide/selling/b4/2.1/03.html"
                                >
                                  Selling Guide requirements
                                </a>{" "}
                                related to the lien priority of unpaid common
                                expense assessments?
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.geUnpaidCommonExpAsmtsInd}
                              </div>
                            </div>
                            <div className="question">
                              <div className="number">8.</div>{" "}
                              <div>
                                Is the project, in whole or in part, operated or
                                owned as a continuing care facility which
                                provides medical and/or supportive services to
                                unit owners? See{" "}
                                <a
                                  target="_blank"
                                  href="https://www.fanniemae.com/content/guide/selling/b4/2.1/03.html"
                                >
                                  Selling Guide reference
                                </a>
                                .
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.geCareFacilityInd}
                              </div>
                            </div>
                            <div className="question">
                              <div className="number">9.</div>{" "}
                              <div>
                                Are residential unit owners required to pay
                                mandatory upfront and/or periodic membership, or
                                recreational lease for the use of amenities
                                (such as country club facilities, golf courses,
                                etc.), that are NOT owned by the HOA or master
                                association and instead are owned by an outside
                                party (including the developer or builder)?
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.gePayMemshpOrLeaseInd}
                              </div>
                            </div>
                            <div className="question">
                              <div className="number">10.</div>{" "}
                              <div>
                                Is the project out of compliance with the{" "}
                                <a
                                  target="_blank"
                                  href="https://www.fanniemae.com/content/guide/selling/b4/2.2/02.html"
                                >
                                  Selling Guide requirements
                                </a>{" "}
                                related to the HOA's ownership of the project's
                                facilities, amenities, common elements, or
                                limited common elements (i.e., are any of these
                                elements owned by an entity other than the HOA)?
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.getCmplnHOAOwnshpInd}
                              </div>
                            </div>
                            <div className="question">
                              <div className="number">11.</div>{" "}
                              <div>
                                Is the lender aware of any unacceptable
                                environmental hazard that has not been
                                appropriately assessed and remediated per
                                sections
                                <a
                                  target="_blank"
                                  href="https://www.fanniemae.com/content/guide/selling/b4/2.1/04.html"
                                >
                                  {" "}
                                  B4-2.1-04
                                </a>
                                ,
                                <a
                                  target="_blank"
                                  href="https://www.fanniemae.com/content/guide/selling/b4/2.1/05.html"
                                >
                                  {" "}
                                  B4-2.1-05
                                </a>
                                , and{" "}
                                <a
                                  target="_blank"
                                  href="https://www.fanniemae.com/content/guide/selling/b4/2.1/06.html"
                                >
                                  B4-2.1-06
                                </a>{" "}
                                of the Selling Guide requirements?
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.geEnvHazardInd}
                              </div>
                            </div>
                            <div className="question">
                              <div className="number">12.</div>{" "}
                              <div>
                                Are units in the project subject to Right of
                                First Refusal or resale restrictions that do not
                                meet the applicable requirements in section{" "}
                                <a
                                  target="_blank"
                                  href="https://www.fanniemae.com/content/guide/selling/b4/2.1/01.html"
                                >
                                  B4-2.1-01
                                </a>
                                ,
                                <a
                                  target="_blank"
                                  href="https://www.fanniemae.com/content/guide/selling/b4/2.2/03.html"
                                >
                                  {" "}
                                  B4-2.2-03
                                </a>
                                , and{" "}
                                <a
                                  target="_blank"
                                  href="https://www.fanniemae.com/content/guide/selling/b5/5.2/02.html"
                                >
                                  B5-5.2-02
                                </a>{" "}
                                of the Selling Guide related to loans with
                                resale restrictions?
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.geRtFtRefslOrReSlRstnsInd}
                              </div>
                            </div>
                            <div className="question">
                              <div className="number">13.</div>{" "}
                              <div>
                                Are there any sale/financing concessions in
                                excess of Fannie Mae’s eligibility policies for
                                mortgage loans even if these concessions are not
                                available to the subject unit, as described in{" "}
                                <a
                                  target="_blank"
                                  href="https://www.fanniemae.com/content/guide/selling/b4/2.1/03.html"
                                >
                                  {" "}
                                  B4-2.1-03
                                </a>
                                ?
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.npeFinConExcessInd}
                              </div>
                            </div>
                            {/* <div className="question">
                              <div className="number">14.</div>{" "}
                              <div>
                              Is the project subject to any action that would cause the project to cease to exist, including termination, deconversion or dissolution of their legal structure or subject to voluntary or involuntary bankruptcy, liquidation or receivership proceedings, or any substantially similar action. This includes projects that have voted or are in the process of voting on any of the actions described above ?
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.geIsProjActionCeaseToExist}
                              </div>
                            </div>
                            {values.geIsProjActionCeaseToExist === "Yes" ? (
                              <>
                                <div className="question">
                                  <div
                                    className="number"
                                    style={{
                                      borderLeft: "1px solid rgba(0,0,0,0.4)",
                                      borderBottom: "1px solid rgba(0,0,0,0.4)",
                                      marginLeft: "37px",
                                      marginBottom: "10px",
                                    }}
                                  ></div>{" "}
                                  <div style={{ paddingTop: "10px" }}>
                                  Choose one of the following 3 options that most closely describes the project ?
                                  </div>
                                </div>
                                <div
                                  className="summary-answer"
                                  style={{ paddingLeft: "86px" }}
                                >
                                  <div className="label">Answer:&nbsp;</div>
                                  <div className="attribute">
                                  {values.geProjActionToCease === "terminating" ? <>Terminating, deconverting or dissolving or any substantially similar action</> : <></>}
                                  {values.geProjActionToCease === "voluntary" ? <>Voluntary or involuntary bankruptcy, liquidation or receivership proceedings, or any substantially similar action</> : <></>}
                                  {values.geProjActionToCease === "NA" ? <>Unable to determine</> : <></>}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )} */}
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                  <Accordion defaultActiveKey="0" style={{ marginTop: "16px" }}>
                    <Card
                      className="accordion-header-card"
                      style={{ margin: "0px" }}
                    >
                      <Card.Header className="accordion-header-space">
                        <ContextAwareToggle eventKey="1">
                          <p className="accordion-header-title">
                            2. Project Information
                          </p>
                        </ContextAwareToggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body className="accordion-body-card">
                          <div style={{ padding: "18px 24px" }}>
                            <div className="question">
                              <div className="number">1.</div>
                              <div>
                                In what year was the project originally built?
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {formData.piYearBuilt}
                              </div>
                            </div>
                            <div className="question">
                              <div className="number">2.</div>
                              <div>Is this a converted project?</div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.piConvtdProjInd}
                              </div>
                            </div>
                            {values.piConvtdProjInd === "Yes" ? (
                              <>
                                <div className="question">
                                  <div
                                    className="number"
                                    style={{
                                      borderLeft: "1px solid rgba(0,0,0,0.4)",
                                      borderBottom: "1px solid rgba(0,0,0,0.4)",
                                      marginLeft: "37px",
                                      marginBottom: "10px",
                                    }}
                                  ></div>{" "}
                                  <div style={{ paddingTop: "10px" }}>
                                    Is the project a gut rehabilitation
                                    conversion as defined in{" "}
                                    <a
                                      target="_blank"
                                      href="https://www.fanniemae.com/content/guide/selling/b4/2.2/02.html"
                                    >
                                      {" "}
                                      B4-2.2-02
                                    </a>{" "}
                                    of the Selling Guide, or a non-gut
                                    conversion that meets the definition of an
                                    Established Project?{" "}
                                  </div>
                                </div>
                                <div
                                  className="summary-answer"
                                  style={{ paddingLeft: "86px" }}
                                >
                                  <div className="label">Answer:&nbsp;</div>
                                  <div className="attribute">
                                    {values.piGutRehabInd}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            {values.piConvtdProjInd === "Yes" ? (
                              <>
                                <div className="question">
                                  <div
                                    className="number"
                                    style={{
                                      borderLeft: "1px solid rgba(0,0,0,0.4)",
                                      borderBottom: "1px solid rgba(0,0,0,0.4)",
                                      marginLeft: "37px",
                                      marginBottom: "10px",
                                    }}
                                  ></div>{" "}
                                  <div style={{ paddingTop: "10px" }}>
                                    What year was the project converted?
                                  </div>
                                </div>
                                <div
                                  className="summary-answer"
                                  style={{ paddingLeft: "86px" }}
                                >
                                  <div className="label">Answer:&nbsp;</div>
                                  <div className="attribute">
                                    {formData.piYearConvtd}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            <div className="question">
                              <div className="number">3.</div>
                              <div>
                                Is the project subject to additional legal
                                phasing or annexation?
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.piAddnPhsOrAnnxInd}
                              </div>
                            </div>
                            <div className="question">
                              <div className="number">4.</div>
                              <div>
                                Has control of the HOA been turned over to the
                                unit purchasers?
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.piCntlHOATurnOverInd}
                              </div>
                            </div>
                            {values.piCntlHOATurnOverInd === "Yes" ? (
                              <>
                                <div className="question">
                                  <div
                                    className="number"
                                    style={{
                                      borderLeft: "1px solid rgba(0,0,0,0.4)",
                                      borderBottom: "1px solid rgba(0,0,0,0.4)",
                                      marginLeft: "37px",
                                      marginBottom: "10px",
                                    }}
                                  ></div>{" "}
                                  <div style={{ paddingTop: "10px" }}>
                                    Year Turned Over
                                  </div>
                                </div>
                                <div
                                  className="summary-answer"
                                  style={{ paddingLeft: "86px" }}
                                >
                                  <div className="label">Answer:&nbsp;</div>
                                  <div className="attribute">
                                    {formData.piDtTurndOver}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            <div className="question">
                              <div className="number">5.</div>
                              <div>
                                Are the common areas and amenities 100% complete
                                for the entire project?
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.piCommnAreasCompltInd}
                              </div>
                            </div>
                            {values.piCommnAreasCompltInd === "No" ? (
                              <>
                                <div className="question">
                                  <div
                                    className="number"
                                    style={{
                                      borderLeft: "1px solid rgba(0,0,0,0.4)",
                                      borderBottom: "1px solid rgba(0,0,0,0.4)",
                                      marginLeft: "37px",
                                      marginBottom: "10px",
                                    }}
                                  ></div>{" "}
                                  <div>
                                    Have acceptable completion assurance
                                    arrangements been reviewed (such as a bond,
                                    cash deposit, letter of credit) to guarantee
                                    the future completion of all project
                                    facilities, common elements and limited
                                    common elements, or is the project still
                                    under construction and not considered
                                    substantially complete?
                                  </div>
                                </div>
                                <div className="summary-answer">
                                  <div className="label">Answer:&nbsp;</div>
                                  <div className="attribute">
                                    {values.npeAssrArrgReviewedInd}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  <Accordion defaultActiveKey="0" style={{ marginTop: "16px" }}>
                    <Card
                      className="accordion-header-card"
                      style={{ margin: "0px" }}
                    >
                      <Card.Header className="accordion-header-space">
                        <ContextAwareToggle eventKey="1">
                          <p className="accordion-header-title">
                            3. Project: Presale and Investor Concentration
                          </p>
                        </ContextAwareToggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body className="accordion-body-card">
                          <div style={{ padding: "18px 24px" }}>
                            <div className="question">
                              <div className="number">1.</div>
                              <div>Total # of Units</div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.ppTotalUnits}
                              </div>
                            </div>
                            <div className="question">
                              <div className="number">2.</div>
                              <div># of Owner-Occupied Units</div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.ppOwnerOccupiedUnits}
                              </div>
                            </div>
                            <div className="question">
                              <div className="number">3.</div>
                              <div>
                                # of Second Homes(if unknown, enter zero and
                                combine primary and second homes in the # of
                                Owner-Occupied Units)
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.ppSecondHomes}
                              </div>
                            </div>
                            <div className="question">
                              <div className="number">4.</div>
                              <div># of Units Rented (Investor)</div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.ppUnitsRented}
                              </div>
                            </div>
                            <div className="question">
                              <div className="number">5.</div>
                              <div># of Units Complete</div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.ppUnitsComplete}
                              </div>
                            </div>
                            <div className="question">
                              <div className="number">6.</div>
                              <div>
                                # of Units Conveyed/Closed (if this is the first
                                sale in the project, enter 1)
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.ppUnitsConveyed}
                              </div>
                            </div>
                            {values.ppDevOwndUntsRentalHoldBackInd !== "" ? (
                              <>
                                <div className="question">
                                  <div
                                    className="number"
                                    style={{
                                      borderLeft: "1px solid rgba(0,0,0,0.4)",
                                      borderBottom: "1px solid rgba(0,0,0,0.4)",
                                      marginLeft: "37px",
                                      marginBottom: "10px",
                                    }}
                                  ></div>{" "}
                                  <div style={{ paddingTop: "10px" }}>
                                    Are there developer-owned units that meet
                                    the rental hold-back requirements as defined
                                    in the
                                    <a
                                      target="_blank"
                                      href="https://www.fanniemae.com/content/guide/selling/b4/2.1/01.html"
                                    >
                                      {" "}
                                      Selling Guide
                                    </a>
                                    ?
                                  </div>
                                </div>
                                <div
                                  className="summary-answer"
                                  style={{ paddingLeft: "86px" }}
                                >
                                  <div className="label">Answer:&nbsp;</div>
                                  <div className="attribute">
                                    {values.ppDevOwndUntsRentalHoldBackInd}
                                  </div>
                                </div>
                                {values.ppDevOwndUntsRentalHoldBackInd ===
                                  "Yes" ? (
                                  <>
                                    <div className="question">
                                      <div
                                        className="number"
                                        style={{
                                          borderLeft:
                                            "1px solid rgba(0,0,0,0.4)",
                                          borderBottom:
                                            "1px solid rgba(0,0,0,0.4)",
                                          marginLeft: "37px",
                                          marginBottom: "10px",
                                        }}
                                      ></div>{" "}
                                      <div style={{ paddingTop: "10px" }}>
                                        How many developer-owned units are held?
                                      </div>
                                    </div>
                                    <div
                                      className="summary-answer"
                                      style={{ paddingLeft: "86px" }}
                                    >
                                      <div className="label">Answer:&nbsp;</div>
                                      <div className="attribute">
                                        {values.ppDevOwnedUnits}
                                      </div>
                                    </div>
                                    <div className="question">
                                      <div
                                        className="number"
                                        style={{
                                          borderLeft:
                                            "1px solid rgba(0,0,0,0.4)",
                                          borderBottom:
                                            "1px solid rgba(0,0,0,0.4)",
                                          marginLeft: "37px",
                                          marginBottom: "10px",
                                        }}
                                      ></div>{" "}
                                      <div style={{ paddingTop: "10px" }}>
                                        Are the maintenance fees for
                                        developer-owned units current?
                                      </div>
                                    </div>
                                    <div
                                      className="summary-answer"
                                      style={{ paddingLeft: "86px" }}
                                    >
                                      <div className="label">Answer:&nbsp;</div>
                                      <div className="attribute">
                                        {values.ppMaintFeesDevOwndCurrentInd}
                                      </div>
                                    </div>
                                    <div className="question">
                                      <div
                                        className="number"
                                        style={{
                                          borderLeft:
                                            "1px solid rgba(0,0,0,0.4)",
                                          borderBottom:
                                            "1px solid rgba(0,0,0,0.4)",
                                          marginLeft: "37px",
                                          marginBottom: "10px",
                                        }}
                                      ></div>{" "}
                                      <div style={{ paddingTop: "10px" }}>
                                        Are there any active or pending special
                                        assessments?
                                      </div>
                                    </div>
                                    <div
                                      className="summary-answer"
                                      style={{ paddingLeft: "86px" }}
                                    >
                                      <div className="label">Answer:&nbsp;</div>
                                      <div className="attribute">
                                        {values.ppActivePendingSpAssmntsInd}
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  {values.phaseId === "proj" ? (
                    <></>
                  ) : (
                    <>
                      <Accordion
                        defaultActiveKey="0"
                        style={{ marginTop: "16px" }}
                      >
                        <Card
                          className="accordion-header-card"
                          style={{ margin: "0px" }}
                        >
                          <Card.Header className="accordion-header-space">
                            <ContextAwareToggle eventKey="1">
                              <p className="accordion-header-title">
                                4. Phase: Presale and Investor Concentration
                              </p>
                            </ContextAwareToggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body className="accordion-body-card">
                              <div style={{ padding: "18px 24px" }}>
                                <div className="question">
                                  <div className="number">1.</div>
                                  <div>
                                    # of Expected Phases at the time of Review
                                  </div>
                                </div>
                                <div className="summary-answer">
                                  <div className="label">Answer:&nbsp;</div>
                                  <div className="attribute">
                                    {values.phpPhases}
                                  </div>
                                </div>
                                <div className="question">
                                  <div className="number">2.</div>
                                  <div>
                                    # of Phases that have been annexed into the
                                    project
                                  </div>
                                </div>
                                <div className="summary-answer">
                                  <div className="label">Answer:&nbsp;</div>
                                  <div className="attribute">
                                    {values.phpPhasesAnnexedToProject}
                                  </div>
                                </div>
                                <div className="question">
                                  <div className="number">1.</div>
                                  <div># of Units</div>
                                </div>
                                <div className="summary-answer">
                                  <div className="label">Answer:&nbsp;</div>
                                  <div className="attribute">
                                    {values.phpUnits}
                                  </div>
                                </div>
                                <div className="question">
                                  <div className="number">2.</div>
                                  <div>
                                    # of Units Complete (include substantially
                                    completed units as defined in{" "}
                                    <a
                                      target="_blank"
                                      href="https://www.fanniemae.com/content/guide/selling/b4/2.2/03.html"
                                    >
                                      {" "}
                                      B4-2.2-03
                                    </a>
                                    )
                                  </div>
                                </div>
                                <div className="summary-answer">
                                  <div className="label">Answer:&nbsp;</div>
                                  <div className="attribute">
                                    {values.phpUnitsComplete}
                                  </div>
                                </div>
                                <div className="question">
                                  <div className="number">3.</div>
                                  <div>
                                    # of Units for Sale (Developer Unsold Units)
                                  </div>
                                </div>
                                <div className="summary-answer">
                                  <div className="label">Answer:&nbsp;</div>
                                  <div className="attribute">
                                    {values.phpUnitsForSale}
                                  </div>
                                </div>
                                <div className="question">
                                  <div className="number">4.</div>
                                  <div>
                                    # of Units Sold (Developer Sales Only)
                                  </div>
                                </div>
                                <div className="summary-answer">
                                  <div className="label">Answer:&nbsp;</div>
                                  <div className="attribute">
                                    {values.phpUnitsSold}
                                  </div>
                                </div>
                                <div className="question">
                                  <div className="number">5.</div>
                                  <div># of Owner-Occupied Units</div>
                                </div>
                                <div className="summary-answer">
                                  <div className="label">Answer:&nbsp;</div>
                                  <div className="attribute">
                                    {values.phpOwnerOccupiedUnits}
                                  </div>
                                </div>
                                <div className="question">
                                  <div className="number">6.</div>
                                  <div># of Second Homes</div>
                                </div>
                                <div className="summary-answer">
                                  <div className="label">Answer:&nbsp;</div>
                                  <div className="attribute">
                                    {values.phpSecondHomes}
                                  </div>
                                </div>
                                <div className="question">
                                  <div className="number">7.</div>
                                  <div># of Units Rented (Investor)</div>
                                </div>
                                <div className="summary-answer">
                                  <div className="label">Answer:&nbsp;</div>
                                  <div className="attribute">
                                    {values.phpUnitsRented}
                                  </div>
                                </div>
                                <div className="question">
                                  <div className="number">8.</div>
                                  <div>
                                    # of units in current phase plus # of units
                                    in all prior legal phases (for example, if
                                    project has 5 phases of 100 units each, and
                                    the current phase is 3rd phase, then the
                                    cumulative number of units would be 300).
                                  </div>
                                </div>
                                <div className="summary-answer">
                                  <div className="label">Answer:&nbsp;</div>
                                  <div className="attribute">
                                    {values.phpUnitsCurrPhaseAndPrPhase}
                                  </div>
                                </div>
                                <div className="question">
                                  <div className="number">9.</div>
                                  <div>
                                    # of units sold to owner occupied and second
                                    home residences in the current and all prior
                                    legal phases?
                                  </div>
                                </div>
                                <div className="summary-answer">
                                  <div className="label">Answer:&nbsp;</div>
                                  <div className="attribute">
                                    {values.phpUnitsSoldOwnerOccpSecondHomeRsdn}
                                  </div>
                                </div>
                              </div>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    </>
                  )}

                  {(convResult < 90 &&
                    values.phaseId === "proj" &&
                    values.piAddnPhsOrAnnxInd === "No" &&
                    values.piCntlHOATurnOverInd === "Yes" &&
                    values.piCommnAreasCompltInd === "Yes" &&
                    values.ppUnitsComplete === values.ppTotalUnits &&
                    values.ppDevOwndUntsRentalHoldBackInd === "Yes" &&
                    values.ppDevOwnedUnits / values.ppTotalUnits <= 0.2) ||
                    (convResult >= 90 &&
                      values.phaseId === "proj" &&
                      values.piAddnPhsOrAnnxInd === "No" &&
                      values.piCntlHOATurnOverInd === "Yes" &&
                      values.piCommnAreasCompltInd === "Yes" &&
                      values.ppUnitsComplete === values.ppTotalUnits) ? (
                    <></>
                  ) : (
                    <Accordion
                      defaultActiveKey="0"
                      style={{ marginTop: "16px" }}
                    >
                      <Card
                        className="accordion-header-card"
                        style={{ margin: "0px" }}
                      >
                        <Card.Header className="accordion-header-space">
                          <ContextAwareToggle eventKey="1">
                            {values.phaseId === "proj" ? (
                              <p className="accordion-header-title">
                                4. New Project Eligibility
                              </p>
                            ) : (
                              <p className="accordion-header-title">
                                5. New Project Eligibility
                              </p>
                            )}
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                          <Card.Body className="accordion-body-card">
                            <div style={{ padding: "18px 24px" }}>
                              <div className="question">
                                <div className="number">1.</div>{" "}
                                <div>Is there a master association?</div>
                              </div>
                              <div className="summary-answer">
                                <div className="label">Answer:&nbsp;</div>
                                <div className="attribute">
                                  {values.npeMasterAsscInd}
                                </div>
                              </div>
                              {values.npeMasterAsscInd === "Yes" ? (
                                <>
                                  <div className="question">
                                    <div
                                      className="number"
                                      style={{
                                        borderLeft: "1px solid rgba(0,0,0,0.4)",
                                        borderBottom:
                                          "1px solid rgba(0,0,0,0.4)",
                                        marginLeft: "37px",
                                        marginBottom: "10px",
                                      }}
                                    ></div>{" "}
                                    <div style={{ paddingTop: "10px" }}>
                                      If the unit owners are required to pay
                                      monthly assessments &gt;$50 to a separate
                                      master association, has the lender
                                      reviewed the complete development plan for
                                      the master, and confirmed the
                                      acceptability of the project?(if not
                                      applicable, select Yes).
                                    </div>
                                  </div>
                                  <div
                                    className="summary-answer"
                                    style={{ paddingLeft: "86px" }}
                                  >
                                    <div className="label">Answer:&nbsp;</div>
                                    <div className="attribute">
                                      {values.npePayMnthyAssesLndrRevwPlanInd}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              <div className="question">
                                <div className="number">2.</div>{" "}
                                <div>
                                  Do the project’s legal documents comply with
                                  each of the requirements set forth in the
                                  <a
                                    target="_blank"
                                    href="https://www.fanniemae.com/content/guide/selling/b4/2.2/03.html"
                                  >
                                    {" "}
                                    Selling Guide
                                  </a>
                                  ?
                                </div>
                              </div>
                              <div className="summary-answer">
                                <div className="label">Answer:&nbsp;</div>
                                <div className="attribute">
                                  {values.npeLglDocsComplyInd}
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  )}

                  <Accordion defaultActiveKey="0" style={{ marginTop: "16px" }}>
                    <Card
                      className="accordion-header-card"
                      style={{ margin: "0px" }}
                    >
                      <Card.Header className="accordion-header-space">
                        <ContextAwareToggle eventKey="1">
                          {(convResult < 90 &&
                            values.phaseId === "proj" &&
                            values.piAddnPhsOrAnnxInd === "No" &&
                            values.piCntlHOATurnOverInd === "Yes" &&
                            values.piCommnAreasCompltInd === "Yes" &&
                            values.ppUnitsComplete === values.ppTotalUnits &&
                            values.ppDevOwndUntsRentalHoldBackInd === "Yes" &&
                            values.ppDevOwnedUnits / values.ppTotalUnits <=
                            0.2) ||
                            (convResult >= 90 &&
                              values.phaseId === "proj" &&
                              values.piAddnPhsOrAnnxInd === "No" &&
                              values.piCntlHOATurnOverInd === "Yes" &&
                              values.piCommnAreasCompltInd === "Yes" &&
                              values.ppUnitsComplete === values.ppTotalUnits) ? (
                            <p className="accordion-header-title">
                              4. Project Ownership
                            </p>
                          ) : values.phaseId === "proj" ? (
                            <p className="accordion-header-title">
                              5. Project Ownership
                            </p>
                          ) : (
                            <p className="accordion-header-title">
                              6. Project Ownership
                            </p>
                          )}
                        </ContextAwareToggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body className="accordion-body-card">
                          <div style={{ padding: "18px 24px" }}>
                            <div className="question">
                              <div className="number">1.</div>{" "}
                              <div>
                                Will the unit owners have the sole ownership
                                interest in, and rights to the use of, the
                                project’s facilities, common elements, and
                                limited common elements (unless two or more HOAs
                                share amenities and have an agreement in place
                                governing such arrangement)?
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.poOwnersHaveSoleOwnshpInd}
                              </div>
                            </div>
                            <div className="question">
                              <div className="number">2.</div>{" "}
                              <div>
                                {" "}
                                Is the project built on a leasehold estate?
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.poLeaseholdInd}
                              </div>
                            </div>
                            {values.poLeaseholdInd === "Yes" ? (
                              <>
                                <div className="question">
                                  <div
                                    className="number"
                                    style={{
                                      borderLeft: "1px solid rgba(0,0,0,0.4)",
                                      borderBottom: "1px solid rgba(0,0,0,0.4)",
                                      marginLeft: "37px",
                                      marginBottom: "10px",
                                    }}
                                  ></div>{" "}
                                  <div style={{ paddingTop: "10px" }}>
                                    Does the leasehold comply with Fannie Mae
                                    requirements outlined in{" "}
                                    <a
                                      target="_blank"
                                      href="https://www.fanniemae.com/content/guide/selling/b2/3/03.html"
                                    >
                                      {" "}
                                      B2-3-03
                                    </a>
                                    ?
                                  </div>
                                </div>
                                <div
                                  className="summary-answer"
                                  style={{ paddingLeft: "86px" }}
                                >
                                  <div className="label">Answer:&nbsp;</div>
                                  <div className="attribute">
                                    {values.poLeaseComplyInd}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            {values.poLeaseComplyInd === "Yes" ? (
                              <>
                                <div className="question">
                                  <div
                                    className="number"
                                    style={{
                                      borderLeft: "1px solid rgba(0,0,0,0.4)",
                                      borderBottom: "1px solid rgba(0,0,0,0.4)",
                                      marginLeft: "74px",
                                      marginBottom: "10px",
                                    }}
                                  ></div>{" "}
                                  <div style={{ paddingTop: "10px" }}>
                                    Leasehold Expiration Date?{" "}
                                  </div>
                                </div>
                                <div
                                  className="summary-answer"
                                  style={{ paddingLeft: "120px" }}
                                >
                                  <div className="label">Answer:&nbsp;</div>
                                  <div className="attribute">
                                    {values.poLeaseholdExpDate}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            <div className="question">
                              <div className="number">3.</div>{" "}
                              <div>
                                Does a single entity own more units in the
                                project than what is allowed by the{" "}
                                <a
                                  target="_blank"
                                  href="https://www.fanniemae.com/content/guide/selling/b4/2.2/03.html"
                                >
                                  {" "}
                                  Selling Guide
                                </a>
                                ?{" "}
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.poSnglOwnMoreThanOneInd}
                              </div>
                            </div>
                            {values.poSnglOwnMoreThanOneInd === "Yes" ? (
                              <>
                                <div className="question">
                                  <div
                                    className="number"
                                    style={{
                                      borderLeft: "1px solid rgba(0,0,0,0.4)",
                                      borderBottom: "1px solid rgba(0,0,0,0.4)",
                                      marginLeft: "37px",
                                      marginBottom: "10px",
                                    }}
                                  ></div>{" "}
                                  <div style={{ paddingTop: "10px" }}>
                                    How many units does the single-entity own?
                                    (For multiple single entities, enter the
                                    number for the largest single entity or
                                    combine the total units for affiliated
                                    single entities){" "}
                                  </div>
                                </div>
                                <div
                                  className="summary-answer"
                                  style={{ paddingLeft: "86px" }}
                                >
                                  <div className="label">Answer:&nbsp;</div>
                                  <div className="attribute">
                                    {values.poUnitsSnglOwn}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  <Accordion defaultActiveKey="0" style={{ marginTop: "16px" }}>
                    <Card
                      className="accordion-header-card"
                      style={{ margin: "0px" }}
                    >
                      <Card.Header className="accordion-header-space">
                        <ContextAwareToggle eventKey="1">
                          {(convResult < 90 &&
                            values.phaseId === "proj" &&
                            values.piAddnPhsOrAnnxInd === "No" &&
                            values.piCntlHOATurnOverInd === "Yes" &&
                            values.piCommnAreasCompltInd === "Yes" &&
                            values.ppUnitsComplete === values.ppTotalUnits &&
                            values.ppDevOwndUntsRentalHoldBackInd === "Yes" &&
                            values.ppDevOwnedUnits / values.ppTotalUnits <=
                            0.2) ||
                            (convResult >= 90 &&
                              values.phaseId === "proj" &&
                              values.piAddnPhsOrAnnxInd === "No" &&
                              values.piCntlHOATurnOverInd === "Yes" &&
                              values.piCommnAreasCompltInd === "Yes" &&
                              values.ppUnitsComplete === values.ppTotalUnits) ? (
                            <p className="accordion-header-title">
                              5. Financials, Special Assessments & Critical Repairs
                            </p>
                          ) : values.phaseId === "proj" ? (
                            <p className="accordion-header-title">
                              6. Financials, Special Assessments & Critical Repairs
                            </p>
                          ) : (
                            <p className="accordion-header-title">
                              7. Financials, Special Assessments & Critical Repairs
                            </p>
                          )}
                        </ContextAwareToggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body className="accordion-body-card">
                          <div style={{ padding: "18px 24px" }}>
                            <div className="question">
                              <div className="number">1.</div>
                              <div>
                                How many units are ≥60 days past due on their
                                HOA common expense assessments?
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.funitsHOAExpnPastDue}
                              </div>
                            </div>
                            <div className="question">
                              <div className="number">2.</div>
                              <div>
                                What is the expiration date of the current
                                annual budget?{" "}
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.fexpDateAnlBudget}
                              </div>
                            </div>
                            <div className="question">
                              <div className="number">3.</div>
                              <div>
                                What are the budgeted annual assessment income?
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.fbugtAnlIncome}
                              </div>
                            </div>
                            <div className="question">
                              <div className="number">4.</div>
                              <div>
                                What is the total amount of exclusionary items
                                as defined by the{" "}
                                <a
                                  target="_blank"
                                  href="https://www.fanniemae.com/content/guide/selling/b4/2.2/02.html"
                                >
                                  {" "}
                                  Selling Guide B4-2.2-02
                                </a>
                                ?(Put zero if not applicable)
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.famtExclItems}
                              </div>
                            </div>
                            <div className="question">
                              <div className="number">5.</div>
                              <div>What are the budgeted annual reserves?</div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.fbugtAnlReserves}
                              </div>
                            </div>
                            {
                              (
                                (values.fbugtAnlReserves /
                                  (values.fbugtAnlIncome -
                                    values.famtExclItems)) *
                                100 *
                                1
                              ) < 10 ?
                                <>
                                  <div className="question">
                                    <div
                                      className="number"
                                      style={{
                                        borderLeft: "1px solid rgba(0,0,0,0.4)",
                                        borderBottom: "1px solid rgba(0,0,0,0.4)",
                                        marginLeft: "37px",
                                        marginBottom: "10px",
                                      }}
                                    ></div>{" "}
                                    <div style={{ paddingTop: "10px" }}>
                                      The lender has reviewed a reserve study that meets the requirements in Section
                                      <a
                                        target="_blank"
                                        href="https://www.fanniemae.com/content/guide/selling/b4/2.2/03.html"
                                      >
                                        {" "}
                                        B4-2.2-02
                                      </a> including demonstrating that the project's funded reserves provides adequate financial protection and meet or exceed the recommendations included in the reserve study.
                                    </div>
                                  </div>
                                  <div
                                    className="summary-answer"
                                    style={{ paddingLeft: "86px" }}
                                  >
                                    <div className="label">Answer:&nbsp;</div>
                                    <div className="attribute">
                                      {values.fresStudyReqInd}
                                    </div>
                                  </div>
                                </>
                                : <></>
                            }
                            <div className="question">
                              <div className="number">6.</div>{" "}
                              <div>
                                How many planned or active special assessments have been issued  <a
                                  target="_blank"
                                  href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B4-Underwriting-Property/Chapter-B4-2-Project-Standards/#Projects.20in.20Need.20of.20Critical.20Repairs">
                                  {" "}
                                  B4-2.1-03
                                </a>? (if none, enter zero)?If the HOA does not separately track special assessments, enter 1
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.fnoOfSpAssmts}
                              </div>
                            </div>
                            {
                              values.fnoOfSpAssmts > 0 ?
                                <>
                                  <hr style={{ borderTop: "dotted 1px", color: "#2e2e2e" }} />
                                  {splAssmtList}
                                </>
                                : (<></>)
                            }
                            <div className="question">
                              <div className="number">7.</div>{" "}
                              <div>
                                Is the project subject to partial or total evacuation order due to unsafe conditions that have not been remediated?
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.fdefdMntInd}
                              </div>
                            </div>
                            <div className="question">
                              <div className="number">8.</div>{" "}
                              <div>
                                Does the project have any material deficiencies which, if left uncorrected, have the potential to result in or contribute to critical element or system failure within one year (e.g., sea wall, elevators, waterproofing, stairwells)?
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.fmetDefInd}
                              </div>
                            </div>
                            {
                              values.fmetDefInd === "Yes" && values.fmetDefElem?.length > 0 ?
                                <>
                                  <div className="question">
                                    <div
                                      className="number"
                                      style={{
                                        borderLeft: "1px solid rgba(0,0,0,0.4)",
                                        borderBottom: "1px solid rgba(0,0,0,0.4)",
                                        marginLeft: "37px",
                                        marginBottom: "10px",
                                      }}
                                    ></div>{" "}
                                    <div style={{ paddingTop: "10px" }}>
                                      What elements are impacted? (Check all that apply)?
                                    </div>
                                  </div>
                                  <div
                                    className="summary-answer"
                                    style={{ paddingLeft: "86px" }}
                                  >
                                    <div className="label">Answer:&nbsp;</div>
                                    <div className="attribute">
                                      {values.fmetDefElem.toString()}
                                    </div>
                                  </div>
                                  {
                                    inOptionsArray(values.fmetDefElem, 'Other') ?
                                      (<>
                                        <>
                                          <div className="question">
                                            <div
                                              className="number"
                                              style={{
                                                borderLeft: "1px solid rgba(0,0,0,0.4)",
                                                borderBottom: "1px solid rgba(0,0,0,0.4)",
                                                marginLeft: "70px",
                                                marginBottom: "10px",
                                              }}
                                            ></div>{" "}
                                            <div style={{ paddingTop: "10px" }}>
                                              Other Reason?
                                            </div>
                                          </div>
                                          <div
                                            className="summary-answer"
                                            style={{ paddingLeft: "86px" }}
                                          >
                                            <div className="label">Answer:&nbsp;</div>
                                            <div className="attribute">
                                              {values.fotherReasonMetDef}
                                            </div>
                                          </div>
                                        </>
                                      </>) :
                                      (<></>)
                                  }
                                </>
                                : <></>
                            }
                            <div className="question">
                              <div className="number">9.</div>{" "}
                              <div>
                                Is there any mold, water intrusions or potentially damaging leaks to the project's building(s) have not been repaired?
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.fleakRepairInd}
                              </div>
                            </div>
                            <div className="question">
                              <div className="number">10.</div>{" "}
                              <div>
                                Is there any advanced physical deterioration?
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.fphyDetInd}
                              </div>
                            </div>
                            {
                              values.fphyDetInd === "Yes" && values.fphyDetEle.length > 0 ?
                                <>
                                  <div className="question">
                                    <div
                                      className="number"
                                      style={{
                                        borderLeft: "1px solid rgba(0,0,0,0.4)",
                                        borderBottom: "1px solid rgba(0,0,0,0.4)",
                                        marginLeft: "37px",
                                        marginBottom: "10px",
                                      }}
                                    ></div>{" "}
                                    <div style={{ paddingTop: "10px" }}>
                                      What elements are impacted? (Check all that apply)?
                                    </div>
                                  </div>
                                  <div
                                    className="summary-answer"
                                    style={{ paddingLeft: "86px" }}
                                  >
                                    <div className="label">Answer:&nbsp;</div>
                                    <div className="attribute">
                                      {values.fphyDetEle.toString()}
                                    </div>
                                  </div>
                                  {
                                    inOptionsArray(values.fphyDetEle, 'Other') ?
                                      (<>
                                        <>
                                          <div className="question">
                                            <div
                                              className="number"
                                              style={{
                                                borderLeft: "1px solid rgba(0,0,0,0.4)",
                                                borderBottom: "1px solid rgba(0,0,0,0.4)",
                                                marginLeft: "70px",
                                                marginBottom: "10px",
                                              }}
                                            ></div>{" "}
                                            <div style={{ paddingTop: "10px" }}>
                                              Other Reason?
                                            </div>
                                          </div>
                                          <div
                                            className="summary-answer"
                                            style={{ paddingLeft: "86px" }}
                                          >
                                            <div className="label">Answer:&nbsp;</div>
                                            <div className="attribute">
                                              {values.fotherReasonPhyDet}
                                            </div>
                                          </div>
                                        </>
                                      </>) :
                                      (<></>)
                                  }
                                </>
                                : <></>
                            }

                            <div className="question">
                              <div className="number">11.</div>{" "}
                              <div>
                                Has the project failed to pass state or other jurisdictional mandatory inspections and/or certifications specific to structural soundness, safety, and habitability?
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.finspFailInd}
                              </div>
                            </div>
                            <div className="question">
                              <div className="number">12.</div>{" "}
                              <div>
                                Are there any unfunded repairs costing more than $10,000 per unit that should be undertaken within the next 12 months (does not include repairs made by the unit owner or repairs funded through a special assessment)
                                </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.funfundRepInd}
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  <Accordion defaultActiveKey="0" style={{ marginTop: "16px" }}>
                    <Card
                      className="accordion-header-card"
                      style={{ margin: "0px" }}
                    >
                      <Card.Header className="accordion-header-space">
                        <ContextAwareToggle eventKey="1">
                          {(convResult < 90 &&
                            values.phaseId === "proj" &&
                            values.piAddnPhsOrAnnxInd === "No" &&
                            values.piCntlHOATurnOverInd === "Yes" &&
                            values.piCommnAreasCompltInd === "Yes" &&
                            values.ppUnitsComplete === values.ppTotalUnits &&
                            values.ppDevOwndUntsRentalHoldBackInd === "Yes" &&
                            values.ppDevOwnedUnits / values.ppTotalUnits <=
                            0.2) ||
                            (convResult >= 90 &&
                              values.phaseId === "proj" &&
                              values.piAddnPhsOrAnnxInd === "No" &&
                              values.piCntlHOATurnOverInd === "Yes" &&
                              values.piCommnAreasCompltInd === "Yes" &&
                              values.ppUnitsComplete === values.ppTotalUnits) ? (
                            <p className="accordion-header-title">
                              6. Litigation, Usage & Insurance
                            </p>
                          ) : values.phaseId === "proj" ? (
                            <p className="accordion-header-title">
                              7. Litigation, Usage & Insurance
                            </p>
                          ) : (
                            <p className="accordion-header-title">
                              8. Litigation, Usage & Insurance
                            </p>
                          )}
                        </ContextAwareToggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body className="accordion-body-card">
                          <div style={{ padding: "18px 24px" }}>
                            <div className="question">
                              <div className="number">1.</div>{" "}
                              <div>
                                Is there any pre-litigation or litigation
                                associated with the project, and naming either
                                the HOA or Developer as a party?
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.llitigationInd}
                              </div>
                            </div>
                            {values.llitigationInd === "Yes" ? (
                              <>
                                <div className="question">
                                  <div
                                    className="number"
                                    style={{
                                      borderLeft: "1px solid rgba(0,0,0,0.4)",
                                      borderBottom: "1px solid rgba(0,0,0,0.4)",
                                      marginLeft: "37px",
                                      marginBottom: "10px",
                                    }}
                                  ></div>{" "}
                                  <div style={{ paddingTop: "10px" }}>
                                    Is the project currently in litigation
                                    related to the safety, structural soundness,
                                    habitability, or functional use of the
                                    project?
                                  </div>
                                </div>
                                <div
                                  className="summary-answer"
                                  style={{ paddingLeft: "86px" }}
                                >
                                  <div className="label">Answer:&nbsp;</div>
                                  <div className="attribute">
                                    {values.lsafetyLitgtnInd}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            {values.llitigationInd === "Yes" ? (
                              <>
                                <div className="question">
                                  <div
                                    className="number"
                                    style={{
                                      borderLeft: "1px solid rgba(0,0,0,0.4)",
                                      borderBottom: "1px solid rgba(0,0,0,0.4)",
                                      marginLeft: "37px",
                                      marginBottom: "10px",
                                    }}
                                  ></div>{" "}
                                  <div style={{ paddingTop: "10px" }}>
                                    Is the project currently in litigation
                                    involving minor matters as defined in the{" "}
                                    <a
                                      target="_blank"
                                      href="https://www.fanniemae.com/content/guide/selling/b4/2.1/03.html"
                                    >
                                      {" "}
                                      Selling Guide
                                    </a>
                                    ?
                                  </div>
                                </div>
                                <div
                                  className="summary-answer"
                                  style={{ paddingLeft: "86px" }}
                                >
                                  <div className="label">Answer:&nbsp;</div>
                                  <div className="attribute">
                                    {values.lminrMttrLitgtnInd}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            <div className="question">
                              <div className="number">2.</div>{" "}
                              <div>
                                Does the project or building in which the
                                project is located contain commercial or
                                mixed-use space?
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.lcommerclSpaceInd}
                              </div>
                            </div>
                            {values.lcommerclSpaceInd === "Yes" ? (
                              <>
                                <div className="question">
                                  <div
                                    className="number"
                                    style={{
                                      borderLeft: "1px solid rgba(0,0,0,0.4)",
                                      borderBottom: "1px solid rgba(0,0,0,0.4)",
                                      marginLeft: "37px",
                                      marginBottom: "10px",
                                    }}
                                  ></div>{" "}
                                  <div style={{ paddingTop: "10px" }}>
                                    What is the total percentage used for
                                    non-residential purposes as defined by the{" "}
                                    <a
                                      target="_blank"
                                      href="https://www.fanniemae.com/content/guide/selling/b4/2.1/03.html"
                                    >
                                      {" "}
                                      Selling Guide
                                    </a>
                                    ?
                                  </div>
                                </div>
                                <div
                                  className="summary-answer"
                                  style={{ paddingLeft: "86px" }}
                                >
                                  <div className="label">Answer:&nbsp;</div>
                                  <div className="attribute">
                                    {values.lnonResSqFtg}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            <div className="question">
                              <div className="number">3.</div>{" "}
                              <div>
                                Does the project’s insurance coverage (including
                                property, flood, liability, and fidelity/crime,
                                as applicable) meet the Selling Guide
                                requirements in Section{" "}
                                <a
                                  target="_blank"
                                  href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B7-Insurance/Chapter-B7-3-Property-and-Flood-Insurance/"
                                >
                                  {" "}
                                  B7-3
                                </a>{" "}
                                and{" "}
                                <a
                                  target="_blank"
                                  href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B7-Insurance/Chapter-B7-4-Liability-and-Fidelity-Crime-Insurance/"
                                >
                                  {" "}
                                  B7-4
                                </a>
                                ?
                              </div>
                            </div>
                            <div className="summary-answer">
                              <div className="label">Answer:&nbsp;</div>
                              <div className="attribute">
                                {values.linsurancCvgInd}
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  <div className="user-agreement">
                    <div className="title">User Agreement</div>
                    {!isTPORole() &&
                      <div className="disclaimer-container">
                        Lender certifies and agrees that (i) the Lender has
                        reviewed and recommends the project for approval by Fannie
                        Mae; (ii) all provisions of the Fannie Mae Selling Guide
                        (collectively, including all Announcements, the “Selling
                        Guide”) pertaining to the Full Review of condo projects
                        using CPM will apply to the subject project and that
                        Lender is responsible for ensuring full compliance with
                        all applicable provisions; (iii) the information and
                        statements contained in this application are true and
                        correct; and (iv) to the best of Lender’s knowledge, there
                        is nothing about the subject property that would make the
                        project ineligible or cause the project to be ineligible
                        for the Full Review process using CPM. Lender agrees that
                        the questions included in CPM are provided to assist the
                        Lender with the Full Review process, that CPM
                        Certifications are based solely on data that the Lender
                        enters in CPM and that additional project eligibility
                        requirements may be included in the Selling Guide. If
                        there is any conflict between the project eligibility
                        requirements in CPM and those in the Selling Guide, the
                        Selling Guide provisions will govern. The Lender must
                        notify the CPM Management team if it becomes aware of any
                        information that could impact the eligibility status
                        reflected in CPM and must provide such notification as
                        soon as practicable but no later than five days after
                        becoming aware of such information. In such instances,
                        Lender must confirm the project’s approved status before
                        selling a loan to Fannie Mae that is secured by one of the
                        project’s units. Fannie Mae reserves the right to change a
                        project’s eligibility status designation after
                        certification if information acquired has an impact on a
                        previously issued eligibility determination.
                      </div>
                    }
                    {isTPORole() &&
                      <div className="disclaimer-container">
                        Licensee certifies and agrees that (i) the Licensee has
                        reviewed and recommends the project for approval by Fannie
                        Mae; (ii) all provisions of the Fannie Mae Selling Guide
                        (collectively, including all Announcements, the “Selling
                        Guide”) pertaining to the Full Review of condo projects
                        using CPM will apply to the subject project and that
                        Licensee is responsible for ensuring full compliance with
                        all applicable provisions; (iii) the information and
                        statements contained in this application are true and
                        correct; and (iv) to the best of Licensee’s knowledge, there
                        is nothing about the subject property that would make the
                        project ineligible or cause the project to be ineligible
                        for the Full Review process using CPM. Licensee agrees that
                        the questions included in CPM are provided to assist the
                        Licensee with the Full Review process, that CPM
                        Certifications are based solely on data that the Licensee
                        enters in CPM and that additional project eligibility
                        requirements may be included in the Selling Guide. If
                        there is any conflict between the project eligibility
                        requirements in CPM and those in the Selling Guide, the
                        Selling Guide provisions will govern. The Licensee must
                        notify the CPM Management team if it becomes aware of any
                        information that could impact the eligibility status
                        reflected in CPM and must provide such notification as
                        soon as practicable but no later than five days after
                        becoming aware of such information. In such instances,
                        Licensee must confirm the project’s approved status before
                        selling a loan to Fannie Mae that is secured by one of the
                        project’s units. Fannie Mae reserves the right to change a
                        project’s eligibility status designation after
                        certification if information acquired has an impact on a
                        previously issued eligibility determination.
                      </div>
                    }

                    <div
                      style={{
                        display: "flex",
                        marginTop: "16px",
                        marginBottom: "35px",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <div className="info-type">
                          Lender Contact Email
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        <Field
                          name="lenderEmail"
                          as={TextField}
                          inputProps={{
                            className: classes.input,
                            maxLength: 100,
                          }}
                          color="#FCFCFC"
                          variant="outlined"
                          value={email}
                          onChange={handleEmailChange}
                        />
                        <div className="form-error">{emailError}</div>
                      </div>
                      <div>
                        <div className="info-type">
                          Lender Contact Phone
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        <Field
                          name="lenderPhone"
                          as={TextField}
                          inputProps={{
                            className: classes.input,
                            maxLength: 16,
                          }}
                          color="#FCFCFC"
                          variant="outlined"
                          value={phone}
                          onChange={handlePhoneChange}
                        />
                        <div
                          className="form-error"
                          style={{ maxWidth: "179px" }}
                        >
                          {phoneError}
                        </div>
                      </div>
                    </div>
                    <div className="info-type">
                      Electronic Signature
                      <span style={{ color: "red" }}>*</span>
                    </div>
                    <input
                      required
                      type="checkbox"
                      id="signature"
                      name="signature"
                    />
                    <label for="signature">
                      &nbsp;&nbsp;{values.userFirstName} {values.userLastName}
                    </label>
                  </div>
                </div>
                <div
                  style={{
                    textAlign: "right",
                    color: "#ff0000",
                    padding: 15,
                  }}
                >
                  {apiErr ? apiErr : null}
                </div>
                <div
                  className="form-footer"
                  style={{ flexDirection: "row-reverse" }}
                >
                  <div
                    style={{ display: "flex", flexDirection: "row-reverse" }}
                  >
                    <button
                      className="complete-cert-btn"
                      type="submit"
                      disabled={isSave}
                    >
                      Complete Certification {isSave}
                    </button>
                  </div>
                  <button
                    className="next-button"
                    type="submit"
                    onClick={() => goToPrevious()}
                  >
                    <ArrowBackIcon />
                    Previous Section
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};