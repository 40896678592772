import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import "./Pers.css";
import { TextField } from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CloseIcon from "@material-ui/icons/Close";

const PersStatus = props => {
  const [show, setShow] = useState(false);
  const [letter, setLetter] = useState(false);

  const handleClose = () => setShow(false);
  const handleRefresh = () => window.location.reload(false);
  const handleShow = () => setShow(true);
  const handleLetter = () => setLetter(true);
  
  const createConditionalLetter = () => {
    sleep(3000);
    window.location =
      "/conditional-approval/" +
      props.project_id +
      "/" +
      props.pers_id +
      "/" +
      props.pers_type;
  };

  const createFinalLetter = () => {
    sleep(3000);
    window.location =
      "/final-approval/" +
      props.project_id +
      "/" +
      props.pers_id +
      "/" +
      props.pers_type;
  };

  const createSuspendedLetter = () => {
    sleep(3000);
    window.location =
      "/suspended/" +
      props.project_id +
      "/" +
      props.pers_id +
      "/" +
      props.pers_type;
  };

  const createIneligibleLetter = () => {
    sleep(3000);
    window.location =
      "/ineligible-letter/" +
      props.project_id +
      "/" +
      props.pers_id +
      "/" +
      props.pers_type;
  };

  if (!!window.performance && window.performance.navigation.type == 2) {
    window.location.reload();
  }


  let [persStatusData, setPersStatusData] = React.useState("");
  const fetchPersStatusData = React.useCallback(() => {
    axios
      .get(global.REACT_APP_API_ENDPOINT + `/condoMgr/lookUpValues/PERS_REVIEW_STATUS`)
      .then(response => {
        // console.log("responsePERS_REVIEW_STATUS ", response.data);
        setPersStatusData(response.data);
      })
      .catch(error => {
        throw Error(error);
      });
  }, []);
  React.useEffect(() => {
    fetchPersStatusData();
  }, [fetchPersStatusData]);
  const persStatusResult = Object.keys(persStatusData).map(
    key => persStatusData[key]
  );
  const persStatusList = persStatusResult.map(persStatus => {
    return { value: persStatus, label: persStatus };
  });

  function validateText(value) {
    let error;
    if (!value || value === " ") {
      error = "Required";
    }
    return error;
  }

  const sleep = ms => new Promise(r => setTimeout(r, ms));

  const CHARACTER_LIMIT = 2000;

  return (
    <>
      {/* <button className="pers-status-button" onClick={handleShow}>
        Change PERS Status
      </button> */}
      <Modal className="show-modal" show={show} onHide={handleClose}>
        <Formik
          initialValues={{
            persId: props.pers_id,
            projectId: props.project_id,
            persStatus: "",
            persStatusJustification: "",
            persStatusChangedBy: ""
          }}
          onSubmit={async values => {
            await sleep(500);
            await axios.post(
              global.REACT_APP_API_ENDPOINT +
                `/persLetter/updatePersStatus`,
              values
            );
            await handleRefresh();
            if (letter === true) {
              if (values.persStatus == "PERS Conditional Approval") {
                await createConditionalLetter();
              } else if (values.persStatus == "PERS Final Approval") {
                await createFinalLetter();
              } else if (values.persStatus == "PERS Suspended") {
                await createSuspendedLetter()
              } else if (values.persStatus == "PERS Ineligible") {
                await createIneligibleLetter()
              }/*  */
            }
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <Modal.Header className="pers-status-modal-header">
                <div className="pers-title-container">
                  <AccessTimeIcon className="clock" />
                  <div className="title">Change PERS Status</div>
                </div>
                <CloseIcon onClick={handleClose} />
              </Modal.Header>
              <Modal.Body className="pers-status-modal-body">
                <div>
                  You are electing to change the status of this PERS. Please
                  ensure you complete all necessary fields below.
                </div>
                <div className="title">
                  PERS Status<span style={{ color: "red" }}>*</span>
                </div>
                <div className="select">
                  <Field
                    className="select-dropdown"
                    name="persStatus"
                    as="select"
                    validate={validateText}
                  >
                    <option value=" ">Select PERS Status</option>
                    {persStatusList.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                  {errors.persStatus && touched.persStatus ? (
                    <div className="pers-error-message">
                      {errors.persStatus}
                    </div>
                  ) : null}
                </div>
                <div className="title">
                  Your Name<span style={{ color: "red" }}>*</span>
                </div>
                <Field
                  name="persStatusChangedBy"
                  as={TextField}
                  className="input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  placeholder="Enter your name here"
                  validate={validateText}
                  error={
                    touched.persStatusChangedBy && errors.persStatusChangedBy
                  }
                  helperText={
                    touched.persStatusChangedBy && errors.persStatusChangedBy
                  }
                />
                <div className="title">
                  Comments<span style={{ color: "red" }}>*</span>
                </div>
                <Field
                  name="persStatusJustification"
                  as={TextField}
                  className="input"
                  size="small"
                  fullWidth={true}
                  multiline
                  rows={4}
                  type="text"
                  inputProps={{
                    maxLength: CHARACTER_LIMIT
                  }}
                  helperText={`${values.persStatusJustification.length}/${CHARACTER_LIMIT} characters`}
                  placeholder="Add comments for change of PERS"
                  variant="outlined"
                  validate={validateText}
                  error={
                    touched.persStatusJustification &&
                    errors.persStatusJustification
                  }
                />
              </Modal.Body>
              <Modal.Footer className="pers-status-modal-footer">
                <button className="reset" type="reset" onClick={handleClose}>
                  Cancel
                </button>
                <button className="submit" type="submit">
                  Submit and Close
                </button>
                <button
                  className="submit"
                  type="submit"
                  onClick={handleLetter}
                  disabled={
                    values.persStatus != "PERS Conditional Approval" &&
                    values.persStatus != "PERS Final Approval" &&
                    values.persStatus != "PERS Suspended" &&
                    values.persStatus != "PERS Ineligible"
                  }
                >
                  Submit and Create Letter
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default PersStatus;
