import React from "react";
import "../Certification.css";
import { Formik, Form, Field } from "formik";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import FormStepper from "../stepper";
import { CancelButton } from "../CancelButton";
import {
  Button,
  Radio,
  InputAdornment,
  TextField,
  makeStyles,
  FilledInput,
} from "@material-ui/core";
import ApartmentIcon from "../../../images/apartment_black_24dp@2x.svg";
import LayersBlackIcon from "../../../images/layers_black_24dp@1x.svg";
export const Page1 = ({
  formData,
  setFormData,
  streetInfo,
  nextStep,
  step,
  status,
  cancelCertification,
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function validateRadio(value) {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  }

  function validateNumNoDec(value) {
    let error;
    if (!value && value !== 0) {
      error = "Required";
    } else if (!/^[0-9]*$/i.test(value)) {
      error = "Invalid Number";
    }
    return error;
  }

  function validateNumDec(value) {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^(\d{1,3}|\d{0,3}\.\d{1,2})$/i.test(value)) {
      error = "Invalid Percentage";
    }
    return error;
  }

  const saveAndNext = (values) => {
    let data = values;
    // reset subquestions
    if (data.geNIncIncomeAbovePmtdInd === "No") {
      data.gePerTotIncNonIncIncome = "";
      data.geIncomeFromPermittedSource = "";
    }

    if (data.geIsProjActionCeaseToExist === "No") {
      data.geProjActionToCease = "";
    }
    setFormData(data);
    nextStep();
  };

  const useStyles = makeStyles((theme) => ({
    input: {
      backgroundColor: "#FCFCFC!important",
      boxShadow:
        "0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px rgba(0,0,0,0.21)",
      borderRadius: "4px",
      height: "3px",
    },
    inputPercent: {
      backgroundColor: "#FCFCFC!important",
      boxShadow: "0 0.4px 0.6px 0 rgba(0,0,0,0.37)",
      borderRadius: "4px",
      height: "3px",
    },
  }));

  const classes = useStyles();
  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      onSubmit={(values) => {
        saveAndNext(values);
      }}
    >
      {({ values, errors, touched }) => (
        <Form>
          <div className="certification-container">
            <FormStepper step={step} type={values.phaseId} class={status} />
            <div className="main">
              <CancelButton
                status={status}
                cancelCertification={cancelCertification}
              />
              <div className="title">Certification Eligibility Questions</div>
              <div className="subtitle">
                {values.phaseId === "proj" ? (
                  <>
                    <img
                      alt="Apartment Logo"
                      style={{ paddingRight: "8px", paddingBottom: "4px" }}
                      src={ApartmentIcon}
                    />
                    {streetInfo.projectName}
                  </>
                ) : (
                  <>
                    <img
                      alt="Phase Logo"
                      style={{ paddingRight: "8px", paddingBottom: "4px" }}
                      src={LayersBlackIcon}
                    />
                    {streetInfo.phaseName}
                  </>
                )}
              </div>
              <div className="info">
                <div style={{ display: "flex" }}>
                  <div className="info-container">
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Project ID</div>
                        <div className="attributes">{values.projectId}</div>
                      </>
                    ) : (
                      <>
                        <div className="type">Phase ID</div>
                        <div className="attributes">{values.phaseId}</div>
                      </>
                    )}
                  </div>
                  <div
                    className="info-container"
                    style={{ paddingLeft: "220px" }}
                  >
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Address</div>
                        <div className="attributes">
                          {streetInfo.projectStreet}, {streetInfo.projectCity},{" "}
                          {streetInfo.projectState} {streetInfo.projectZip}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="type">Address</div>
                        <div className="attributes">
                          {streetInfo.phaseStreet}, {streetInfo.phaseCity},{" "}
                          {streetInfo.phaseState} {streetInfo.phaseZip}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div className="info-container" style={{ paddingTop: "0px" }}>
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Project Name</div>
                        <div className="attributes">
                          {streetInfo.projectName}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="type">Project/Phase Name</div>
                        <div className="attributes">
                          {streetInfo.projectName} / {streetInfo.phaseName}
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className="info-container"
                    style={{ padding: "0px 0px 0px 134px" }}
                  >
                    <>
                      <div className="type">Project Type</div>
                      <div className="attributes">
                        {status === "new" ? (
                          <>New</>
                        ) : status === "est" ? (
                          <>Established</>
                        ) : (
                          <>Incomplete</>
                        )}
                      </div>
                    </>
                  </div>
                </div>
              </div>

              <div className="form">
                <div className="form-header">
                  {values.phaseId === "proj" ? (
                    <>
                      {status === "new" ? (
                        <div className="subheader">Section 1 OF 8</div>
                      ) : (
                        <div className="subheader">Section 1 OF 7</div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="subheader">Section 1 OF 9</div>
                    </>
                  )}
                  <div className="title">Guide Eligibility</div>
                </div>
                <div className="form-body">
                  <div className="question">
                    <div className="number">1.</div>
                    <div>
                      Does the project operate as a hotel, motel, or resort as
                      defined by the{" "}
                      <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b4/2.1/03.html"
                      >
                        Selling Guide B4-2.1-03
                      </a>
                      ?
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="r-container"
                      >
                        <label className="label">
                          <Field
                            name="geHotelMotelResortInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="geHotelMotelResortInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    {errors.geHotelMotelResortInd &&
                      touched.geHotelMotelResortInd && (
                        <div className="form-error">
                          {errors.geHotelMotelResortInd}
                        </div>
                      )}
                  </div>
                  <div className="question">
                    <div className="number">2.</div>
                    <div>
                      How many timeshare or segmented units within the project?
                      (If none, enter zero)
                    </div>
                  </div>
                  <div className="answer">
                    <Field
                      name="geTimeshareCount"
                      as={TextField}
                      inputProps={{
                        className: classes.input,
                        maxLength: 9,
                      }}
                      color="#FCFCFC"
                      variant="outlined"
                      validate={validateNumNoDec}
                      error={
                        touched.geTimeshareCount && errors.geTimeshareCount
                      }
                      helperText={
                        touched.geTimeshareCount && errors.geTimeshareCount
                      }
                    />
                  </div>
                  <div className="question">
                    <div className="number">3.</div>{" "}
                    <div>Is the project a houseboat project?</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="r-container"
                      >
                        <label className="label">
                          <Field
                            name="geHouseBoatInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="geHouseBoatInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    {errors.geHouseBoatInd && touched.geHouseBoatInd && (
                      <div className="form-error">{errors.geHouseBoatInd}</div>
                    )}
                  </div>
                  <div className="question">
                    <div className="number">4.</div>{" "}
                    <div>
                      Do any single units have multiple living spaces? (Does NOT
                      pertain to legally combined units as outlined in
                      <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b4/2.1/03.html"
                      >
                        {" "}
                        B4-2.1-03
                      </a>
                      .)
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="r-container"
                      >
                        <label className="label">
                          <Field
                            name="geMulLivingSpacesInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="geMulLivingSpacesInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    {errors.geMulLivingSpacesInd &&
                      touched.geMulLivingSpacesInd && (
                        <div className="form-error">
                          {errors.geMulLivingSpacesInd}
                        </div>
                      )}
                  </div>
                  <div className="question">
                    <div className="number">5.</div>
                    <div>
                      Are units in the project subject to{" "}
                      <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b4/2.1/03.html"
                      >
                        split ownership{" "}
                      </a>
                      arrangements or other arrangements that restrict the unit
                      owner’s ability to occupy the unit such as mandatory
                      rental pooling agreements, common interest apartments or
                      community apartment projects?
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="r-container"
                      >
                        <label className="label">
                          <Field
                            name="geSplitOwnershipInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="geSplitOwnershipInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    {errors.geSplitOwnershipInd &&
                      touched.geSplitOwnershipInd && (
                        <div className="form-error">
                          {errors.geSplitOwnershipInd}
                        </div>
                      )}
                  </div>
                  <div className="question">
                    <div className="number">6.</div>{" "}
                    <div>
                      Does the project/HOA's budget reflect non-incidental
                      income from the ownership and/or operation of amenities or
                      services for use by unit owners and the general public?
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="r-container"
                      >
                        <label className="label">
                          <Field
                            name="geNIncIncomeAbovePmtdInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="geNIncIncomeAbovePmtdInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    {errors.geNIncIncomeAbovePmtdInd &&
                      touched.geNIncIncomeAbovePmtdInd && (
                        <div className="form-error">
                          {errors.geNIncIncomeAbovePmtdInd}
                        </div>
                      )}
                  </div>
                  {values.geNIncIncomeAbovePmtdInd === "Yes" ? (
                    <>
                      <div className="question">
                        <div
                          className="number"
                          style={{
                            borderLeft: "1px solid rgba(0,0,0,0.4)",
                            borderBottom: "1px solid rgba(0,0,0,0.4)",
                            marginLeft: "37px",
                            marginBottom: "10px",
                          }}
                        ></div>{" "}
                        <div style={{ paddingTop: "10px" }}>
                          What percentage (%) of the Total Income is reflected
                          as non-incidental income on the budget?
                        </div>
                      </div>
                      <div className="answer" style={{ paddingLeft: "86px" }}>
                        <Field
                          name="gePerTotIncNonIncIncome"
                          as={TextField}
                          style={{ backgroundColor: "#FCFCFC" }}
                          InputProps={{
                            classes: {
                              input: classes.inputPercent,
                            },
                            endAdornment: (
                              <InputAdornment variant="outlined" position="end">
                                %
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{
                            maxLength: 9,
                          }}
                          variant="outlined"
                          validate={validateNumDec}
                          error={
                            touched.gePerTotIncNonIncIncome &&
                            errors.gePerTotIncNonIncIncome
                          }
                          helperText={
                            touched.gePerTotIncNonIncIncome &&
                            errors.gePerTotIncNonIncIncome
                          }
                        />
                      </div>
                      <div className="question">
                        <div
                          className="number"
                          style={{
                            borderLeft: "1px solid rgba(0,0,0,0.4)",
                            borderBottom: "1px solid rgba(0,0,0,0.4)",
                            marginLeft: "37px",
                            marginBottom: "10px",
                          }}
                        ></div>{" "}
                        <div style={{ paddingTop: "10px" }}>
                          Is the income from the permitted sources as outlined
                          in{" "}
                          <a
                            target="_blank"
                            href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B4-Underwriting-Property/Chapter-B4-2-Project-Standards/#Non-Incidental.20Business.20Arrangements"
                          >
                            B4-2.1-03
                          </a>
                          ?
                        </div>
                      </div>
                      <div className="answer" style={{ paddingLeft: "86px" }}>
                        <div
                          role="group"
                          aria-labelledby="radio-group"
                          className="r-container"
                        >
                          <label className="label">
                            <Field
                              name="geIncomeFromPermittedSource"
                              type="radio"
                              variant="outlined"
                              value="Yes"
                              className="alignment"
                              validate={validateRadio}
                            />
                            Yes
                          </label>
                          <label className="label">
                            <Field
                              name="geIncomeFromPermittedSource"
                              type="radio"
                              variant="outlined"
                              value="No"
                              className="alignment"
                              validate={validateRadio}
                            />
                            No
                          </label>
                        </div>
                        {errors.geIncomeFromPermittedSource &&
                          touched.geIncomeFromPermittedSource && (
                            <div className="form-error">
                              {errors.geIncomeFromPermittedSource}
                            </div>
                          )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="question">
                    <div className="number">7.</div>{" "}
                    <div>
                      Is the project out of compliance with the{" "}
                      <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b4/2.1/03.html"
                      >
                        Selling Guide requirements
                      </a>{" "}
                      related to the lien priority of unpaid common expense
                      assessments?
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="r-container"
                      >
                        <label className="label">
                          <Field
                            name="geUnpaidCommonExpAsmtsInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="geUnpaidCommonExpAsmtsInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    {errors.geUnpaidCommonExpAsmtsInd &&
                      touched.geUnpaidCommonExpAsmtsInd && (
                        <div className="form-error">
                          {errors.geUnpaidCommonExpAsmtsInd}
                        </div>
                      )}
                  </div>
                  <div className="question">
                    <div className="number">8.</div>{" "}
                    <div>
                      Is the project, in whole or in part, operated or owned as
                      a continuing care facility which provides medical and/or
                      supportive services to unit owners? See{" "}
                      <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b4/2.1/03.html"
                      >
                        Selling Guide reference
                      </a>
                      .
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="r-container"
                      >
                        <label className="label">
                          <Field
                            name="geCareFacilityInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="geCareFacilityInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    {errors.geCareFacilityInd && touched.geCareFacilityInd && (
                      <div className="form-error">
                        {errors.geCareFacilityInd}
                      </div>
                    )}
                  </div>
                  <div className="question">
                    <div className="number">9.</div>{" "}
                    <div>
                      Are residential unit owners required to pay mandatory
                      upfront and/or periodic membership, or recreational lease
                      for the use of amenities (such as country club facilities,
                      golf courses, etc.), that are NOT owned by the HOA or
                      master association and instead are owned by an outside
                      party (including the developer or builder)?
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="r-container"
                      >
                        <label className="label">
                          <Field
                            name="gePayMemshpOrLeaseInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="gePayMemshpOrLeaseInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    {errors.gePayMemshpOrLeaseInd &&
                      touched.gePayMemshpOrLeaseInd && (
                        <div className="form-error">
                          {errors.gePayMemshpOrLeaseInd}
                        </div>
                      )}
                  </div>
                  <div className="question">
                    <div className="number">10.</div>{" "}
                    <div>
                      Is the project out of compliance with the{" "}
                      <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b4/2.2/02.html"
                      >
                        Selling Guide requirements
                      </a>{" "}
                      related to the HOA's ownership of the project's
                      facilities, amenities, common elements, or limited common
                      elements (i.e., are any of these elements owned by an
                      entity other than the HOA)?
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="r-container"
                      >
                        <label className="label">
                          <Field
                            name="getCmplnHOAOwnshpInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="getCmplnHOAOwnshpInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    {errors.getCmplnHOAOwnshpInd &&
                      touched.getCmplnHOAOwnshpInd && (
                        <div className="form-error">
                          {errors.getCmplnHOAOwnshpInd}
                        </div>
                      )}
                  </div>
                  <div className="question">
                    <div className="number">11.</div>{" "}
                    <div>
                      Is the lender aware of any unacceptable
                      environmental hazard that has not been appropriately
                      assessed and remediated per sections
                      <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b4/2.1/04.html"
                      >
                        {" "}
                        B4-2.1-04
                      </a>
                      ,
                      <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b4/2.1/05.html"
                      >
                        {" "}
                        B4-2.1-05
                      </a>
                      , and{" "}
                      <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b4/2.1/06.html"
                      >
                        B4-2.1-06
                      </a>{" "}
                      of the Selling Guide requirements?
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="r-container"
                      >
                        <label className="label">
                          <Field
                            name="geEnvHazardInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="geEnvHazardInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    {errors.geEnvHazardInd && touched.geEnvHazardInd && (
                      <div className="form-error">{errors.geEnvHazardInd}</div>
                    )}
                  </div>
                  <div className="question">
                    <div className="number">12.</div>{" "}
                    <div>
                      Are units in the project subject to Right of First Refusal
                      or resale restrictions that do not meet the applicable
                      requirements in section{" "}
                      <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b4/2.1/01.html"
                      >
                        B4-2.1-01
                      </a>
                      ,
                      <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b4/2.2/03.html"
                      >
                        {" "}
                        B4-2.2-03
                      </a>
                      , and{" "}
                      <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b5/5.2/02.html"
                      >
                        B5-5.2-02
                      </a>{" "}
                      of the Selling Guide related to loans with resale
                      restrictions?
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="r-container"
                      >
                        <label className="label">
                          <Field
                            name="geRtFtRefslOrReSlRstnsInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="geRtFtRefslOrReSlRstnsInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    {errors.geRtFtRefslOrReSlRstnsInd &&
                      touched.geRtFtRefslOrReSlRstnsInd && (
                        <div className="form-error">
                          {errors.geRtFtRefslOrReSlRstnsInd}
                        </div>
                      )}
                  </div>
                  <div className="question">
                    <div className="number">13.</div>{" "}
                    <div>
                      Are there any sale/financing concessions in excess of
                      Fannie Mae’s eligibility policies for mortgage loans even
                      if these concessions are not available to the subject
                      unit, as described in{" "}
                      <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b4/2.1/03.html"
                      >
                        {" "}
                        B4-2.1-03
                      </a>
                      ?
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="r-container"
                      >
                        <label className="label">
                          <Field
                            name="npeFinConExcessInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="npeFinConExcessInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    {errors.npeFinConExcessInd &&
                      touched.npeFinConExcessInd && (
                        <div className="form-error">
                          {errors.npeFinConExcessInd}
                        </div>
                      )}
                  </div>
                  {/* <div className="question">
                    <div className="number">14.</div>{" "}
                    <div>
                      Is the project subject to any action that would cause the project to cease to exist, including termination, deconversion or dissolution of their legal structure or subject to voluntary or involuntary bankruptcy, liquidation or receivership proceedings, or any substantially similar action. This includes projects that have voted or are in the process of voting on any of the actions described above ?
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="r-container"
                      >
                        <label className="label">
                          <Field
                            name="geIsProjActionCeaseToExist"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="geIsProjActionCeaseToExist"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    {errors.geIsProjActionCeaseToExist &&
                      touched.geIsProjActionCeaseToExist && (
                        <div className="form-error">
                          {errors.geIsProjActionCeaseToExist}
                        </div>
                      )}
                  </div>
                  {values.geIsProjActionCeaseToExist === "Yes" ? (
                    <>
                      <div className="question">
                        <div
                          className="number"
                          style={{
                            borderLeft: "1px solid rgba(0,0,0,0.4)",
                            borderBottom: "1px solid rgba(0,0,0,0.4)",
                            marginLeft: "37px",
                            marginBottom: "10px",
                          }}
                        ></div>{" "}
                        <div style={{ paddingTop: "10px" }}>
                          Choose one of the following 3 options that most closely describes the project?
                        </div>
                      </div>
                      <div className="answer" style={{ paddingLeft: "86px" }}>
                        <div
                          role="group"
                          aria-labelledby="radio-group"
                          className="c-container"
                        >
                          <label className="label">
                            <Field
                              name="geProjActionToCease"
                              type="radio"
                              variant="outlined"
                              value="terminating"
                              className="alignment"
                              validate={validateRadio}
                            />
                            Terminating, deconverting or dissolving or any substantially similar action
                          </label>
                          <label className="label">
                            <Field
                              name="geProjActionToCease"
                              type="radio"
                              variant="outlined"
                              value="voluntary"
                              className="alignment"
                              validate={validateRadio}
                            />
                            Voluntary or involuntary bankruptcy, liquidation or receivership proceedings, or any substantially similar action
                          </label>
                          <label className="label">
                            <Field
                              name="geProjActionToCease"
                              type="radio"
                              variant="outlined"
                              value="NA"
                              className="alignment"
                              validate={validateRadio}
                            />
                            Unable to determine
                          </label>
                        </div>
                        {errors.geProjActionToCease &&
                          touched.geProjActionToCease && (
                            <div className="form-error">
                              {errors.geProjActionToCease}
                            </div>
                          )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )} */}
                </div>
                <div className="form-footer">
                  <div></div>
                  <button className="next-button" type="submit">
                    Next Section <ArrowForwardIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};