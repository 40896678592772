import React from 'react';
import IndividualComment from './IndividualComment';
import './Comments.css';


const Comments = ({ list, projectId, showExternalToggle }) => {
  let comments = <h3>Loading...</h3>;
  // console.log('input from parent:', list);

  if (list) {
    comments = list.map((m, i) => <IndividualComment key={i} item={m} projectId={projectId} showExternalToggle={showExternalToggle} />);
  }
  
  return (
    <div className="scrolling-test">
      {comments}
    </div>
  )
}
export default Comments;