import { Button } from "@material-ui/core";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { isProjectReadOnlyRole } from "../../redux/actions/userAction";
import "./ToggleExternalModal.css";

const ToggleExternalModal = ({item, projectId}) => {
  const [show, setShow] = useState(false);
  const [showExternal, setShowExternal] = useState(!!item.showExternal);
  const passData = JSON.parse(sessionStorage.getItem("passData"));

  const handleToggle = (e) => {
    const value = e.target.value === "true";

    if (showExternal !== value) {
      setShow(true);
    }
  };

  const handleConfirm = () => {
    const userName = passData.user;

    const request = {
      userName,
      projectId,
      commentId: item.commentId,
      showExternal: !showExternal,
    };

    axios
      .post(
        global.REACT_APP_API_ENDPOINT + `/condoMgr/updateComment/`,
        request
      )
      .then(() => {
        setShowExternal(!showExternal);
        item.showExternal = showExternal;
      })
      .catch(error => {
        console.error(error);
      });

    setShow(false);
  };

  const handleCancel = () => {
    setShow(false);
  }

  return (
    <>
      {isProjectReadOnlyRole() ? (
        <label className="read-only-permissions-label">
          {showExternal ? "Internal & External" : "Internal"}
        </label>
      ) : (
        <form className="toggle-form pl-3 ">
          <div className="form-group">
            <ToggleButtonGroup
              color="primary"
              value={showExternal}
              exclusive
              onChange={handleToggle}
              aria-label="text alignment"
            >
              <ToggleButton value={false} aria-label="left aligned">
                Internal
              </ToggleButton>
              <ToggleButton value={true} aria-label="centered">
                Internal & External
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </form>
      )}

      <Modal aria-label="toggle comment permissions modal" show={show} onHide={handleCancel} style={{paddingTop: "7.5%"}}  backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Confirm Permissions Change</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <form className="pl-3 ">
            <div className="form-group">
              <label className="comment-confirm">
                {showExternal
                  ? "Do you want to make this comment visible to Internal users only?"
                  : "Do you want to make this comment visible to both Internal & External users?"
                }
              </label>
            </div>
            {showExternal ? (<></>) : (
              <div className="comment-note">
                Note: This will allow lenders to view this comment; use standardized language when possible.
              </div>
            )}
        </form>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#f7f7f7" }}>
        <Button className="modal-button confirm-button" onClick={handleConfirm}>
          Confirm Change
        </Button>
        <Button className="modal-button cancel-button" onClick={handleCancel}>
          Cancel
        </Button>
      </Modal.Footer>
      </Modal>
    </>
  );
};

export default ToggleExternalModal;
