import React from "react";
import "./ChildPhaseRestrictions.css";
import ConstructionBlackIcon from "../../../images/construction_black_24dp@1x.svg";
import CampaignBlackIcon from "../../../images/campaign_black_24dp@1x.svg";

const IndividualChildPhaseRestriction = ({ item, type }) => {
  return (
    <div>
      {typeof item !== "undefined" ? (
        <div className="phase-restrictions-text">
          <div className="phase-restrictions-image">
            {type === "Construction" ? (
              <>
                {" "}
                <img
                  alt="Hammer and Wrench Icon"
                  className="restrictions-image"
                  src={ConstructionBlackIcon}
                />
              </>
            ) : (
              <>
                {" "}
                <img
                  alt="Blow Horn Icon"
                  className="restrictions-image"
                  src={CampaignBlackIcon}
                />
              </>
            )}
          </div>
          {item.isAddedRestrictions === false ? (
            <div className="text">{item.decisionDesc}</div>
          ) : (
            <div className="text">
              {item.decisionName}
              :&nbsp;
              {item.decisionDesc}
            </div>
          )}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default IndividualChildPhaseRestriction;
