import React, { useState } from "react";
import "../ViewCertification.css";
import moment from "moment";
import { Formik, Form, Field } from "formik";
import PrintIcon from "@material-ui/icons/Print";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { Accordion, Card } from "react-bootstrap";
import ContextAwareToggle from "../../Profile/ContextAwareToggle";
import { makeStyles } from "@material-ui/core";
import { isReadonlyRole, isTPORole } from "../../../redux/actions/userAction";
import ApartmentIcon from "../../../images/apartment_black_24dp@2x.svg";
import LayersIcon from "../../../images/layers_black_24dp@1x.svg";
import CriticalIcon from "../../../images/Critical@2x.svg";
import { FinancialsSpecialAssessmentsCriticalRepairs } from "../../shared/components/PrintCertificationForms/FinancialsSpecialAssessmentsCriticalRepairs";
import { GuideEligibility } from "../../shared/components/PrintCertificationForms/GuideEligibility";
import { LitigationUsageAndInsurance } from "../../shared/components/PrintCertificationForms/LitigationUsageAndInsurance";
import { NewProjectEligibility } from "../../shared/components/PrintCertificationForms/NewProjectEligibility";
import { PhasePresaleAndInvestorConcentration } from "../../shared//components/PrintCertificationForms/PhasePresaleAndInvestorConcentration";
import { ProjectInformation } from "../../shared/components/PrintCertificationForms/ProjectInformation";
import { ProjectOwnership } from "../../shared/components/PrintCertificationForms/ProjectOwnership";
import { ProjectPresaleAndInvestorConcentration } from "../../shared/components/PrintCertificationForms/ProjectPresaleAndInvestorConcentration";
import { fspAssmtsRuleCheck } from "../../shared/services/SharedService"
export const ViewCertificationDetails = ({
  streetInfo,
  docData,
  convResult,
  status,
  projectId,
  phaseId
}) => {

  React.useEffect(() => {
    console.log(docData);
    window.scrollTo(0, 0);
  }, []);
  function getCertDate() {
    const certDate = moment(docData.certfcnDate)
      .format("MM/DD/YY");
    return certDate;
  }
  function goToProject() {
    window.location.href = `/project/${projectId}`;
  }

  function goToPhase() {
    window.location.href = `/project/phase/${phaseId}`;
  }

  function goToCertifyProject() {
    window.location.href = `/certification/${projectId}/proj/${status}`;
  }

  function goToCertifyPhase() {
    window.location.href = `/certification/${projectId}/${phaseId}/${status}`;
  }

  function gotToPrintCertification() {
    window.location.href = `/print-certification/${docData.certificationId}/${projectId}/${phaseId}`;
  }

  const useStyles = makeStyles((theme) => ({
    input: {
      backgroundColor: "#FCFCFC!important",
      boxShadow:
        "0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px rgba(0,0,0,0.21)",
      borderRadius: "4px",
      height: "3px",
    },
    inputPercent: {
      backgroundColor: "#FCFCFC!important",
      boxShadow: "0 0.4px 0.6px 0 rgba(0,0,0,0.37)",
      borderRadius: "4px",
      height: "3px",
    },
  }));

  const classes = useStyles();
  return (
    <div className="certification-container">
      <div className="main">
        <div className="title">Certification Eligibility Questions</div>
        <div className="subtitle">
          {phaseId === 'proj' ? (
            <>
              <img
                alt="Apartment Icon"
                style={{ paddingRight: "8px", paddingBottom: "4px" }}
                src={ApartmentIcon}
              />
              {streetInfo.projectName}
              {docData.showRecertifyButton === true &&
                !isReadonlyRole() ? (
                <button
                  className="recertify-button"
                  style={{
                    float: "right",
                    border: 0,
                  }}
                  onClick={goToCertifyProject}
                >
                  {" "}
                  Re-Certify Project{" "}
                </button>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              <img
                alt="Phase Icon"
                style={{ paddingRight: "8px", paddingBottom: "4px" }}
                src={LayersIcon}
              />
              {streetInfo.phaseName}
              {docData.showRecertifyButton === true ? (
                <button
                  className="recertify-button"
                  style={{
                    float: "right",
                    border: 0,
                  }}
                  onClick={goToCertifyPhase}
                >
                  {" "}
                  Re-Certify Phase{" "}
                </button>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
        {typeof docData.guideEligibilityAndProjectInfoSt !==
          "undefined" ? (
          <>
            {docData.certificationStatus === "Certified by Lender" ? (
              <div
                className="decline-info"
                style={{ border: "1px solid #098620" }}
              >
                <div
                  className="decline-info-symbol"
                  style={{ backgroundColor: "#098620" }}
                >
                  <CheckIcon
                    style={{
                      color: "#FFFFFF",
                      width: "30px",
                      height: "30px",
                      margin: "5px 0px",
                    }}
                  />
                </div>
                <div className="decline-info-text">
                  {docData.certificationStatus}
                </div>
              </div>
            ) : (
              <div className="decline-info">
                <div className="decline-info-symbol">
                  <CloseIcon
                    style={{
                      color: "#FFFFFF",
                      width: "30px",
                      height: "30px",
                      margin: "5px 0px",
                    }}
                  />
                </div>
                <div className="decline-info-text">
                  {docData.certificationStatus}
                </div>
              </div>
            )}
          </>
        ) : (
          <></>
        )}
        <div className="info" style={{ margin: "0px" }}>
          <div style={{ display: "flex" }}>
            <div className="info-container">
              {phaseId === 'proj' ? (
                <>
                  <div className="type">Project ID</div>
                  <div className="attributes">{projectId}</div>
                </>
              ) : (
                <>
                  <div className="type">Phase ID</div>
                  <div className="attributes">{phaseId}</div>
                </>
              )}
            </div>
            <div
              className="info-container"
              style={{ paddingLeft: "220px" }}
            >
              {phaseId === 'proj' ? (
                <>
                  <div className="type">Address</div>
                  <div className="attributes">
                    {streetInfo.projectStreet}, {streetInfo.projectCity},{" "}
                    {streetInfo.projectState} {streetInfo.projectZip}
                  </div>
                </>
              ) : (
                <>
                  <div className="type">Address</div>
                  <div className="attributes">
                    {streetInfo.phaseStreet}, {streetInfo.phaseCity},{" "}
                    {streetInfo.phaseState} {streetInfo.phaseZip}
                  </div>
                </>
              )}
            </div>
            <div
              className="info-container"
              style={{ paddingLeft: "220px" }}
            >
              <div className="type">Certification ID</div>
              <div className="attributes">
                {docData.certificationId}
              </div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div className="info-container" style={{ paddingTop: "0px" }}>
              {phaseId === 'proj' ? (
                <>
                  <div className="type">Project Name</div>
                  <div className="attributes">
                    {streetInfo.projectName}
                  </div>
                </>
              ) : (
                <>
                  <div className="type">Project/Phase Name</div>
                  <div className="attributes">
                    {streetInfo.projectName} / {streetInfo.phaseName}
                  </div>
                </>
              )}
            </div>
            <div
              className="info-container"
              style={{ paddingLeft: "63px", paddingTop: "0px" }}
            >
              <div className="type">Status</div>
              {typeof docData.guideEligibilityAndProjectInfoSt !==
                "undefined" ? (
                <>
                  {docData.certificationStatus ===
                    "Certified by Lender" ? (
                    <>
                      <div
                        className="attributes"
                        style={{ color: "#098620", fontWeight: "bold" }}
                      >
                        {docData.certificationStatus}
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="attributes"
                        style={{ color: "#D0021B", fontWeight: "bold" }}
                      >
                        {docData.certificationStatus}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="info-container" style={{ paddingTop: "0px" }}>
              <div className="type">Certification Date</div>
              <div className="attributes">
                {docData.certfcnDate ? getCertDate() : "NA"}
              </div>
            </div>
            <div className="info-container" style={{ paddingTop: "0px" }}>
              <div className="type">Expires</div>
              <div className="attributes">
                {docData.dateExpiry ? docData.dateExpiry : "NA"}
              </div>{" "}
            </div>
            <div className="info-container" style={{ paddingTop: "0px" }}>
              <div className="type">Project Type</div>
              <div className="attributes">
                {docData.certProjectType === "projectEst"
                  ? "Established"
                  : docData.certProjectType === "projectNew"
                    ? "New"
                    : ""}
              </div>{" "}
            </div>
            <div className="info-container" style={{ paddingTop: "0px" }}>
              <div className="type">
                Project Type Code to be used at delivery
              </div>
              <div className="attributes">
                {docData.certProjectType === "projectNew"
                  ? "R-Full Review (with or without CPM)—New condo project"
                  : docData.certProjectType === "projectEst"
                    ? "S-Full Review (with or without CPM)—Established condo project"
                    : ""}
              </div>{" "}
            </div>
          </div>
        </div>

        <div className="form">
          <div className="form-header">
            <div className="title">Results</div>
          </div>
          <div className="form-body">
            {docData.delvyRestrictions !== null ? (
              <div className="certification-result-restriction-container">
                <div className="restriction-title">
                  <img
                    alt="Warning Critical Icon"
                    style={{ paddingRight: "8px", paddingBottom: "3px" }}
                    src={CriticalIcon}
                  />
                  Delivery Restrictions
                </div>
                <div className="restriction-attribute">
                  <img
                    alt="Apartment Icon"
                    style={{
                      marginRight: "8px",
                      marginTop: "3px",
                      width: "17px",
                      height: "17px",
                    }}
                    src={ApartmentIcon}
                  />
                  {docData.delvyRestrictions}
                </div>
              </div>
            ) : (
              <></>
            )}
            <Accordion defaultActiveKey="1" style={{ marginTop: "16px" }}>
              <Card
                className="accordion-header-card"
                style={{ margin: "0px" }}
              >
                <Card.Header className="accordion-header-space">
                  <ContextAwareToggle eventKey="1">
                    {typeof docData.guideEligibilityAndProjectInfoSt !==
                      "undefined" ? (
                      <>
                        {(docData.guideEligibilityAndProjectInfoSt
                          .geHotelMotelResortIndSt === "Fail" ||
                          docData.guideEligibilityAndProjectInfoSt
                            .geTimeshareCountSt === "Fail" ||
                          docData.guideEligibilityAndProjectInfoSt
                            .geHouseBoatIndSt === "Fail" ||
                          docData.guideEligibilityAndProjectInfoSt
                            .geMulLivingSpacesIndSt === "Fail" ||
                          docData.guideEligibilityAndProjectInfoSt
                            .geSplitOwnershipIndSt === "Fail" ||
                          docData.guideEligibilityAndProjectInfoSt
                            .geNIncIncomeAbovePmtdIndSt === "Fail" ||
                          docData.guideEligibilityAndProjectInfoSt
                            .gePerTotIncNonIncIncomeSt === "Fail" ||
                          docData.guideEligibilityAndProjectInfoSt
                            .geUnpaidCommonExpAsmtsIndSt === "Fail" ||
                          docData.guideEligibilityAndProjectInfoSt
                            .geCareFacilityIndSt === "Fail" ||
                          docData.guideEligibilityAndProjectInfoSt
                            .gePayMemshpOrLeaseIndSt === "Fail" ||
                          docData.guideEligibilityAndProjectInfoSt
                            .getCmplnHOAOwnshpIndSt === "Fail" ||
                          docData.guideEligibilityAndProjectInfoSt
                            .geEnvHazardIndSt === "Fail" ||
                          docData.guideEligibilityAndProjectInfoSt
                            .geRtFtRefslOrReSlRstnsIndSt === "Fail" ||
                          docData.newProjectEligibilitySt
                            .npeFinConExcessIndSt === "Fail" ||
                          docData.guideEligibilityAndProjectInfoSt
                            .geIsProjActionCeaseToExistSt === "Fail") &&
                          docData.certificationStatus ===
                          "Guide Ineligible" ? (
                          <>
                            <p
                              className="accordion-header-title"
                              style={{ color: "#D0021B" }}
                            >
                              1. Guide Eligibility
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="accordion-header-title">
                              1. Guide Eligibility
                            </p>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <p className="accordion-header-title">
                          1. Guide Eligibility
                        </p>
                      </>
                    )}
                  </ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className="accordion-body-card">
                    <GuideEligibility data={docData} />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <Accordion defaultActiveKey="0" style={{ marginTop: "16px" }}>
              <Card
                className="accordion-header-card"
                style={{ margin: "0px" }}
              >
                <Card.Header className="accordion-header-space">
                  <ContextAwareToggle eventKey="1">
                    {typeof docData.guideEligibilityAndProjectInfoSt !==
                      "undefined" ? (
                      <>
                        {(docData.guideEligibilityAndProjectInfoSt
                          .piYearBuiltSt === "Fail" ||
                          docData.guideEligibilityAndProjectInfoSt
                            .piConvtdProjIndSt === "Fail" ||
                          docData.guideEligibilityAndProjectInfoSt
                            .piGutRehabIndSt === "Fail" ||
                          docData.guideEligibilityAndProjectInfoSt
                            .piYearConvtdSt === "Fail" ||
                          docData.guideEligibilityAndProjectInfoSt
                            .piAddnPhsOrAnnxIndSt === "Fail" ||
                          docData.guideEligibilityAndProjectInfoSt
                            .piCntlHOATurnOverIndSt === "Fail" ||
                          docData.guideEligibilityAndProjectInfoSt
                            .piDtTurndOverSt === "Fail" ||
                          docData.guideEligibilityAndProjectInfoSt
                            .piCommnAreasCompltIndSt === "Fail" ||
                          docData.newProjectEligibilitySt
                            .npeAssrArrgReviewedIndSt === "Fail") &&
                          docData.certificationStatus ===
                          "Guide Ineligible" ? (
                          <>
                            <p
                              className="accordion-header-title"
                              style={{ color: "#D0021B" }}
                            >
                              2. Project Information
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="accordion-header-title">
                              2. Project Information
                            </p>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <p className="accordion-header-title">
                          2. Project Information
                        </p>
                      </>
                    )}
                  </ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className="accordion-body-card">
                    <ProjectInformation data={docData} />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>

            <Accordion defaultActiveKey="0" style={{ marginTop: "16px" }}>
              <Card
                className="accordion-header-card"
                style={{ margin: "0px" }}
              >
                <Card.Header className="accordion-header-space">
                  <ContextAwareToggle eventKey="1">
                    {typeof docData.projectPresaleSt !== "undefined" ? (
                      <>
                        {(docData.projectPresaleSt.ppTotalUnitsSt ===
                          "Fail" ||
                          docData.projectPresaleSt
                            .ppOwnerOccupiedUnitsSt === "Fail" ||
                          docData.projectPresaleSt.ppSecondHomesSt ===
                          "Fail" ||
                          docData.projectPresaleSt.ppUnitsRentedSt ===
                          "Fail" ||
                          docData.projectPresaleSt.ppUnitsCompleteSt ===
                          "Fail" ||
                          docData.projectPresaleSt.ppUnitsConveyedSt ===
                          "Fail" ||
                          docData.projectPresaleSt
                            .ppPreSaleConveyPercntgeSt === "Fail" ||
                          docData.projectPresaleSt
                            .ppDevOwndUntsRentalHoldBackIndSt ===
                          "Fail" ||
                          docData.projectPresaleSt.ppDevOwnedUnitsSt ===
                          "Fail" ||
                          docData.projectPresaleSt
                            .ppMaintFeesDevOwndCurrentIndSt === "Fail" ||
                          docData.projectPresaleSt
                            .ppActivePendingSpAssmntsIndSt === "Fail") &&
                          docData.certificationStatus ===
                          "Guide Ineligible" ? (
                          <>
                            <p
                              className="accordion-header-title"
                              style={{ color: "#D0021B" }}
                            >
                              3. Project: Presale and Investor
                              Concentration
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="accordion-header-title">
                              3. Project: Presale and Investor
                              Concentration
                            </p>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <p className="accordion-header-title">
                          3. Project: Presale and Investor Concentration
                        </p>
                      </>
                    )}
                  </ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className="accordion-body-card">
                    <ProjectPresaleAndInvestorConcentration data={docData} convResult={convResult} />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>

            {phaseId === 'proj' ? (
              <></>
            ) : (
              <>
                <Accordion
                  defaultActiveKey="0"
                  style={{ marginTop: "16px" }}
                >
                  <Card
                    className="accordion-header-card"
                    style={{ margin: "0px" }}
                  >
                    <Card.Header className="accordion-header-space">
                      <ContextAwareToggle eventKey="1">
                        {typeof docData.phasePresaleSt !== "undefined" ? (
                          <>
                            {(docData.phasePresaleSt
                              .phpUnitsCompleteSt === "Fail" ||
                              docData.phasePresaleSt
                                .phpUnitsSoldOwnerOccpSecondHomeRsdnSt ===
                              "Fail" ||
                              docData.phasePresaleSt
                                .phpOwnerOccupiedUnitsSt === "Fail" ||
                              docData.phasePresaleSt.phpSecondHomesSt ===
                              "Fail") &&
                              docData.certificationStatus ===
                              "Guide Ineligible" ? (
                              <>
                                <p
                                  className="accordion-header-title"
                                  style={{ color: "#D0021B" }}
                                >
                                  4. Phase: Presale and Investor
                                  Concentration
                                </p>
                              </>
                            ) : (
                              <>
                                <p className="accordion-header-title">
                                  4. Phase: Presale and Investor
                                  Concentration
                                </p>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <p className="accordion-header-title">
                              4. Phase: Presale and Investor Concentration
                            </p>
                          </>
                        )}
                      </ContextAwareToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body className="accordion-body-card">
                        <PhasePresaleAndInvestorConcentration data={docData} />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </>
            )}

            {(convResult < 90 &&
              phaseId === 'proj' &&
              docData.guideEligibilityAndProjectInfoSt.piAddnPhsOrAnnxInd === "No" &&
              docData.guideEligibilityAndProjectInfoSt.piCntlHOATurnOverInd === "Yes" &&
              docData.guideEligibilityAndProjectInfoSt.piCommnAreasCompltInd === "Yes" &&
              docData.projectPresaleSt.ppUnitsComplete === docData.projectPresaleSt.ppTotalUnits &&
              docData.projectPresaleSt.ppDevOwndUntsRentalHoldBackInd === "Yes" &&
              docData.projectPresaleSt.ppDevOwnedUnits / docData.projectPresaleSt.ppTotalUnits <= 0.2) ||
              (convResult >= 90 &&
                phaseId === 'proj' &&
                docData.guideEligibilityAndProjectInfoSt.piAddnPhsOrAnnxInd === "No" &&
                docData.guideEligibilityAndProjectInfoSt.piCntlHOATurnOverInd === "Yes" &&
                docData.guideEligibilityAndProjectInfoSt.piCommnAreasCompltInd === "Yes" &&
                docData.projectPresaleSt.ppUnitsComplete === docData.projectPresaleSt.ppTotalUnits) ? (
              <></>
            ) : (
              <Accordion
                defaultActiveKey="0"
                style={{ marginTop: "16px" }}
              >
                <Card
                  className="accordion-header-card"
                  style={{ margin: "0px" }}
                >
                  <Card.Header className="accordion-header-space">
                    <ContextAwareToggle eventKey="1">
                      {typeof docData.newProjectEligibilitySt !==
                        "undefined" ? (
                        <>
                          {(docData.newProjectEligibilitySt
                            .npeMasterAsscIndSt === "Fail" ||
                            docData.newProjectEligibilitySt
                              .npePayMnthyAssesLndrRevwPlanIndSt ===
                            "Fail" ||
                            docData.newProjectEligibilitySt
                              .npeLglDocsComplyIndSt === "Fail") &&
                            docData.certificationStatus ===
                            "Guide Ineligible" ? (
                            <>
                              {phaseId === 'proj' ? (
                                <p
                                  className="accordion-header-title"
                                  style={{ color: "#D0021B" }}
                                >
                                  4. New Project Eligibility
                                </p>
                              ) : (
                                <p
                                  className="accordion-header-title"
                                  style={{ color: "#D0021B" }}
                                >
                                  5. New Project Eligibility
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              {phaseId === 'proj' ? (
                                <p className="accordion-header-title">
                                  4. New Project Eligibility
                                </p>
                              ) : (
                                <p className="accordion-header-title">
                                  5. New Project Eligibility
                                </p>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {phaseId === 'proj' ? (
                            <p className="accordion-header-title">
                              4. New Project Eligibility
                            </p>
                          ) : (
                            <p className="accordion-header-title">
                              5. New Project Eligibility
                            </p>
                          )}
                        </>
                      )}
                    </ContextAwareToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body className="accordion-body-card">
                      <NewProjectEligibility data={docData} />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            )}

            <Accordion defaultActiveKey="0" style={{ marginTop: "16px" }}>
              <Card
                className="accordion-header-card"
                style={{ margin: "0px" }}
              >
                <Card.Header className="accordion-header-space">
                  <ContextAwareToggle eventKey="1">
                    {typeof docData.projectOwnershipSt !== "undefined" ? (
                      <>
                        {(docData.projectOwnershipSt
                          .poOwnersHaveSoleOwnshpIndSt === "Fail" ||
                          docData.projectOwnershipSt
                            .poLeaseComplyIndSt === "Fail" ||
                          docData.projectOwnershipSt.poUnitsSnglOwnSt ===
                          "Fail" ||
                          docData.projectOwnershipSt.poLeaseholdExpDateSt === "Fail" ||
                          docData.projectOwnershipSt
                            .poSnglOwnMoreThanOneIndSt === "Fail") &&
                          docData.certificationStatus ===
                          "Guide Ineligible" ? (
                          <>
                            {(convResult < 90 &&
                              phaseId === 'proj' &&
                              docData.guideEligibilityAndProjectInfoSt.piAddnPhsOrAnnxInd === "No" &&
                              docData.guideEligibilityAndProjectInfoSt.piCntlHOATurnOverInd === "Yes" &&
                              docData.guideEligibilityAndProjectInfoSt.piCommnAreasCompltInd === "Yes" &&
                              docData.projectPresaleSt.ppUnitsComplete ===
                              docData.projectPresaleSt.ppTotalUnits &&
                              docData.projectPresaleSt.ppDevOwndUntsRentalHoldBackInd ===
                              "Yes" &&
                              docData.projectPresaleSt.ppDevOwnedUnits /
                              docData.projectPresaleSt.ppTotalUnits <=
                              0.2) ||
                              (convResult >= 90 &&
                                phaseId === 'proj' &&
                                docData.guideEligibilityAndProjectInfoSt.piAddnPhsOrAnnxInd === "No" &&
                                docData.guideEligibilityAndProjectInfoSt.piCntlHOATurnOverInd === "Yes" &&
                                docData.guideEligibilityAndProjectInfoSt.piCommnAreasCompltInd === "Yes" &&
                                docData.projectPresaleSt.ppUnitsComplete ===
                                docData.projectPresaleSt.ppTotalUnits) ? (
                              <p
                                className="accordion-header-title"
                                style={{ color: "#D0021B" }}
                              >
                                4. Project Ownership
                              </p>
                            ) : phaseId === 'proj' ? (
                              <p
                                className="accordion-header-title"
                                style={{ color: "#D0021B" }}
                              >
                                5. Project Ownership
                              </p>
                            ) : (
                              <p
                                className="accordion-header-title"
                                style={{ color: "#D0021B" }}
                              >
                                6. Project Ownership
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            {(convResult < 90 &&
                              phaseId === 'proj' &&
                              docData.guideEligibilityAndProjectInfoSt.piAddnPhsOrAnnxInd === "No" &&
                              docData.guideEligibilityAndProjectInfoSt.piCntlHOATurnOverInd === "Yes" &&
                              docData.guideEligibilityAndProjectInfoSt.piCommnAreasCompltInd === "Yes" &&
                              docData.projectPresaleSt.ppUnitsComplete ===
                              docData.projectPresaleSt.ppTotalUnits &&
                              docData.projectPresaleSt.ppDevOwndUntsRentalHoldBackInd ===
                              "Yes" &&
                              docData.projectPresaleSt.ppDevOwnedUnits /
                              docData.projectPresaleSt.ppTotalUnits <=
                              0.2) ||
                              (convResult >= 90 &&
                                phaseId === 'proj' &&
                                docData.guideEligibilityAndProjectInfoSt.piAddnPhsOrAnnxInd === "No" &&
                                docData.guideEligibilityAndProjectInfoSt.piCntlHOATurnOverInd === "Yes" &&
                                docData.guideEligibilityAndProjectInfoSt.piCommnAreasCompltInd === "Yes" &&
                                docData.projectPresaleSt.ppUnitsComplete ===
                                docData.projectPresaleSt.ppTotalUnits) ? (
                              <p className="accordion-header-title">
                                4. Project Ownership
                              </p>
                            ) : phaseId === 'proj' ? (
                              <p className="accordion-header-title">
                                5. Project Ownership
                              </p>
                            ) : (
                              <p className="accordion-header-title">
                                6. Project Ownership
                              </p>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {(convResult < 90 &&
                          phaseId === 'proj' &&
                          docData.guideEligibilityAndProjectInfoSt.piAddnPhsOrAnnxInd === "No" &&
                          docData.guideEligibilityAndProjectInfoSt.piCntlHOATurnOverInd === "Yes" &&
                          docData.guideEligibilityAndProjectInfoSt.piCommnAreasCompltInd === "Yes" &&
                          docData.projectPresaleSt.ppUnitsComplete ===
                          docData.projectPresaleSt.ppTotalUnits &&
                          docData.projectPresaleSt.ppDevOwndUntsRentalHoldBackInd ===
                          "Yes" &&
                          docData.projectPresaleSt.ppDevOwnedUnits /
                          docData.projectPresaleSt.ppTotalUnits <=
                          0.2) ||
                          (convResult >= 90 &&
                            phaseId === 'proj' &&
                            docData.guideEligibilityAndProjectInfoSt.piAddnPhsOrAnnxInd === "No" &&
                            docData.guideEligibilityAndProjectInfoSt.piCntlHOATurnOverInd === "Yes" &&
                            docData.guideEligibilityAndProjectInfoSt.piCommnAreasCompltInd === "Yes" &&
                            docData.projectPresaleSt.ppUnitsComplete ===
                            docData.projectPresaleSt.ppTotalUnits) ? (
                          <p className="accordion-header-title">
                            4. Project Ownership
                          </p>
                        ) : phaseId === 'proj' ? (
                          <p className="accordion-header-title">
                            5. Project Ownership
                          </p>
                        ) : (
                          <p className="accordion-header-title">
                            6. Project Ownership
                          </p>
                        )}
                      </>
                    )}
                  </ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className="accordion-body-card">
                    <ProjectOwnership data={docData} />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>

            <Accordion defaultActiveKey="0" style={{ marginTop: "16px" }}>
              <Card
                className="accordion-header-card"
                style={{ margin: "0px" }}
              >
                <Card.Header className="accordion-header-space">
                  <ContextAwareToggle eventKey="1">
                    {typeof docData.lcFinancialsSt !== "undefined" ? (
                      <>  
                        {(docData.lcFinancialsSt.famtExclItemsSt ===
                          "Fail" ||
                          docData.lcFinancialsSt.fbugtAnlIncomeSt ===
                          "Fail" ||
                          docData.lcFinancialsSt.fbugtAnlReservesSt ===
                          "Fail" ||
                          docData.lcFinancialsSt.fdefdMntIndSt === 
                          "Fail" ||
                          docData.lcFinancialsSt.finspFailIndSt ===
                          "Fail" ||
                          docData.lcFinancialsSt.fleakRepairIndSt ===
                          "Fail" ||
                          docData.lcFinancialsSt.fmetDefIndSt ===
                          "Fail" ||
                          docData.lcFinancialsSt.fphyDetIndSt ===
                          "Fail" ||
                          docData.lcFinancialsSt.fresStudyReqIndSt ===
                          "Fail" ||
                          docData.lcFinancialsSt.funfundRepIndSt ===
                          "Fail" ||
                          docData.lcFinancialsSt.funitsHOAExpnPastDueSt ===
                          "Fail" ||
                          fspAssmtsRuleCheck(docData.lcFinancialsSt.fspAssmts) === "Fail") &&
                          docData.certificationStatus ===
                          "Guide Ineligible" ? (
                          <>
                            {(convResult < 90 &&
                              phaseId === 'proj' &&
                              docData.guideEligibilityAndProjectInfoSt.piAddnPhsOrAnnxInd === "No" &&
                              docData.guideEligibilityAndProjectInfoSt.piCntlHOATurnOverInd === "Yes" &&
                              docData.guideEligibilityAndProjectInfoSt.piCommnAreasCompltInd === "Yes" &&
                              docData.projectPresaleSt.ppUnitsComplete ===
                              docData.projectPresaleSt.ppTotalUnits &&
                              docData.projectPresaleSt.ppDevOwndUntsRentalHoldBackInd ===
                              "Yes" &&
                              docData.projectPresaleSt.ppDevOwnedUnits /
                              docData.projectPresaleSt.ppTotalUnits <=
                              0.2) ||
                              (convResult >= 90 &&
                                phaseId === 'proj' &&
                                docData.guideEligibilityAndProjectInfoSt.piAddnPhsOrAnnxInd === "No" &&
                                docData.guideEligibilityAndProjectInfoSt.piCntlHOATurnOverInd === "Yes" &&
                                docData.guideEligibilityAndProjectInfoSt.piCommnAreasCompltInd === "Yes" &&
                                docData.projectPresaleSt.ppUnitsComplete ===
                                docData.projectPresaleSt.ppTotalUnits) ? (
                              <p
                                className="accordion-header-title"
                                style={{ color: "#D0021B" }}
                              >
                                5. Financials, Special Assessments & Critical Repairs
                              </p>
                            ) : phaseId === 'proj' ? (
                              <p
                                className="accordion-header-title"
                                style={{ color: "#D0021B" }}
                              >
                                6. Financials, Special Assessments & Critical Repairs
                              </p>
                            ) : (
                              <p
                                className="accordion-header-title"
                                style={{ color: "#D0021B" }}
                              >
                                7. Financials, Special Assessments & Critical Repairs
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            {(convResult < 90 &&
                              phaseId === 'proj' &&
                              docData.guideEligibilityAndProjectInfoSt.piAddnPhsOrAnnxInd === "No" &&
                              docData.guideEligibilityAndProjectInfoSt.piCntlHOATurnOverInd === "Yes" &&
                              docData.guideEligibilityAndProjectInfoSt.piCommnAreasCompltInd === "Yes" &&
                              docData.projectPresaleSt.ppUnitsComplete ===
                              docData.projectPresaleSt.ppTotalUnits &&
                              docData.projectPresaleSt.ppDevOwndUntsRentalHoldBackInd ===
                              "Yes" &&
                              docData.projectPresaleSt.ppDevOwnedUnits /
                              docData.projectPresaleSt.ppTotalUnits <=
                              0.2) ||
                              (convResult >= 90 &&
                                phaseId === 'proj' &&
                                docData.guideEligibilityAndProjectInfoSt.piAddnPhsOrAnnxInd === "No" &&
                                docData.guideEligibilityAndProjectInfoSt.piCntlHOATurnOverInd === "Yes" &&
                                docData.guideEligibilityAndProjectInfoSt.piCommnAreasCompltInd === "Yes" &&
                                docData.projectPresaleSt.ppUnitsComplete ===
                                docData.projectPresaleSt.ppTotalUnits) ? (
                              <p className="accordion-header-title">
                                5. Financials, Special Assessments & Critical Repairs
                              </p>
                            ) : phaseId === 'proj' ? (
                              <p className="accordion-header-title">
                                6. Financials, Special Assessments & Critical Repairs
                              </p>
                            ) : (
                              <p className="accordion-header-title">
                                7. Financials, Special Assessments & Critical Repairs
                              </p>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {(convResult < 90 &&
                          phaseId === 'proj' &&
                          docData.guideEligibilityAndProjectInfoSt.piAddnPhsOrAnnxInd === "No" &&
                          docData.guideEligibilityAndProjectInfoSt.piCntlHOATurnOverInd === "Yes" &&
                          docData.guideEligibilityAndProjectInfoSt.piCommnAreasCompltInd === "Yes" &&
                          docData.projectPresaleSt.ppUnitsComplete ===
                          docData.projectPresaleSt.ppTotalUnits &&
                          docData.projectPresaleSt.ppDevOwndUntsRentalHoldBackInd ===
                          "Yes" &&
                          docData.projectPresaleSt.ppDevOwnedUnits /
                          docData.projectPresaleSt.ppTotalUnits <=
                          0.2) ||
                          (convResult >= 90 &&
                            phaseId === 'proj' &&
                            docData.guideEligibilityAndProjectInfoSt.piAddnPhsOrAnnxInd === "No" &&
                            docData.guideEligibilityAndProjectInfoSt.piCntlHOATurnOverInd === "Yes" &&
                            docData.guideEligibilityAndProjectInfoSt.piCommnAreasCompltInd === "Yes" &&
                            docData.projectPresaleSt.ppUnitsComplete ===
                            docData.projectPresaleSt.ppTotalUnits) ? (
                          <p className="accordion-header-title">
                            5. Financials, Special Assessments & Critical Repairs
                          </p>
                        ) : phaseId === 'proj' ? (
                          <p className="accordion-header-title">
                            6. Financials, Special Assessments & Critical Repairs
                          </p>
                        ) : (
                          <p className="accordion-header-title">
                            7. Financials, Special Assessments & Critical Repairs
                          </p>
                        )}
                      </>
                    )}
                  </ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className="accordion-body-card">
                    <FinancialsSpecialAssessmentsCriticalRepairs data={docData} />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>

            <Accordion defaultActiveKey="0" style={{ marginTop: "16px" }}>
              <Card
                className="accordion-header-card"
                style={{ margin: "0px" }}
              >
                <Card.Header className="accordion-header-space">
                  <ContextAwareToggle eventKey="1">
                    {typeof docData.lcLitigationSt !== "undefined" ? (
                      <>
                        {(docData.lcLitigationSt.lsafetyLitgtnIndSt ===
                          "Fail" ||
                          docData.lcLitigationSt.linsurancCvgIndSt ===
                          "Fail" ||
                          docData.lcLitigationSt.lminrMttrLitgtnIndSt ===
                          "Fail" ||
                          docData.lcLitigationSt.lnonResSqFtgSt ===
                          "Fail") &&
                          docData.certificationStatus ===
                          "Guide Ineligible" ? (
                          <>
                            {(convResult < 90 &&
                              phaseId === 'proj' &&
                              docData.guideEligibilityAndProjectInfoSt.piAddnPhsOrAnnxInd === "No" &&
                              docData.guideEligibilityAndProjectInfoSt.piCntlHOATurnOverInd === "Yes" &&
                              docData.guideEligibilityAndProjectInfoSt.piCommnAreasCompltInd === "Yes" &&
                              docData.projectPresaleSt.ppUnitsComplete ===
                              docData.projectPresaleSt.ppTotalUnits &&
                              docData.projectPresaleSt.ppDevOwndUntsRentalHoldBackInd ===
                              "Yes" &&
                              docData.projectPresaleSt.ppDevOwnedUnits /
                              docData.projectPresaleSt.ppTotalUnits <=
                              0.2) ||
                              (convResult >= 90 &&
                                phaseId === 'proj' &&
                                docData.guideEligibilityAndProjectInfoSt.piAddnPhsOrAnnxInd === "No" &&
                                docData.guideEligibilityAndProjectInfoSt.piCntlHOATurnOverInd === "Yes" &&
                                docData.guideEligibilityAndProjectInfoSt.piCommnAreasCompltInd === "Yes" &&
                                docData.projectPresaleSt.ppUnitsComplete ===
                                docData.projectPresaleSt.ppTotalUnits) ? (
                              <p
                                className="accordion-header-title"
                                style={{ color: "#D0021B" }}
                              >
                                6. Litigation, Usage & Insurance
                              </p>
                            ) : phaseId === 'proj' ? (
                              <p
                                className="accordion-header-title"
                                style={{ color: "#D0021B" }}
                              >
                                7. Litigation, Usage & Insurance
                              </p>
                            ) : (
                              <p
                                className="accordion-header-title"
                                style={{ color: "#D0021B" }}
                              >
                                8. Litigation, Usage & Insurance
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            {(convResult < 90 &&
                              phaseId === 'proj' &&
                              docData.guideEligibilityAndProjectInfoSt.piAddnPhsOrAnnxInd === "No" &&
                              docData.guideEligibilityAndProjectInfoSt.piCntlHOATurnOverInd === "Yes" &&
                              docData.guideEligibilityAndProjectInfoSt.piCommnAreasCompltInd === "Yes" &&
                              docData.projectPresaleSt.ppUnitsComplete ===
                              docData.projectPresaleSt.ppTotalUnits &&
                              docData.projectPresaleSt.ppDevOwndUntsRentalHoldBackInd ===
                              "Yes" &&
                              docData.projectPresaleSt.ppDevOwnedUnits /
                              docData.projectPresaleSt.ppTotalUnits <=
                              0.2) ||
                              (convResult >= 90 &&
                                phaseId === 'proj' &&
                                docData.guideEligibilityAndProjectInfoSt.piAddnPhsOrAnnxInd === "No" &&
                                docData.guideEligibilityAndProjectInfoSt.piCntlHOATurnOverInd === "Yes" &&
                                docData.guideEligibilityAndProjectInfoSt.piCommnAreasCompltInd === "Yes" &&
                                docData.projectPresaleSt.ppUnitsComplete ===
                                docData.projectPresaleSt.ppTotalUnits) ? (
                              <p className="accordion-header-title">
                                6. Litigation, Usage & Insurance
                              </p>
                            ) : phaseId === 'proj' ? (
                              <p className="accordion-header-title">
                                7. Litigation, Usage & Insurance
                              </p>
                            ) : (
                              <p className="accordion-header-title">
                                8. Litigation, Usage & Insurance
                              </p>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {(convResult < 90 &&
                          phaseId === 'proj' &&
                          docData.guideEligibilityAndProjectInfoSt.piAddnPhsOrAnnxInd === "No" &&
                          docData.guideEligibilityAndProjectInfoSt.piCntlHOATurnOverInd === "Yes" &&
                          docData.guideEligibilityAndProjectInfoSt.piCommnAreasCompltInd === "Yes" &&
                          docData.projectPresaleSt.ppUnitsComplete ===
                          docData.projectPresaleSt.ppTotalUnits &&
                          docData.projectPresaleSt.ppDevOwndUntsRentalHoldBackInd ===
                          "Yes" &&
                          docData.projectPresaleSt.ppDevOwnedUnits /
                          docData.projectPresaleSt.ppTotalUnits <=
                          0.2) ||
                          (convResult >= 90 &&
                            phaseId === 'proj' &&
                            docData.guideEligibilityAndProjectInfoSt.piAddnPhsOrAnnxInd === "No" &&
                            docData.guideEligibilityAndProjectInfoSt.piCntlHOATurnOverInd === "Yes" &&
                            docData.guideEligibilityAndProjectInfoSt.piCommnAreasCompltInd === "Yes" &&
                            docData.projectPresaleSt.ppUnitsComplete ===
                            docData.projectPresaleSt.ppTotalUnits) ? (
                          <p className="accordion-header-title">
                            6. Litigation, Usage & Insurance
                          </p>
                        ) : phaseId === 'proj' ? (
                          <p className="accordion-header-title">
                            7. Litigation, Usage & Insurance
                          </p>
                        ) : (
                          <p className="accordion-header-title">
                            8. Litigation, Usage & Insurance
                          </p>
                        )}
                      </>
                    )}
                  </ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className="accordion-body-card">
                    <LitigationUsageAndInsurance data={docData} />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
          <div className="form-footer" style={{ display: "block" }}>
            <div className="user-agreement-title">User Agreement</div>
            {!isTPORole() &&
              <div className="disclosure">
                Lender certifies and agrees that (i) the Lender has reviewed
                and recommends the project for approval by Fannie Mae; (ii)
                all provisions of the Fannie Mae Selling Guide
                (collectively, including all Announcements, the{" "}
                <strong>“Selling Guide”</strong>) pertaining to the Full
                Review of condo projects using CPM will apply to the subject
                project and that Lender is responsible for ensuring full
                compliance with all applicable provisions; (iii) the
                information and statements contained in this application are
                true and correct; and (iv) to the best of Lender’s
                knowledge, there is nothing about the subject property that
                would make the project ineligible or cause the project to be
                ineligible for the Full Review process using CPM. Lender
                agrees that the questions included in CPM are provided to
                assist the Lender with the Full Review process, that CPM
                Certifications are based solely on data that the Lender
                enters in CPM and that additional project eligibility
                requirements may be included in the Selling Guide. If there
                is any conflict between the project eligibility requirements
                in CPM and those in the Selling Guide, the Selling Guide
                provisions will govern. The Lender must notify the CPM
                Management team if it becomes aware of any information that
                could impact the eligibility status reflected in CPM and
                must provide such notification as soon as practicable but no
                later than five days after becoming aware of such
                information. In such instances, Lender must confirm the
                project’s approved status before selling a loan to Fannie
                Mae that is secured by one of the project’s units. Fannie
                Mae reserves the right to change a project’s eligibility
                status designation after certification if information
                acquired has an impact on a previously issued eligibility
                determination.
              </div>
            }
            {isTPORole() &&
              <div className="disclosure">
                Licensee certifies and agrees that (i) the Licensee has reviewed
                and recommends the project for approval by Fannie Mae; (ii)
                all provisions of the Fannie Mae Selling Guide
                (collectively, including all Announcements, the{" "}
                <strong>“Selling Guide”</strong>) pertaining to the Full
                Review of condo projects using CPM will apply to the subject
                project and that Licensee is responsible for ensuring full
                compliance with all applicable provisions; (iii) the
                information and statements contained in this application are
                true and correct; and (iv) to the best of Licensee’s
                knowledge, there is nothing about the subject property that
                would make the project ineligible or cause the project to be
                ineligible for the Full Review process using CPM. Licensee
                agrees that the questions included in CPM are provided to
                assist the Licensee with the Full Review process, that CPM
                Certifications are based solely on data that the Licensee
                enters in CPM and that additional project eligibility
                requirements may be included in the Selling Guide. If there
                is any conflict between the project eligibility requirements
                in CPM and those in the Selling Guide, the Selling Guide
                provisions will govern. The Licensee must notify the CPM
                Management team if it becomes aware of any information that
                could impact the eligibility status reflected in CPM and
                must provide such notification as soon as practicable but no
                later than five days after becoming aware of such
                information. In such instances, Licensee must confirm the
                project’s approved status before selling a loan to Fannie
                Mae that is secured by one of the project’s units. Fannie
                Mae reserves the right to change a project’s eligibility
                status designation after certification if information
                acquired has an impact on a previously issued eligibility
                determination.
              </div>
            }
            <div className="user-information">
              <div className="contact-phone">
                Lender Contact Phone{" "}
                <span style={{ color: "#BD2B2B" }}>*</span>
              </div>
              <div className="user-phone">{docData.lenderPhone}</div>
              <div className="contact-email">
                Lender Contact Email{" "}
                <span style={{ color: "#BD2B2B" }}>*</span>
              </div>
              <div className="user-email">{docData.lenderEmail}</div>
              <div className="contact-id">
                User ID <span style={{ color: "#BD2B2B" }}>*</span>
              </div>
              <div className="user-id">{docData.createdBy}</div>
              <div className="contact-lender">
                Lender <span style={{ color: "#BD2B2B" }}>*</span>
              </div>
              <div className="user-lender">{docData.lenderId}</div>
            </div>
            <div style={{ paddingTop: "20px" }}>
              {phaseId === 'proj' ? (
                <>
                  <button
                    className="complete-cert-print-btn"
                    onClick={gotToPrintCertification}
                  >
                    <PrintIcon
                      style={{
                        width: "20px",
                        height: "20px",
                        color: "#0C77BA",
                        paddingRight: "6px",
                      }}
                    />
                    Print
                  </button>

                  <button
                    className="complete-cert-btn"
                    onClick={goToProject}
                    style={{ marginLeft: "10px" }}
                  >
                    Return to Project
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="complete-cert-print-btn"
                    onClick={gotToPrintCertification}
                  >
                    <PrintIcon
                      style={{
                        width: "20px",
                        height: "20px",
                        color: "#0C77BA",
                        paddingRight: "6px",
                      }}
                    />
                    Print
                  </button>
                  <button
                    className="complete-cert-btn"
                    onClick={goToPhase}
                    style={{ marginLeft: "10px" }}
                  >
                    Return to Phase
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
