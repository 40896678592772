import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Certification.css";
import { Page1 } from "./pages/page-1";
import { Page2 } from "./pages/page-2";
import { Page3 } from "./pages/page-3";
import { Page4 } from "./pages/page-4";
import { Page5 } from "./pages/page-5";
import { Page6 } from "./pages/page-6";
import { Page7 } from "./pages/page-7";
import { Page8 } from "./pages/page-8";
import { Page9 } from "./pages/page-9";
import { Page10 } from "./pages/page-10";
import { Page11 } from "./pages/page-11";
import { Page12 } from "./pages/page-12";
import { page7SetMultiSelectOptions } from '../shared/services/SharedService'
export const Certification = (props) => {
  window.onscroll = function () {
    effects();
  };
  function effects() {}
  const [streetInfo, setStreetInfo] = useState({});
  const [docId, setDocId] = useState([]);
  const [convResult, setConvResult] = useState("");
  const [midRuleType, setMidRuleType] = useState("");
  const [year1, setYear1] = useState("");
  const [year2, setYear2] = useState("");
  const [year3, setYear3] = useState("");
  const phaseId = props.match.params.phase_id;
  const projectId = props.match.params.project_id;
  const status = props.match.params.status;
  let certificationId = "";
  const getPhaseDetails = async () => {
    if (phaseId === "proj") {
      try {
        const { data } = await axios.get(
          `${global.REACT_APP_API_ENDPOINT}/lndrCertification/getProjectInfoForLC/${projectId}`
        );
        setStreetInfo({ data });
        setStreetInfo({
          projectCity: data.city,
          projectState: data.state,
          projectStreet: data.street,
          projectZip: data.zip,
          projectName: data.projectName,
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const { data } = await axios.get(
          `${global.REACT_APP_API_ENDPOINT}/lndrCertification/getPhaseInfoForLC/${phaseId}`
        );
        setStreetInfo({ data });
        setStreetInfo({
          phaseCity: data.phaseCity,
          phaseName: data.phaseName,
          phaseState: data.phaseState,
          phaseStreet: data.phaseStreet,
          phaseZip: data.phaseZip,
          projectName: data.projectName,
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const cancelCertification = async () => {
    const id = sessionStorage.getItem("certificationId");
    if (id) {
      try {
        await axios.delete(
          `${global.REACT_APP_API_ENDPOINT}/lndrCertification/cancelCertification/${id}`
        );
      } catch (err) {
        console.log(err);
      }
      sessionStorage.removeItem("certificationId");
    }
    window.location.href =
      phaseId === "proj"
        ? "/project/" + projectId
        : "/project/phase/" + phaseId;
  };

  const getIncompleteCertificationData = async () => {
    if (phaseId === "proj") {
      try {
        const passData = JSON.parse(sessionStorage.getItem("passData"));
        const lenderId =
          sessionStorage.getItem("lenderId") ||
          passData.sellerServicerCoNo ||
          "12346";
        const { data } = await axios.get(
          `${global.REACT_APP_API_ENDPOINT}/lndrCertification/getLatestIncompleteLenderCertificationForProject/${lenderId}/${projectId}`
        );
        certificationId = data.certificationId || "";
        certificationId &&
          sessionStorage.setItem("certificationId", certificationId);
        setYear1(data.guideEligibilityAndProjectInfo.piYearBuilt);
        let formData = {
          projectId: props.match.params.project_id,
          phaseId: props.match.params.phase_id,
          createdBy: passData.user,
          userFirstName: passData.firstName,
          userLastName: passData.lastName,
          geCareFacilityInd:
            data.guideEligibilityAndProjectInfo.geCareFacilityInd,
          geEnvHazardInd: data.guideEligibilityAndProjectInfo.geEnvHazardInd,
          geHotelMotelResortInd:
            data.guideEligibilityAndProjectInfo.geHotelMotelResortInd,
          geHouseBoatInd: data.guideEligibilityAndProjectInfo.geHouseBoatInd,
          geMulLivingSpacesInd:
            data.guideEligibilityAndProjectInfo.geMulLivingSpacesInd,
          geNIncIncomeAbovePmtdInd:
            data.guideEligibilityAndProjectInfo.geNIncIncomeAbovePmtdInd,
          gePayMemshpOrLeaseInd:
            data.guideEligibilityAndProjectInfo.gePayMemshpOrLeaseInd,
          gePerTotIncNonIncIncome:
            data.guideEligibilityAndProjectInfo.gePerTotIncNonIncIncome,
          geIncomeFromPermittedSource:
            data.guideEligibilityAndProjectInfo.geIncomeFromPermittedSource,
          geRtFtRefslOrReSlRstnsInd:
            data.guideEligibilityAndProjectInfo.geRtFtRefslOrReSlRstnsInd,
          geSplitOwnershipInd:
            data.guideEligibilityAndProjectInfo.geSplitOwnershipInd,
          geTimeshareCount:
            data.guideEligibilityAndProjectInfo.geTimeshareCount,
          geUnpaidCommonExpAsmtsInd:
            data.guideEligibilityAndProjectInfo.geUnpaidCommonExpAsmtsInd,
          getCmplnHOAOwnshpInd:
            data.guideEligibilityAndProjectInfo.getCmplnHOAOwnshpInd,
          piAddnPhsOrAnnxInd:
            data.guideEligibilityAndProjectInfo.piAddnPhsOrAnnxInd,
          piCntlHOATurnOverInd:
            data.guideEligibilityAndProjectInfo.piCntlHOATurnOverInd,
          piCommnAreasCompltInd:
            data.guideEligibilityAndProjectInfo.piCommnAreasCompltInd,
          piConvtdProjInd: data.guideEligibilityAndProjectInfo.piConvtdProjInd,
          piDtTurndOver: data.guideEligibilityAndProjectInfo.piDtTurndOver,
          piGutRehabInd: data.guideEligibilityAndProjectInfo.piGutRehabInd,
          piYearBuilt: data.guideEligibilityAndProjectInfo.piYearBuilt,
          piYearConvtd: data.guideEligibilityAndProjectInfo.piYearConvtd,
          ppActivePendingSpAssmntsInd:
            data.projectPresaleAndInvestor.ppActivePendingSpAssmntsInd,
          ppDevOwndUntsRentalHoldBackInd:
            data.projectPresaleAndInvestor.ppDevOwndUntsRentalHoldBackInd,
          ppDevOwnedUnits: data.projectPresaleAndInvestor.ppDevOwnedUnits,
          ppMaintFeesDevOwndCurrentInd:
            data.projectPresaleAndInvestor.ppMaintFeesDevOwndCurrentInd,
          ppOwnerOccupiedUnits:
            data.projectPresaleAndInvestor.ppOwnerOccupiedUnits,
          ppPreSaleConveyPercntge:
            data.projectPresaleAndInvestor.ppPreSaleConveyPercntge,
          ppSecondHomes: data.projectPresaleAndInvestor.ppSecondHomes,
          ppTotalUnits: data.projectPresaleAndInvestor.ppTotalUnits,
          ppUnitsComplete: data.projectPresaleAndInvestor.ppUnitsComplete,
          ppUnitsConveyed: data.projectPresaleAndInvestor.ppUnitsConveyed,
          ppUnitsRented: data.projectPresaleAndInvestor.ppUnitsRented,
          npeAssrArrgReviewedInd:
            data.newProjectEligibility.npeAssrArrgReviewedInd,
          npeFinConExcessInd: data.newProjectEligibility.npeFinConExcessInd,
          npeLglDocsComplyInd: data.newProjectEligibility.npeLglDocsComplyInd,
          npeMasterAsscInd: data.newProjectEligibility.npeMasterAsscInd,
          npePayMnthyAssesLndrRevwPlanInd:
            data.newProjectEligibility.npePayMnthyAssesLndrRevwPlanInd,
          poLeaseComplyInd: data.projectOwnership.poLeaseComplyInd,
          poLeaseholdInd: data.projectOwnership.poLeaseholdInd,
          poOwnersHaveSoleOwnshpInd:
            data.projectOwnership.poOwnersHaveSoleOwnshpInd,
          poSnglOwnMoreThanOneInd:
            data.projectOwnership.poSnglOwnMoreThanOneInd,
          poUnitsSnglOwn: data.projectOwnership.poUnitsSnglOwn,
          lcommerclSpaceInd: data.lcLitigation.lcommerclSpaceInd,
          linsurancCvgInd: data.lcLitigation.linsurancCvgInd,
          llitigationInd: data.lcLitigation.llitigationInd,
          lminrMttrLitgtnInd: data.lcLitigation.lminrMttrLitgtnInd,
          lnonResSqFtg: data.lcLitigation.lnonResSqFtg,
          lsafetyLitgtnInd: data.lcLitigation.lsafetyLitgtnInd,
          lttlSqFtg: data.lcLitigation.lttlSqFtg,
          fnoOfSpAssmts: data.lcFinancials.fnoOfSpAssmts,
          fresStudyReqInd: data.lcFinancials.fresStudyReqInd,
          fmetDefInd: data.lcFinancials.fmetDefInd,
          fmetDefElem: data.lcFinancials.fmetDefElem,
          fotherReasonMetDef: data.lcFinancials.fotherReasonMetDef,
          fleakRepairInd: data.lcFinancials.fleakRepairInd,
          funitsHOAExpnPastDue: data.lcFinancials.funitsHOAExpnPastDue,
          fexpDateAnlBudget: data.lcFinancials.fexpDateAnlBudget,
          fbugtAnlIncome: data.lcFinancials.fbugtAnlIncome,
          famtExclItems: data.lcFinancials.famtExclItems,
          fbugtAnlReserves: data.lcFinancials.fbugtAnlReserves,
          fdefdMntInd: data.lcFinancials.fdefdMntInd,
          fsplAssmtsInd: data.lcFinancials.fsplAssmtsInd,
          fttlSpAssmts: data.lcFinancials.fttlSpAssmts,
          fspAssmts: data.lcFinancials.fspAssmts,
          fphyDetInd: data.lcFinancials.fphyDetInd,
          fphyDetEle: data.lcFinancials.fphyDetEle,
          fotherReasonPhyDet: data.lcFinancials.fotherReasonPhyDet,
          finspFailInd: data.lcFinancials.finspFailInd,
          funfundRepInd: data.lcFinancials.funfundRepInd,
          geIsProjActionCeaseToExist: data.guideEligibilityAndProjectInfo.geIsProjActionCeaseToExist,
			    geProjActionToCease: data.guideEligibilityAndProjectInfo.geProjActionToCease,
          poLeaseholdExpDate: data.projectOwnership.poLeaseholdExpDate
        }
        formData = page7SetMultiSelectOptions(formData);
        setFormData(formData);
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const passData = JSON.parse(sessionStorage.getItem("passData"));
        const lenderId =
          sessionStorage.getItem("lenderId") ||
          passData.sellerServicerCoNo ||
          "12346";
        const { data } = await axios.get(
          `${global.REACT_APP_API_ENDPOINT}/lndrCertification/getLatestIncompleteLenderCertificationForPhase/${lenderId}/${projectId}/${phaseId}`
        );
        certificationId = data.certificationId || "";
        certificationId &&
          sessionStorage.setItem("certificationId", certificationId);
        let formData = {
          projectId: props.match.params.project_id,
          phaseId: props.match.params.phase_id,
          createdBy: passData.user,
          userFirstName: passData.firstName,
          userLastName: passData.lastName,
          geCareFacilityInd:
            data.guideEligibilityAndProjectInfo.geCareFacilityInd,
          geEnvHazardInd: data.guideEligibilityAndProjectInfo.geEnvHazardInd,
          geHotelMotelResortInd:
            data.guideEligibilityAndProjectInfo.geHotelMotelResortInd,
          geHouseBoatInd: data.guideEligibilityAndProjectInfo.geHouseBoatInd,
          geMulLivingSpacesInd:
            data.guideEligibilityAndProjectInfo.geMulLivingSpacesInd,
          geNIncIncomeAbovePmtdInd:
            data.guideEligibilityAndProjectInfo.geNIncIncomeAbovePmtdInd,
          gePayMemshpOrLeaseInd:
            data.guideEligibilityAndProjectInfo.gePayMemshpOrLeaseInd,
          gePerTotIncNonIncIncome:
            data.guideEligibilityAndProjectInfo.gePerTotIncNonIncIncome,
          geIncomeFromPermittedSource:
            data.guideEligibilityAndProjectInfo.geIncomeFromPermittedSource,
          geRtFtRefslOrReSlRstnsInd:
            data.guideEligibilityAndProjectInfo.geRtFtRefslOrReSlRstnsInd,
          geSplitOwnershipInd:
            data.guideEligibilityAndProjectInfo.geSplitOwnershipInd,
          geTimeshareCount:
            data.guideEligibilityAndProjectInfo.geTimeshareCount,
          geUnpaidCommonExpAsmtsInd:
            data.guideEligibilityAndProjectInfo.geUnpaidCommonExpAsmtsInd,
          getCmplnHOAOwnshpInd:
            data.guideEligibilityAndProjectInfo.getCmplnHOAOwnshpInd,
          piAddnPhsOrAnnxInd:
            data.guideEligibilityAndProjectInfo.piAddnPhsOrAnnxInd,
          piCntlHOATurnOverInd:
            data.guideEligibilityAndProjectInfo.piCntlHOATurnOverInd,
          piCommnAreasCompltInd:
            data.guideEligibilityAndProjectInfo.piCommnAreasCompltInd,
          piConvtdProjInd: data.guideEligibilityAndProjectInfo.piConvtdProjInd,
          piDtTurndOver: data.guideEligibilityAndProjectInfo.piDtTurndOver,
          piGutRehabInd: data.guideEligibilityAndProjectInfo.piGutRehabInd,
          piYearBuilt: data.guideEligibilityAndProjectInfo.piYearBuilt,
          piYearConvtd: data.guideEligibilityAndProjectInfo.piYearConvtd,
          ppActivePendingSpAssmntsInd:
            data.projectPresaleAndInvestor.ppActivePendingSpAssmntsInd,
          ppDevOwndUntsRentalHoldBackInd:
            data.projectPresaleAndInvestor.ppDevOwndUntsRentalHoldBackInd,
          ppDevOwnedUnits: data.projectPresaleAndInvestor.ppDevOwnedUnits,
          ppMaintFeesDevOwndCurrentInd:
            data.projectPresaleAndInvestor.ppMaintFeesDevOwndCurrentInd,
          ppOwnerOccupiedUnits:
            data.projectPresaleAndInvestor.ppOwnerOccupiedUnits,
          ppPreSaleConveyPercntge:
            data.projectPresaleAndInvestor.ppPreSaleConveyPercntge,
          ppSecondHomes: data.projectPresaleAndInvestor.ppSecondHomes,
          ppTotalUnits: data.projectPresaleAndInvestor.ppTotalUnits,
          ppUnitsComplete: data.projectPresaleAndInvestor.ppUnitsComplete,
          ppUnitsConveyed: data.projectPresaleAndInvestor.ppUnitsConveyed,
          ppUnitsRented: data.projectPresaleAndInvestor.ppUnitsRented,
          npeAssrArrgReviewedInd:
            data.newProjectEligibility.npeAssrArrgReviewedInd,
          npeFinConExcessInd: data.newProjectEligibility.npeFinConExcessInd,
          npeLglDocsComplyInd: data.newProjectEligibility.npeLglDocsComplyInd,
          npeMasterAsscInd: data.newProjectEligibility.npeMasterAsscInd,
          npePayMnthyAssesLndrRevwPlanInd:
            data.newProjectEligibility.npePayMnthyAssesLndrRevwPlanInd,
          poLeaseComplyInd: data.projectOwnership.poLeaseComplyInd,
          poLeaseholdInd: data.projectOwnership.poLeaseholdInd,
          poOwnersHaveSoleOwnshpInd:
            data.projectOwnership.poOwnersHaveSoleOwnshpInd,
          poSnglOwnMoreThanOneInd:
            data.projectOwnership.poSnglOwnMoreThanOneInd,
          poUnitsSnglOwn: data.projectOwnership.poUnitsSnglOwn,
          lcommerclSpaceInd: data.lcLitigation.lcommerclSpaceInd,
          linsurancCvgInd: data.lcLitigation.linsurancCvgInd,
          llitigationInd: data.lcLitigation.llitigationInd,
          lminrMttrLitgtnInd: data.lcLitigation.lminrMttrLitgtnInd,
          lnonResSqFtg: data.lcLitigation.lnonResSqFtg,
          lsafetyLitgtnInd: data.lcLitigation.lsafetyLitgtnInd,
          lttlSqFtg: data.lcLitigation.lttlSqFtg,
          phpOwnerOccupiedUnits:
            data.phasePresaleAndInvestor.phpOwnerOccupiedUnits,
          phpOwnerOccupiedUnits:
            data.phasePresaleAndInvestor.phpOwnerOccupiedUnits,
          phpPhases: data.phasePresaleAndInvestor.phpPhases,
          phpPhasesAnnexedToProject:
            data.phasePresaleAndInvestor.phpPhasesAnnexedToProject,
          phpSecondHomes: data.phasePresaleAndInvestor.phpSecondHomes,
          phpUnits: data.phasePresaleAndInvestor.phpUnits,
          phpUnitsComplete: data.phasePresaleAndInvestor.phpUnitsComplete,
          phpUnitsCurrPhaseAndPrPhase:
            data.phasePresaleAndInvestor.phpUnitsCurrPhaseAndPrPhase,
          phpUnitsForSale: data.phasePresaleAndInvestor.phpUnitsForSale,
          phpUnitsRented: data.phasePresaleAndInvestor.phpUnitsRented,
          phpUnitsSold: data.phasePresaleAndInvestor.phpUnitsSold,
          phpUnitsSoldOwnerOccpSecondHomeRsdn:
            data.phasePresaleAndInvestor.phpUnitsSoldOwnerOccpSecondHomeRsdn,
          fnoOfSpAssmts: data.lcFinancials.fnoOfSpAssmts,
          fresStudyReqInd: data.lcFinancials.fresStudyReqInd,
          fmetDefInd: data.lcFinancials.fmetDefInd,
          fmetDefElem: data.lcFinancials.fmetDefElem,
          fotherReasonMetDef: data.lcFinancials.fotherReasonMetDef,
          fleakRepairInd: data.lcFinancials.fleakRepairInd,
          funitsHOAExpnPastDue: data.lcFinancials.funitsHOAExpnPastDue,
          fexpDateAnlBudget: data.lcFinancials.fexpDateAnlBudget,
          fbugtAnlIncome: data.lcFinancials.fbugtAnlIncome,
          famtExclItems: data.lcFinancials.famtExclItems,
          fbugtAnlReserves: data.lcFinancials.fbugtAnlReserves,
          fdefdMntInd: data.lcFinancials.fdefdMntInd,
          fsplAssmtsInd: data.lcFinancials.fsplAssmtsInd,
          fttlSpAssmts: data.lcFinancials.fttlSpAssmts,
          fspAssmts: data.lcFinancials.fspAssmts,
          fphyDetInd: data.lcFinancials.fphyDetInd,
          fphyDetEle: data.lcFinancials.fphyDetEle,
          fotherReasonPhyDet: data.lcFinancials.fotherReasonPhyDet,
          finspFailInd: data.lcFinancials.finspFailInd,
          funfundRepInd: data.lcFinancials.funfundRepInd,
          geIsProjActionCeaseToExist: data.guideEligibilityAndProjectInfo.geIsProjActionCeaseToExist,
			    geProjActionToCease: data.guideEligibilityAndProjectInfo.geProjActionToCease,
          poLeaseholdExpDate: data.projectOwnership.poLeaseholdExpDate
        }
        formData = page7SetMultiSelectOptions(formData);
        setFormData(formData);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    getPhaseDetails();
    if (status === "Incomplete") {
      getIncompleteCertificationData();
    }
  }, []);

  const passData = JSON.parse(sessionStorage.getItem("passData"));
  const [formData, setFormData] = useState({
    projectId: props.match.params.project_id,
    phaseId: props.match.params.phase_id,
    createdBy: passData && passData.user,
    userFirstName: passData && passData.firstName,
    userLastName: passData && passData.lastName,
    lenderEmail: "",
    lenderPhone: "",
    geCareFacilityInd: "",
    geEnvHazardInd: "",
    geHotelMotelResortInd: "",
    geHouseBoatInd: "",
    geMulLivingSpacesInd: "",
    geNIncIncomeAbovePmtdInd: "",
    gePayMemshpOrLeaseInd: "",
    gePerTotIncNonIncIncome: "",
    geIncomeFromPermittedSource: "",
    geRtFtRefslOrReSlRstnsInd: "",
    geSplitOwnershipInd: "",
    geTimeshareCount: "",
    geUnpaidCommonExpAsmtsInd: "",
    getCmplnHOAOwnshpInd: "",
    piAddnPhsOrAnnxInd: "",
    piCntlHOATurnOverInd: "",
    piCommnAreasCompltInd: "",
    piConvtdProjInd: "",
    piDtTurndOver: "",
    piGutRehabInd: "",
    piYearBuilt: "",
    piYearConvtd: "",
    ppActivePendingSpAssmntsInd: "",
    ppDevOwndUntsRentalHoldBackInd: "",
    ppDevOwnedUnits: "",
    ppMaintFeesDevOwndCurrentInd: "",
    ppOwnerOccupiedUnits: "",
    ppPreSaleConveyPercntge: "",
    ppSecondHomes: "",
    ppTotalUnits: "",
    ppUnitsComplete: "",
    ppUnitsConveyed: "",
    ppUnitsRented: "",
    phpOwnerOccupiedUnits: "",
    phpPhases: "",
    phpPhasesAnnexedToProject: "",
    phpSecondHomes: "",
    phpUnits: "",
    phpUnitsComplete: "",
    phpUnitsCurrPhaseAndPrPhase: "",
    phpUnitsForSale: "",
    phpUnitsRented: "",
    phpUnitsSold: "",
    phpUnitsSoldOwnerOccpSecondHomeRsdn: "",
    npeAssrArrgReviewedInd: "",
    npeFinConExcessInd: "",
    npeLglDocsComplyInd: "",
    npeMasterAsscInd: "",
    npePayMnthyAssesLndrRevwPlanInd: "",
    poLeaseComplyInd: "",
    poLeaseholdInd: "",
    poOwnersHaveSoleOwnshpInd: "",
    poSnglOwnMoreThanOneInd: "",
    poUnitsSnglOwn: "",
    lcommerclSpaceInd: "",
    linsurancCvgInd: "",
    llitigationInd: "",
    lminrMttrLitgtnInd: "",
    lnonResSqFtg: "",
    lsafetyLitgtnInd: "",
    lttlSqFtg: "",
    fnoOfSpAssmts: "",
    fresStudyReqInd: "",
    fmetDefInd: "",
    fmetDefElem: [],
    fotherReasonMetDef: "",
    fleakRepairInd: "",
    funitsHOAExpnPastDue: "",
    fexpDateAnlBudget: "",
    fbugtAnlIncome: "",
    famtExclItems: "",
    fbugtAnlReserves: "",
    fdefdMntInd: "",
    fsplAssmtsInd: "",
    fttlSpAssmts: "",
    fspAssmts: [],
    fphyDetInd: "",
    fphyDetEle: [],
    fotherReasonPhyDet: "",
    finspFailInd: "",
    funfundRepInd: "",
    geIsProjActionCeaseToExist: "",
    geProjActionToCease: "",
    poLeaseholdExpDate: "",
  });

  const [step, setStep] = useState(0);
  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => prev - 1);
  const skipStep2 = () => setStep(4);
  const skipStep3 = () => setStep(5);
  const skipStep4 = () => setStep(11);
  const errorStep = () => setStep(10);
  const page3 = () => setStep(2);

  switch (step) {
    case 0:
      return (
        <Page1
          formData={formData}
          setFormData={setFormData}
          streetInfo={streetInfo}
          nextStep={nextStep}
          step={step}
          status={status}
          cancelCertification={cancelCertification}
        />
      );
    case 1:
      return (
        <Page2
          formData={formData}
          setFormData={setFormData}
          streetInfo={streetInfo}
          nextStep={nextStep}
          prevStep={prevStep}
          setYear1={setYear1}
          setYear2={setYear2}
          setYear3={setYear3}
          step={step}
          status={status}
          cancelCertification={cancelCertification}
        />
      );
    case 2:
      return (
        <Page3
          formData={formData}
          setFormData={setFormData}
          streetInfo={streetInfo}
          nextStep={nextStep}
          prevStep={prevStep}
          errorStep={errorStep}
          skipStep2={skipStep2}
          skipStep3={skipStep3}
          setConvResult={setConvResult}
          step={step}
          status={status}
          cancelCertification={cancelCertification}
        />
      );
    case 3:
      return (
        <Page4
          formData={formData}
          setFormData={setFormData}
          streetInfo={streetInfo}
          nextStep={nextStep}
          prevStep={prevStep}
          convResult={convResult}
          step={step}
          status={status}
          cancelCertification={cancelCertification}
        />
      );
    case 4:
      return (
        <Page5
          formData={formData}
          setFormData={setFormData}
          streetInfo={streetInfo}
          page3={page3}
          nextStep={nextStep}
          prevStep={prevStep}
          convResult={convResult}
          midRuleType={midRuleType}
          setMidRuleType={setMidRuleType}
          step={step}
          status={status}
          cancelCertification={cancelCertification}
        />
      );
    case 5:
      return (
        <Page6
          formData={formData}
          setFormData={setFormData}
          streetInfo={streetInfo}
          page3={page3}
          nextStep={nextStep}
          prevStep={prevStep}
          convResult={convResult}
          midRuleType={midRuleType}
          setMidRuleType={setMidRuleType}
          step={step}
          status={status}
          cancelCertification={cancelCertification}
        />
      );
    case 6:
      return (
        <Page7
          formData={formData}
          setFormData={setFormData}
          streetInfo={streetInfo}
          nextStep={nextStep}
          prevStep={prevStep}
          convResult={convResult}
          midRuleType={midRuleType}
          step={step}
          status={status}
          cancelCertification={cancelCertification}
        />
      );
    case 7:
      return (
        <Page8
          formData={formData}
          setFormData={setFormData}
          streetInfo={streetInfo}
          nextStep={nextStep}
          prevStep={prevStep}
          convResult={convResult}
          midRuleType={midRuleType}
          step={step}
          status={status}
          cancelCertification={cancelCertification}
        />
      );
    case 8:
      return (
        <Page9
          formData={formData}
          setFormData={setFormData}
          streetInfo={streetInfo}
          nextStep={nextStep}
          prevStep={prevStep}
          setDocId={setDocId}
          convResult={convResult}
          year1={year1}
          year2={year2}
          year3={year3}
          step={step}
          status={status}
          cancelCertification={cancelCertification}
        />
      );
    case 9:
      return (
        <Page11
          formData={formData}
          setFormData={setFormData}
          streetInfo={streetInfo}
          nextStep={nextStep}
          prevStep={prevStep}
          convResult={convResult}
          year1={year1}
          year2={year2}
          year3={year3}
          docId={docId}
          step={step}
          skipStep4={skipStep4}
          status={status}
          cancelCertification={cancelCertification}
        />
      );
    case 10:
      return (
        <Page10
          convResult={convResult}
          formData={formData}
          setFormData={setFormData}
          streetInfo={streetInfo}
          nextStep={nextStep}
          prevStep={prevStep}
          page3={page3}
          step={step}
          status={status}
          cancelCertification={cancelCertification}
        />
      );
    case 11:
      return (
        <Page12
          formData={formData}
          setFormData={setFormData}
          streetInfo={streetInfo}
          nextStep={nextStep}
          prevStep={prevStep}
          convResult={convResult}
          year1={year1}
          year2={year2}
          year3={year3}
          docId={docId}
          step={step}
          status={status}
          cancelCertification={cancelCertification}
        />
      );
    default:
      return (
        <Page1
          formData={formData}
          setFormData={setFormData}
          streetInfo={streetInfo}
          nextStep={nextStep}
          prevStep={prevStep}
          step={step}
          status={status}
          cancelCertification={cancelCertification}
        />
      );
  }
};